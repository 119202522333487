import Container from "../../Shared/Container";
import styles from './styles.module.scss';

function CaseInternalTime() {
    return (
            <Container>
                <div className={styles.wrapperCont}>
                    <h2>
                    How much of it took us?
                    </h2>
                    <div className={styles.mainCont}>
                        <div className={styles.left}>
                            <p>
                            We feel a profound gratitude for having such projects come our way and that motivates us even more to beat all odds.
                            </p>

                            <div className={styles.steps}>
                                <div className={styles.items}>
                                    <h3>
                                        Our team
                                    </h3>
                                    <p>
                                    2 software developers
                                    </p>
                                </div>

                                <div className={`${styles.items} ${styles.bordered}`}>
                                    <h3>
                                    14 weeks
                                    </h3>
                                    <p>
                                    We crafted a complex trip and cargo algorithm
                                    </p>
                                </div>

                                <div className={styles.items}>
                                    <h3>
                                    1 commitment
                                    </h3>
                                    <p>
                                    We crafted a complex trip and cargo algorithm
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

    );
}

export default CaseInternalTime;
