import Container from "../../Shared/Container";
import styles from './styles.module.scss';

function CaseInternalHero({header, subHeader}) {
    return (
        <Container>
            <div className={styles.wrapper}>
                <p>
                    {subHeader ? subHeader : 'Backlinking DIY'}
                </p>
                <h1>
                    {header ? header : 'How we enabled fashion website get through the SEO handling and everything'}
                </h1>
                {/* <div className={styles.image}/> */}
            </div>
        </Container>
    );
}

export default CaseInternalHero;
