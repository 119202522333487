import styles from "./styles.module.scss";

const PrimaryButton = ({
  buttonText,
  onClick,
  outlined = false,
  dark = false,
  light = false,
  type,
}) => {
  return (
    <button
      type={type}
      className={`${styles.wrapper} ${outlined && styles.outlined} ${dark && styles.dark} ${light && styles.light}`}
      onClick={() => {
        if (onClick) {
          onClick();
        } else return false;
      }}
    >
      {buttonText}
    </button>
  );
};

export default PrimaryButton;
