import PrimaryButton from "../../Shared/Buttons/Primary";
import Container from "../../Shared/Container";
import styles from "./styles.module.scss";
import Slider from "react-slick";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Data = [1, 2, 3, 4];

function LocalSEOWorks({ heading, subHeading }) {
  const [activeIndex, setActive] = useState(0);
  const sliderRef = useRef();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={"/images/ic_arrow_dark.svg"}
        alt={"arrow"}
        className={styles.nextArrow}
        onClick={onClick}
      />
    );
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={"/images/ic_arrow_dark.svg"}
        alt={"arrow"}
        className={styles.prevArrow}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: isMobile ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    // centerMode: true,
    arrows: isMobile ? false : true,
    variableWidth: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dotsClass: styles.button__bar,
    beforeChange: (oldIndex, newIndex) => {
      setActive(newIndex);
    },
  };
  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.headerSection}>
          <h1>{heading ? heading : "Explore our work across industries"}</h1>
          <p>
            {subHeading
              ? subHeading
              : "Real world solutions using the higher standard of developement"}
          </p>
        </div>

        <div className={styles.cardsSection}>
          <Slider {...settings} ref={sliderRef}>
            {Data.map(() => {
              return (
                <div className="sliderCustom">
                  <div className={styles.cards}>
                    <img
                      className={styles.cardImage}
                      src={"/images/ic_seo_industries.png"}
                      alt={"logo"}
                    />
                    <div className={styles.content}>
                      <div className={styles.top}>Local SEO</div>
                      <div className={styles.middle}>
                        How we helped an startup come top of the page
                      </div>
                      <div className={styles.buttonContTwo}>Read more</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>

        <div className={styles.buttonCont}>
          <PrimaryButton
            buttonText={"View all Case studies"}
            onClick={() => navigate("/case-study")}
          />
        </div>
      </div>
    </Container>
  );
}

export default LocalSEOWorks;
