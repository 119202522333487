import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

function BlogsInternalHero({
  category,
  header,
  author,
  date,
  bannerImage,
  content,
  authorImage,
  relatedData
}) {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();

  const shareOnFacebook = () => {
    const url = window.location.href;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookUrl, '_blank');
  };

  const shareOnTwitter = () => {
    const url = window.location.href;
    const text = "Check this out!";
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
    window.open(twitterUrl, '_blank');
  };

  const shareOnLinkedIn = () => {
    const url = window.location.href;
    const title = "Check this out!";
    const summary = "Check this out!";
    const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}`;
    window.open(linkedInUrl, '_blank');
  };

  const getContactData = () => {
    let header, desc;
    switch (category) {
      case "Managed SEO":
        header = 'Do You Want More Traffic, More Leads & Conversions?'
        desc = `Request A Free SEO Audit & we'll share detailed insights to enhance your Website's SEO Performance.`
        break;
      case "White label":
        header = `Do You Want To Automate Your SEO Lead Generation?`
        desc = `Get Access To The Blueprint of our system to generate continuos flow of SEO prospects in a predictable manner.`
        break;
      case "Link Building":
        header = `Wary Of Buying Backlinks, From Random Sellers!!`
        desc = `Get Access To Our Ebook on Strurcturd Approach To Link Building & take control of all your Link Building Efforts`
        break;
      default:
        header = `Do You Want More Traffic, More Leads & Conversions?`
        desc = `Jack ranker was a name that reached the masses and got
        everyone intrigued and smiling. With a deeper meaning, it
        brings`
    }

    return {header, desc};
  }

  const settingsTwo = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    variableWidth: true,
    centerMode: false,
  };

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.top}>Blog &rang; {category}</div>
          <div className={styles.middle}>{header}</div>
          <div className={styles.bottom}>
            {author} &sdot; {date}
          </div>

          <img
            className={styles.heroIllus}
            src={bannerImage}
            alt={"carousel item"}
          />
        </div>

        <div className={styles.bodyMain}>
          <div className={styles.left}>
            <div
              className={styles.texts}
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
            <div className={styles.bottom}>
              <div className={styles.first}>Sharing is caring</div>
              <div className={styles.line} />
              <div className={styles.icons}>
                <img
                  className={styles.icon}
                  src={"/images/ic_fb_filled.svg"}
                  alt={"carousel item"}
                  onClick={shareOnFacebook}
                />
                <img
                  className={styles.icon}
                  src={"/images/ic_linkedin_filled.svg"}
                  alt={"carousel item"}
                  onClick={shareOnLinkedIn}
                />
                <img
                  className={styles.icon}
                  src={"/images/ic_x_filled.svg"}
                  alt={"carousel item"}
                  onClick={shareOnTwitter}
                />
              </div>
            </div>
          </div>


          <div className={styles.right}>
            <div className={styles.authorCard}>
              <img
                className={styles.author}
                src={authorImage}
                alt={"carousel item"}
              />
              <div className={styles.texts}>
                <h3>About: {author}</h3>
                <p>
                  He is the founder of Rank Jacker. The Wall Street Journal calls
                  him a top influencer on the web, Forbes says he is one of the top
                  10 marketers,
                </p>
              </div>

              <div className={styles.icons}>
                <img
                  className={styles.icon}
                  src={"/images/ic_fb_filled.svg"}
                  alt={"carousel item"}
                  onClick={shareOnFacebook}
                />
                <img
                  className={styles.icon}
                  src={"/images/ic_linkedin_filled.svg"}
                  alt={"carousel item"}
                  onClick={shareOnLinkedIn}
                />
                {/* <img
                  className={styles.icon}
                  src={"/images/ic_instagram_filled.svg"}
                  alt={"carousel item"}
                /> */}
                <img
                  className={styles.icon}
                  src={"/images/ic_x_filled.svg"}
                  alt={"carousel item"}
                  onClick={shareOnTwitter}
                />
              </div>
            </div>
            <div className={styles.contact}>
              <h2>{getContactData().header}</h2>
              <p>
                {getContactData().desc}
              </p>
              <div className={styles.button}>
                <p>Your website</p>
                <img
                  className={styles.arrow}
                  src={"/images/ic_arrow_dark.svg"}
                  alt={"carousel item"}
                />
              </div>
            </div>

            {/* <div className={styles.contents}>
              <h3>
                Table of contents
              </h3>

              <p className={styles.active}>
                Title 1 : Lorem ipsum saying something
              </p>
              <p>
                Title 2 : Lorem ipsum saying something
              </p>
              <p>
                Title 3 : Lorem ipsum saying something
              </p>
              <p>
                Title 4 : Lorem ipsum saying something
              </p>
              <p>
                Title 5 : Lorem ipsum saying something
              </p>
              <hr />
              <p>
                Title 1 : Lorem ipsum saying something
              </p>
              <p>
                Title 2 : Lorem ipsum saying something
              </p>
              <p>
                Title 3 : Lorem ipsum saying something
              </p>
              <p>
                Title 4 : Lorem ipsum saying something
              </p>
              <p>
                Title 5 : Lorem ipsum saying something
              </p>
            </div> */}
          </div>

        </div>

        <div className={styles.blogList}>
          <div className={styles.header}>Try these blogs as well</div>
          <div className={styles.middleBlogs}>
            {!isMobile && relatedData.map((item) => {
              return (
                <div className={styles.blogCard} onClick={() => navigate(`/blogs/${item.slug}`)}>
                  <img
                    className={styles.heroImage}
                    src={item.x_featured_media_medium}
                    alt={"carousel item"}
                  />
                  <div className={styles.details}>
                    <div className={styles.title}>{item.title?.rendered}</div>
                    <div className={styles.type}>{item.x_categories}</div>
                    <div className={styles.author}>{item.x_author}</div>
                    <div className={styles.date}>{item.x_date}</div>
                  </div>
                </div>
              );
            })}

            {isMobile && <Slider {...settingsTwo}>
              {relatedData.map((item) => {
              return (
                <div className={styles.blogCard} onClick={() => navigate(`/blogs/${item.slug}`)}>
                  <img
                    className={styles.heroImage}
                    src={item.x_featured_media_medium}
                    alt={"carousel item"}
                  />
                  <div className={styles.details}>
                    <div className={styles.title}>{item.title?.rendered}</div>
                    <div className={styles.type}>{item.x_categories}</div>
                    <div className={styles.author}>{item.x_author}</div>
                    <div className={styles.date}>{item.x_date}</div>
                  </div>
                </div>
              );
            })}
            </Slider>}
          </div>
        </div>

        <div className={styles.news}>
          <h1>Subscribe to our newsletter</h1>
          <p>
            Jack ranker was a name that reached the masses and got everyone
            intrigued and smiling. With a deeper meaning, it brings ou
          </p>
          <div className={styles.buttonWrapper}>
            <input
              name="myInput"
              placeholder={"Your Email"}
              className={styles.inputField}
            />
            <img
              className={styles.icon}
              src={"/images/ic_arrow_dark.svg"}
              alt={"carousel item"}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default BlogsInternalHero;
