import DFYClients from "../../../components/DFY/Clients";
import DFYDetails from "../../../components/DFY/Details";
import DFYExperts from "../../../components/DFY/Experts";
import DFYGrow from "../../../components/DFY/Grow";
import DFYHero from "../../../components/DFY/Hero";
import Footer from "../../../components/Footer";
import HomeTestimonial from "../../../components/Home/Testimonial";
import HomeFaqs from "../../../components/Home/Faqs";
import Container from "../../../components/Shared/Container";
import { useMediaQuery } from "react-responsive";
import styles from "./styles.module.scss";
import DIYClients from "../../../components/DIY/Clients";

const Data = [
  "/images/ic_databox.svg",
  "/images/ic_business_insider.svg",
  "/images/ic_legalzoom.svg",
  "/images/ic_partner_one.svg",
  "/images/ic_segate.svg",
];
const MobileData = [
  "/images/ic_databox.svg",
  "/images/ic_business_insider.svg",
  "/images/ic_legalzoom.svg",
  "/images/ic_partner_one.svg",
  "/images/ic_segate.svg",
  "/images/ic_segate.svg",
];

const LocalService = [
  {
    h1: "We become your white label partner",
    p: "Sign a contract ensuring full confidentiality. You represent us to clients, set prices, and control margins while we work discreetly.",
    icon: "/images/white1.svg",
  },
  {
    h1: "Frictionless Onboarding",
    p: "We’ve done all the work for you in creating an on-boarding process for both you and your clients.",
    icon: "/images/white2.svg",
  },
  {
    h1: "24-72 hrs Turnover Time",
    p: "Project begins within 24-72 hours. We analyze the project, assess competition, and craft winning strategies for your clients.",
    icon: "/images/white3.svg",
  },
  {
    h1: "Reporting and Client Retention",
    p: "Our experts monitor the online review sites looking into the local SEO algorithm with our expertise! Increase your quality reviews today.",
    icon: "/images/white4.svg",
  },
];

const SpeedData = [
  {
    icon: "/images/ic_buy_link.svg",
    header: "Buy Links Directly",
    desc: "The complete guide on why, how, and which packages to buy for your link-building process so that your website ranks and grows quality traffic only. It’s a complete DIY process where all you need is to select the link (package) and buy it.",
  },
  {
    icon: "/images/ic_managed.svg",
    header: "Managed SEO",
    desc: "With our DFY Managed SEO gets a hands-free experience to get your website ranked on the first page of Google, leaving you to have more time for doing what you are good at i.e, running your business.",
  },
  {
    icon: "/images/ic_local.svg",
    header: "Local SEO",
    desc: "We help you to optimize your local SEO to get more people in your area to be able to find your business and thereby convert more sales.",
  },
  {
    icon: "/images/ic_consult.svg",
    header: "SEO consultation & sales process",
    desc: "Consult with our SEO and sales expert for a well-researched and detailed strategy that not only helps your website to rank but also drives quality traffic and sales.",
  },
  {
    icon: "/images/ic_monthly.svg",
    header: "Monthly managed link building",
    desc: "With our proven monthly link-building process, we guarantee quality traffic to your website that in turn helps you to dominate your competition in the marketplace.",
  },
  {
    icon: "/images/ic_depth.svg",
    header: "In-Depth SEO Audit",
    desc: "Our comprehensive SEO audit swiftly uncovers errors, gaps, and barriers impacting your rankings. Transparent and thorough, our services analyze technical, on-page, and off-page SEO, ensuring your site ranks on Google's first page.",
  },
];

const DataClients = [
  {
    icon: "/images/white_growth.svg",
    header: "Grow your agency faster with our experince",
    desc: `We have been in the industry for more than 10years, having spent 1000s of dollars in different tools, courses and strategies, we have battle tested all of them. Therefore we exactly know what works in the market and have the proven framework that can skyrocket your business. to new height at the shortest span of time possible.`,
  },
  {
    icon: "/images/white_growth.svg",
    header: "100% transparent SEO reporting",
    desc: `We deliver what we promise, and keep you updated with your growth metrics through weekly reports so that you are in full control thorughout the project. Our reports also include every minute aspect of your website, implementation we have brought in and the growth metrics we have achieved.`,
  },
  {
    icon: "/images/white_growth.svg",
    header: "Unparallel Customer Support",
    desc: `We believe in having a few clients but long term quality clients. We provide 24X7 support so that there isn't a moment our clients feel left alone. We do not agree or propose to work with any random business whom we can't help or with whom we are the ideal fit, we only work with businesses when we are confident in growing their business and helping them achieve their business goals.`,
  },
];

const FaqData = {
  Generals: [
    {
      question: "When Can My Clients See The Rankings?",
      answer:
        "It can take months to see the effects of the SEO program you have chosen. On average, it takes 1-3 months for our clients to see the impact, first page ranking takes longer, around 6-8 months however, competitive markets require almost a year.",
    },
    {
      question: "Why Choose A Reseller?",
      answer:
        "White labeling SEO is less hectic than making it happen by your team if you have bandwidth issues. We can be an extension of your current team and easily collaborate with your project managers to make it a success for your clients. We have the expertise and know the latest trends, which you can harness by white labeling.",
    },
    {
      question: "Will Your Methods Work As Search Engine Get Smarter?",
      answer:
        "We keep updated with the latest trends, and Google algorithms and have resources to be ahead of the game. Our dedicated research and development team monitors the industry and keeps the whole process updated.",
    },
    {
      question: "Do We Offer Discount?",
      answer:
        "Yes, on big company orders, we do offer discounts on our services. Please contact us.",
    },
  ],
};
const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO has completely transformed our online visibility. Their white label SEO service not only boosted our rankings but also provided invaluable insights into our target audience. Highly recommended!",
    h3: "Robert Johnson",
    designation: "CEO",
    personSrc: "/images/Robert Johnson.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We partnered with Rank-Jacker SEO for white label SEO services and it was one of the best decisions we made for our agency. Their expertise and dedication helped us deliver exceptional results to our clients consistently.",
    h3: "Emily Wilson",
    designation: "Marketing Manager",
    personSrc: "/images/Emily Wilson.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's white label SEO service exceeded our expectations. Their team is proactive, responsive, and truly understands the intricacies of SEO. Our clients are delighted with the outcomes!",
    h3: "Rupert Lee",
    designation: "Director of Operations",
    personSrc: "/images/Rupert Lee.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Choosing Rank-Jacker SEO for white label SEO was a game-changer for our agency. Their transparent reporting and tailored strategies have significantly increased our clients' organic traffic and conversions.",
    h3: "Sarah Thompson",
    designation: "Founder",
    personSrc: "/images/Sarah Thompson.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We've been working with Rank-Jacker SEO for white label SEO services and the results have been phenomenal. Their attention to detail and ability to adapt to changing algorithms have kept our clients ahead of the competition.",
    h3: "Michael Davis",
    designation: "Managing Director",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We've been impressed with the professionalism and expertise of Rank-Jacker SEO. Their white label SEO service has not only improved our clients' search rankings but also enhanced their overall online presence.",
    h3: "Ryan Carter",
    designation: "Marketing Director",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO has been an invaluable partner for our agency. Their white label SEO service has helped us scale our operations and deliver exceptional results to our clients consistently.",
    h3: "Jennifer White",
    designation: "COO",
    personSrc: "/images/i_client_one.png",
  },
];

const WhiteLabelSEO = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <>
      <DFYHero
        header={"WHITE LABEL SEO SERVICES FOR AGENCIES"}
        desc={
          "We carry the toughest task off your shoulders without compromising on quality."
        }
        ctaLink={"https://calendly.com/bookadiscoverycall/15"}
      />

      {/* Details section */}
      <div className={styles.wrapper}>
        <div className={styles.bgColor} />
        <div className={styles.bgColorTwo} />
        <Container>
          <div className={styles.contentWrapper}>
            <div className={styles.partners}>
              {isMobile
                ? MobileData.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })
                : Data.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })}
            </div>

            <div className={styles.headerSection}>
              <h1>SPEEDING UP = GROWINGEXPONENTIALLY</h1>
              <p>You Get All The Credit, We Do All The Work</p>
            </div>

            <div className={styles.cardsSection}>
              {SpeedData.map((item, index) => {
                return (
                  <div className={styles.cards} key={index}>
                    <img className={styles.icon} src={item.icon} alt={"logo"} />

                    <h2>{item.header}</h2>
                    <p>{item.desc}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </div>

      <DFYClients
        header={"IF YOU NEED A GROWTH HACKERYOU NEED RANK JACKER"}
        desc={"We not only rank websites, drive real traffic that convert"}
        data={DataClients}
        ctaLink={
          "https://forms.zohopublic.in/rankmakeup/form/WhiteLabelSEOServiceFrom/formperma/apxG6nA8NTrPGyRhME8Sgg1ihP6ajvhWPbLU4p2UCmg"
        }
      />
      <DIYClients data={Testimonial} verticalSpace={true} />

      <div className={styles.wrapper}>
        <div className={styles.services}>
          <Container>
            <h1>INTERESTED? THIS IS HOW WE WORK</h1>

            <div className={styles.cardsContainer}>
              {LocalService.map((item, index) => {
                return (
                  <div className={styles.cards}>
                    <h1>{item.h1}</h1>
                    <p>{item.p}</p>
                    <img
                      className={styles.icon}
                      src={item.icon}
                      alt={"banner"}
                    />
                  </div>
                );
              })}
            </div>
          </Container>
        </div>
      </div>

      <DFYExperts
        bannerHeader={"Claim Your Free Backlink Audit Now"}
        bannerDesc={
          "Let us help you wead out Toxic, Irrelevant backlinks from your website!"
        }
        buttonLink={"https://calendly.com/bookadiscoverycall/15"}
      />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};

export default WhiteLabelSEO;
