import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import InputTextButton from "../../Shared/Input/Button";
import { useNavigate } from "react-router-dom";
import {
  BlogList,
  BlogListFour,
  BlogListSecond,
  BlogListThree,
} from "../../../cont/BlogList";
import he from "he";
function BlogsListings({ featured, topThree, middleFive, allBlogs }) {
  const navigate = useNavigate();
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={"/images/slider-next.svg"}
        alt={"arrow"}
        className={styles.nextArrow}
        width={30}
        height={30}
        onClick={onClick}
      />
    );
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={"/images/slider-next.svg"}
        alt={"arrow"}
        className={styles.prevArrow}
        width={30}
        height={30}
        onClick={onClick}
      />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    variableWidth: true,
    centerMode: false,

    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settingsTwo = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    variableWidth: true,
    centerMode: false,
  };

  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.topSection}>
          <div className={styles.headerSection}>
            <h1>Discover the Perfect Blog: Tailored to Your Needs</h1>
            <div className={styles.buttonWrapper}>
              {/* <div className={styles.button}>
                <p>Select your industry</p>
                <img
                  className={styles.arrow}
                  src={"/images/ic_down_arrow.svg"}
                  alt={"carousel item"}
                />
              </div> */}
              {/* <div className={styles.button}>
                <p>Select your service</p>
                <img
                  className={styles.arrow}
                  src={"/images/ic_down_arrow.svg"}
                  alt={"carousel item"}
                />
              </div> */}
            </div>
          </div>

          <div className={styles.topBlogs}>
            {/* Featured blog */}
            {featured && (
              <div
                className={styles.left}
                onClick={() => navigate(`/blogs/${featured.slug}`)}
              >
                <img
                  className={styles.heroImage}
                  src={featured.x_featured_media_medium}
                  alt={"carousel item"}
                />
                <h1>
                  {" "}
                  {he.decode(
                    featured?.title?.rendered ? featured.title.rendered : " ",
                  )}
                </h1>
                <h3>{featured.x_categories}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: featured.excerpt?.rendered,
                  }}
                />
                <div className={styles.author}>{featured.x_author}</div>
                <div className={styles.date}>{featured.x_date}</div>
              </div>
            )}

            {/* Top three blogs */}
            <div className={styles.right}>
              {topThree.map((item) => {
                return (
                  <div
                    className={styles.blogCard}
                    onClick={() => navigate(`/blogs/${item.slug}`)}
                  >
                    <img
                      className={styles.heroImage}
                      src={item.x_featured_media_medium}
                      alt={"carousel item"}
                    />
                    <div className={styles.details}>
                      <div className={styles.title}>
                        {he.decode(
                          item?.title?.rendered ? item.title.rendered : " ",
                        )}
                      </div>
                      <div className={styles.type}>{item.x_categories}</div>
                      <div className={styles.author}>{item.x_author}</div>
                      <div className={styles.date}>{item.x_date}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>

      <div className={styles.middleBlogs}>
        <Slider {...settingsTwo}>
          {middleFive.map((item) => {
            return (
              <div
                className={styles.blogCard}
                onClick={() => navigate(`/blogs/${item.slug}`)}
              >
                <img
                  className={styles.heroImage}
                  src={item.x_featured_media_medium}
                  alt={"carousel item"}
                />
                <div className={styles.details}>
                  <div className={styles.title}>{item.title?.rendered}</div>
                  <div className={styles.type}>{item.x_categories}</div>
                  <div className={styles.author}>{item.x_author}</div>
                  <div className={styles.date}>{item.x_date}</div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      <div className={styles.directors}>
        <img
          className={styles.heroImage}
          src={"/images/i_editors.png"}
          alt={"carousel item"}
        />
        <Container>
          <div className={styles.header}>
            <h1>From our Director’s desk</h1>
            <div className={styles.desc}>
              <p>Amit kumar</p>
            </div>
          </div>

          <div className={styles.blogCards}>
            <div className={styles.sliderContainer}>
              <Slider {...settings}>
                {BlogListThree.map((item) => {
                  return (
                    <div>
                      <div className={styles.blogCard}>
                        <img
                          className={styles.heroImage}
                          src={item.src}
                          alt={"carousel item"}
                        />
                        <div className={styles.details}>
                          <div className={styles.title}>
                            {he.decode(item?.header ? item?.header : " ")}
                          </div>
                          <div className={styles.type}>{item.type}</div>
                          <div className={styles.author}>{item.author}</div>
                          <div className={styles.date}>{item.date}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <div className={styles.mainList}>
          {allBlogs.map((item) => {
            return (
              <div
                className={styles.blogCard}
                onClick={() => navigate(`/blogs/${item.slug}`)}
              >
                <img
                  className={styles.heroImage}
                  src={item.x_featured_media_medium}
                  alt={"carousel item"}
                />
                <div className={styles.details}>

                  <div className={styles.title} title={item?.title?.rendered}> {he.decode(
                      item?.title?.rendered ? item.title.rendered : " ",
                  )}</div>
                  <div className={styles.type}>{item.x_categories}</div>
                  <div className={styles.author}>{item.x_author}</div>
                  <div className={styles.date}>{item.x_date}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.news}>
          <h1>Want To Hear More From Us?</h1>
          <p>
            Subscribe to our newsletter and get unlimited SEO updates, ranking
            tips, and revenue generation advice from our in-house experts.
          </p>
          <div className={styles.buttonWrapper}>
            <input
              name="myInput"
              placeholder={"Your Email"}
              className={styles.inputField}
            />
            <img
              className={styles.icon}
              src={"/images/ic_arrow_dark.svg"}
              alt={"carousel item"}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BlogsListings;
