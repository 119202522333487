import DIYHero from "../../../components/DIY/Hero";
import DIYDetails from "../../../components/DIY/Details";
import DIYTestimonials from "../../../components/DIY/Testimonials";
import HomeFaqs from "../../../components/Home/Faqs";
import Footer from "../../../components/Footer";
import Details from "../../../components/DFY/Details";
import styles from "../styles.module.scss";
import Container from "../../../components/Shared/Container";
import PrimaryButton from "../../../components/Shared/Buttons/Primary";
import SwitchButton from "../../../components/Shared/Buttons/Switch";
import { useMediaQuery } from "react-responsive";
import SureSection from "../../../components/ContentStrategy/SureSection";
import DFYClients from "../../../components/DFY/Clients";
import { Link, useNavigate } from "react-router-dom";
import DIYClients from "../../../components/DIY/Clients";
import {
  TestimonialsData,
  TestimonialsDataFound,
} from "../../../cont/ClientData";
import PricingOptions from "../../../components/DIY/PricingOptions";
import DFYExperts from "../../../components/DFY/Experts";
import PricingTable from "../../../components/DIY/PricingTable";
const logoList = [
  "/images/fl-logo-1.svg",
  "/images/fl-logo-2.svg",
  "/images/fl-logo-3.svg",
  "/images/fl-logo-5.svg",
  "/images/fl-logo-4.svg",
];

const logoListMobile = [
  "/images/fl-logo-1.svg",
  "/images/fl-logo-2.svg",
  "/images/fl-logo-3.svg",
  "/images/fl-logo-5.svg",
  "/images/fl-logo-4.svg",
];

const Pricing = [
  {
    price: "$15",
    unit: "Link",
    desc: "Budget PBN links",
    list: [
      "DA 30+ (Domain Authority)",
      "From Multi Niche Sites",
      "Do-Follow Link",
      "Unique Content",
      "Hosted In Unique IP’s",
      "Zero Footprints",
      "Permanent Post",
      "100% Safe",
    ],
  },
  {
    price: "$20",
    unit: "Link",
    desc: "Budget PBN links",
    list: [
      "High DA 50+ (Domain Authority)",
      "From Multi Niche Sites",
      "Do-Follow Link",
      "Unique Content",
      "Hosted In Unique IP’s",
      "Zero Footprints",
      "Permanent Post",
      "100% Safe",
    ],
  },
  {
    price: "$25",
    unit: "Link",
    desc: "Budget PBN links",
    list: [
      "High DA 50+ (Domain Authority)",
      "From Multi Niche Sites",
      "Do-Follow Link",
      "Unique Content",
      "Hosted In Unique IP’s",
      "Zero Footprints",
      "Permanent Post",
      "100% Safe",
    ],
  },
];

const SureData = [
  {
    icon: "/images/ic_niche_two.svg",
    header: "Niche Edit",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
  {
    icon: "/images/ic_guest_two.svg",
    header: "Guest Posting",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
  {
    icon: "/images/ic_traffic.svg",
    header: "Increases traffic",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
];

const DominateData = [
  {
    icon: "/images/speaker.svg",
    header: "ATTRACTIVE PROMOTIONAL CONTENT",
    desc: "Our service crafts compelling marketing content to attract and engage your target audience, optimizing your website for search engines and giving your business a competitive edge in targeted exposure.",
  },
  {
    icon: "/images/blog.svg",
    header: "FULLY USABLE SUB-BLOG",
    desc: 'Receive a fully functional sub-blog (WordPress, Blogspot, or similar CMS) with "about us" and "contact" pages. Use it to post content, market upcoming blogs, and attract fresh traffic.',
  },
  {
    icon: "/images/link.svg",
    header: "ONLY CONTEXTUAL LINKS",
    desc: "We don’t build blank profiles with out-of-place links. We create the backlinks (including profiles) filled with text preceding the link.",
  },
  {
    icon: "/images/wheel.svg",
    header: "UNIQUE RESOURCE BASE",
    desc: "If you already have certain web 2.0 properties built, you can share the list with us and we make sure you only get the unique properties built with us.",
  },
];

const FaqData = {
  Generals: [
    {
      question: "What are Foundation Links?",
      answer:
        "Foundation Links are hyperlinks placed on websites or pages that are considered authoritative, trustworthy, and credible within their respective fields. These links are often used to establish the credibility and authority of a website, particularly in the eyes of search engines like Google.",
    },
    {
      question: "How do Foundation Links benefit my website?",
      answer:
        "Foundation Links offer several benefits to your website. Firstly, they can significantly improve your website's search engine ranking, as search engines consider links from reputable sources as indicators of quality and relevance. Additionally, Foundation Links can drive targeted traffic to your site, as users are more likely to click on links from trusted sources.",
    },
    {
      question: "Where can I obtain Foundation Links for my website?",
      answer:
        "Acquiring Foundation Links requires strategic outreach and relationship-building with authoritative websites and organizations in your industry. This can involve guest blogging, contributing expert content, participating in industry forums or discussions, and networking with influencers and thought leaders.",
    },
    {
      question: "Are Foundation Links different from other types of backlinks?",
      answer:
        "Yes, Foundation Links are distinct from other types of backlinks in that they originate from highly reputable and authoritative sources within your industry. While other backlinks may come from a variety of sources, Foundation Links specifically come from websites that are recognized as leaders in their field.",
    },
    {
      question:
        "How can I ensure the quality of Foundation Links for my website?",
      answer:
        "Ensuring the quality of Foundation Links involves careful vetting of the websites and sources from which you acquire these links. Look for websites with strong domain authority, relevant content, and a track record of credibility and trustworthiness. Additionally, focus on building genuine relationships with these sources to foster long-term partnerships and secure high-quality Foundation Links for your website.",
    },
    {
      question: "How Long Does it Take to See Results?",
      answer:
        "Results vary based on industry competitiveness, content quality, and outreach effectiveness. Generally, improvement in rankings and traffic can take weeks to months, but it's a long-term strategy for sustained growth.",
    },
    {
      question: "How Does Foundation Link-Building Work?",
      answer:
        "Foundation link-building involves identifying relevant, authoritative websites within your industry or niche and reaching out to them to request backlinks. This process may include guest posting, submitting press releases, participating in industry forums, and engaging in other strategies to earn backlinks. The goal is to establish a network of high-quality links that point back to your website, thereby increasing its credibility and visibility to search engines.",
    },
    {
      question: "How many types of foundational backlinks are there?",
      answer:
        "There are several types of foundational backlinks, including directory submissions, social bookmarking, forum posting, blog commenting, and profile creation.",
    },
    {
      question: "What is the best time for building Foundational Backlinks?",
      answer:
        "The best time to build foundational backlinks is during the early stages of your website's development or SEO campaign. Establishing a strong foundation of backlinks early on can help improve your website's credibility and authority in the eyes of search engines. However, it's important to continue building and maintaining backlinks over time to ensure sustained SEO success.",
    },
    {
      question: "How To Maintain Foundation Backlinks Quality?",
      answer:
        "To maintain the quality of foundational backlinks, it's essential to regularly audit your backlink profile and ensure that links are coming from reputable and relevant sources. Remove or disavow any low-quality or spammy backlinks that could harm your website's SEO performance. Additionally, periodically update your foundational backlinks by adding new ones from trusted sources to keep your link profile diverse and authoritative.",
    },
  ],
};
const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "The team at RankJacker is great and super responsive to my needs any time of the day or night. I rate them as super professional, skilled & reasonably resourceful when it comes to SEO.",
    h3: "Chris R.",
    designation: "Founder",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "This service is incredible! I am saving time and cutting costs. It's the best decision I could have ever made for my agency business!",
    h3: "Joe C. Harris",
    designation: "Agency Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank Jacker always over-delivers and does a fantastic job! I highly recommend them.",
    h3: "Chris Wright",
    designation: "Agency Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "I'm really impressed by the RankJacker team. Not only do they deliver all the backlinks on time, their customer service is top-notch. They also provide updates when the content is indexed and the links are registered on SEMRush as well. Legit stuff.",
    h3: "Yvonne",
    designation: "Agency Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Great array of highly powerful foundational links. Found my brand traffic increasing and was able to trigger a Google Knowledge Panel quicker.",
    h3: "Chris Romero",
    designation: "Agency Owner",
    personSrc: "/images/i_client_one.png",
  },
];

const FoundationLink = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();
  return (
    <>
      <DIYHero
        bgImage={"/images/foundation-link-bg.png"}
        title={"FOUNDATION LINK BUILDING SERVICE"}
        subtitle={
          "Let’s help your campaign gain Trust, Relevancy & Authority and attract initial traction in the SERPs."
        }
        cta1Link={"https://rankjacker.spp.io/order/foundation-package"}
        cta2Link={"https://calendly.com/bookadiscoverycall/15"}
      />
      <div className={styles.wrapper}>
        <div className={styles.bgContainer} />
        <div className={styles.bgContainerBottom} />

        <div className={styles.mainContent}>
          <Container>
            <div className={styles.partners}>
              {!isMobile
                ? logoList.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })
                : logoListMobile.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })}
            </div>

            <div className={styles.appointment}>
              <div className={styles.left}>
                <div className={styles.topText}>WHAT ARE FOUNDATION LINKS?</div>
                <div className={styles.header}>
                  The most effective and proven strategy to build up the
                  groundwork for your new projects. Foundation links provide
                  initial shout-out for your brand, its story, the products &
                  services as well as trust building signals. We use a
                  proprietary methodology based on 5P Marketing Mix to create
                  synergy with most likable & popular niche properties.
                </div>
                <div className={styles.buttonCont}>
                  <Link to={"https://calendly.com/bookadiscoverycall/15"}>
                    <PrimaryButton buttonText={"Book an appointment"} />
                  </Link>
                </div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.logoImage}
                  src={"/images/ic_appointment.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </Container>

          {/*Dominate*/}
          <div className={styles.dominate}>
            <Container>
              <div className={styles.headerSection}>
                <h1>Why you should work with us</h1>
              </div>

              <div className={styles.cardsSection}>
                {DominateData.map((item, index) => {
                  return (
                    <div className={styles.cards} key={index}>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt={"logo"}
                      />

                      <h2>{item.header}</h2>
                      <p>{item.desc}</p>
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>
          <PricingTable />
          {/*<PricingOptions*/}
          {/*  data={Pricing}*/}
          {/*  heading={"Pricing and Options"}*/}
          {/*  subHeading={*/}
          {/*    "Through your RJ dashboard, you can Buy Pbn Links in a sizable quantity. You can get special wholesale pricing. Be it 10 or 60, each and every link of yours is posted safely on our high-quality blog network."*/}
          {/*  }*/}
          {/*  ctaLink={"https://rankjacker.spp.io/order/foundation-package"}*/}
          {/*/>*/}
          {/* <SureSection /> */}
          <DFYClients
            dark={true}
            header={"Working with Precision in Process, Excellence in Results"}
            desc={
              "Our impeccable back linking strategies give you access to robust data solutions- easy to implement and use. Here’s how we work."
            }
            ctaLink={
              "https://forms.zohopublic.in/rankmakeup/form/MonthlyLinkBuildingServicesFrom/formperma/6D8s1zzWj6n07cdswYzlM7bE8s-QgUC8PocNl6DkAc4"
            }
          />
        </div>
      </div>
      {/* <DIYTestimonials data={Testimonial}/> */}
      <div className={styles.expWrapper}>
        <DFYExperts
          bannerHeader={"Claim Your Free Backlink Audit Now"}
          bannerDesc={
            "Let us help you wead out Toxic, Irrelevant backlinks from your website!"
          }
        />
      </div>
      <DIYClients data={TestimonialsDataFound} />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};
export default FoundationLink;
