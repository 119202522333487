import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import { useMediaQuery } from "react-responsive";
import HomeTestimonial from "../../Home/Testimonial";

function DIYTestimonials() {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.header}>Why you should work with us</div>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.first}>
              Many benefits customer gets working with us!
            </div>
            <div className={styles.second}>
              Our software arms researchers with powerful data solutions that
              are simple to implement and utilize.
            </div>

            <div className={styles.list}>
              <div className={styles.items}>
                <img
                  className={styles.stars}
                  src={"/images/correct_cr.svg"}
                  alt={"stars"}
                />
                <p>Targeted Campaigns for Media Outreach.</p>
              </div>
              <div className={styles.items}>
                <img
                  className={styles.stars}
                  src={"/images/correct_cr.svg"}
                  alt={"stars"}
                />
                <p>Distribution to 450 News Sites & Media Outlets.</p>
              </div>
              <div className={styles.items}>
                <img
                  className={styles.stars}
                  src={"/images/correct_cr.svg"}
                  alt={"stars"}
                />
                <p>White Label Reporting for Resellers.</p>
              </div>
              <div className={styles.items}>
                <img
                  className={styles.stars}
                  src={"/images/correct_cr.svg"}
                  alt={"stars"}
                />
                <p>Up to 2 Contextual Links.</p>
              </div>
              <div className={styles.items}>
                <img
                  className={styles.stars}
                  src={"/images/correct_cr.svg"}
                  alt={"stars"}
                />
                <p>Brand Mentions along with White Hat Authority Links.</p>
              </div>
              <div className={styles.items}>
                <img
                  className={styles.stars}
                  src={"/images/correct_cr.svg"}
                  alt={"stars"}
                />
                <p>Well-articulated PR Articles syndicated post client Approval.</p>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={`${styles.items} ${styles.active} ${styles.one}`}>
              <img src="/images/linking.svg" alt=""/>
              <p>Bank Linking
                benefits</p>
            </div>
            <div className={`${styles.items} ${styles.two}`}>
              <h2>1500+</h2>
              <p>Agencies served</p>
            </div>
            <div className={`${styles.items} ${styles.three}`}>
              <h2>700+</h2>
              <p>
                SEO
                <br />
                clients
              </p>
            </div>
            <div className={`${styles.items} ${styles.four}`}>
              <h2>30K+</h2>
              <p>Links each year </p>
            </div>
          </div>
        </div>
        {/* <HomeTestimonial />
        <div className={styles.samples}>
          <h1>Our samples for your reference</h1>
          <div className={styles.cardsSection}>
            <div className={styles.cards}>
              <img
                className={styles.cardImage}
                src={"/images/i_sample_one.png"}
                alt={"stars"}
              />
              <h3>Sample 1</h3>
              <p>DIY service &sdot; Service &sdot; Industry</p>
            </div>
            {!isMobile && <div className={styles.cards}>
              <img
                className={styles.cardImage}
                src={"/images/i_sample_one.png"}
                alt={"stars"}
              />
              <h3>Sample 2</h3>
              <p>DIY service &sdot; Service &sdot; Industry</p>
            </div>}
            {!isMobile && <div className={styles.cards}>
              <img
                className={styles.cardImage}
                src={"/images/i_sample_one.png"}
                alt={"stars"}
              />
              <h3>Sample 3</h3>
              <p>DIY service &sdot; Service &sdot; Industry</p>
            </div>}
          </div>
        </div> */}
      </div>
    </Container>
  );
}

export default DIYTestimonials;
