import Footer from "../../components/Footer";
import HomeFaqs from "../../components/Home/Faqs";
import HomeWorkspace from "../../components/Home/Workspace";
import LocalSEOHero from "../../components/LocalSEO/Hero";
import LocalSEOPricing from "../../components/LocalSEO/Pricing";
import LocalSEOWhy from "../../components/LocalSEO/Why";
import LocalSEOWorks from "../../components/LocalSEO/Works";
import { useNavigate } from 'react-router-dom';
import EBook from "../../components/Shared/EBook";



const WorkspaceSubSectionData =[
    {
        text: "Direct realtions with our founder",
        icon: "/images/ic_easy_one.svg"
    },
    {
        text: "Your privacy contractually protected",
        icon: "/images/ic_easy_two.svg"
    },
    {
        text: "Dedicated team, super experimented & pedagogical ",
        icon: "/images/ic_easy_three.svg"
    },
    {
        text: "Direct realtions with our founder",
        icon: "/images/ic_easy_one.svg"
    },
    {
        text: "Your privacy contractually protected",
        icon: "/images/ic_easy_two.svg"
    },
    {
        text: "Dedicated team, super experimented & pedagogical",
        icon: "/images/ic_easy_three.svg"
    }
]
function LocalSEO() {

  const navigate = useNavigate();



  return (
    <>
      <LocalSEOHero />
      <LocalSEOWhy />
      <LocalSEOPricing />
      <HomeWorkspace
        tittle={"Workspace created for outsourcing efficiency"}
        subTitle={
          "real stories from the real people about their experience working with us."
        }
        buttonText={"Log in now"}
        onClick={() => false}
        subSectionTitle={"We make it easy for you"}
        subSectionSubTitle={"real stories from the real people about their experience working with us."}
        subSectionList={WorkspaceSubSectionData}

      />
      <LocalSEOWorks />
      <HomeFaqs />
      <Footer />
    </>
  );
}

export default LocalSEO;
