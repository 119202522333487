import PrimaryButton from "../../Shared/Buttons/Primary";
import Container from "../../Shared/Container";
import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import AnimatedContainer from "../../Animations/AnimatedContainer";
import React, { useState, useRef, useEffect } from "react";

const BlogBottomBanner = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({
        query: "(max-width: 576px)",
    });
    const spring = {
        type: "spring",
        damping: 25,
        stiffness: 100,
        delay: 2
    };
    const [clicked, setClicked] = useState(false);

    
    return (
        <AnimatedContainer 
            className={`${styles.container} ${clicked && styles.clicked}`}
            variants={{
                visible: { translateY: 0, opacity: 1, scaleX: 1 },
                hidden: {
                  translateY: 10,
                  opacity: 0,
                  scaleX: 0.9
                },
              }}
              transition={spring}>
            <Container>
                <div className={styles.containerTwo}>
                    <img
                        className={styles.person}
                        src={"/images/blog_bottom.png"}
                        alt={"stars"}
                    />
                    <div className={styles.mainText}>
                        <h3>
                        We hope enjoy reading this blog post.
                        </h3>
                        <p>
                        If you want to Improve your traffic, give us a call
                        </p>
                    </div>
                    <Link to={'https://calendly.com/bookadiscoverycall/15'}>
                        <PrimaryButton buttonText={"Book a call"}  />
                    </Link>
                    <img
                        className={styles.icon}
                        src={"/images/ic_close_main.png"}
                        alt={"stars"}
                        onClick={() => setClicked(true)}
                    />
                </div>
                
            </Container>
        </AnimatedContainer>
    );
};

export default BlogBottomBanner;
