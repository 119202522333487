export const StagesData = [
    {
        pill: "Stage 1",
        desc: "On Page And Technical Audit",
        cards: [
            {
                icon: "/images/step_one.svg",
                header: "On Page Audit and Optimization",
                text: "There are several factors which when done right makes a successful on-page optimization. We have seen a lot of our clients who have beautiful and well-crafted websites that just can’t rank in search engines. Factors like thin content, image to text ratio, duplicate contents, similar or missing title tags and meta descriptions and the list keeps going on… We have a checklist of more than 200 checkpoints, which we analyze and implement for optimizing your website's On-page SEO."
            },
            {
                icon: "/images/link.svg",
                header: "Technical SEO",
                text: " Technical SEO is also a part of on-page SEO but it has been segregated because it only revolves around the technical aspect and not the content aspect of the website. Factors like site speed, site architecture, XML sitemaps, no error codes or dead pages affect your technical SEO. We take full control of all these factors and make sure your website is fully optimized for both technical as well as on-page."
            }
        ]
    },
    {
        pill: "Stage 2",
        desc: "Research, Content Strategy And Creation",
        cards: [
            {
                icon: "/images/step_two.svg",
                header: "Keyword Research",
                text: "We help to push your rank forward for competitive keywords as well as keywords that has a high chance of getting quality traffic."
            },
            {
                icon: "/images/step_three.svg",
                header: "In-depth Customer Sales Cycle Research",
                text: " It is strange to see SEO agencies putting their SEO efforts without even understanding the business of their clients. We research through the client’s ideal customer profile and the sales cycle and then make our SEO strategy in resonance."
            },
            {
                icon: "/images/step_four.svg",
                header: "Competitive Analysis",
                text: "The secret to succeeding fast in business is simple – copy what works and add your own creativity to it. We analyze what your top competitors are doing and make a better plan for you to beat your competition."
            },
            {
                icon: "/images/step_five.svg",
                header: "Content Plan for 3 months",
                text: "After doing in depth research of your sales cycle and competitors, we plan and publish your content so that you rank and get quality traffic that converts."
            }
        ]
    },
    {
        pill: "Stage 3",
        desc: "Research, Content Strategy And Creation",
        cards: [
            {
                icon: "/images/step_six.svg",
                header: "Content Effectiveness",
                text: "After publishing your content we keep a track of the performance and rank of the site, in this stage we work on improvising the contents for better result and authority."
            }
        ]
    },
    {
        pill: "Stage 4",
        desc: "Off-Page Audit And Link Building",
        cards: [
            {
                icon: "/images/step_seven.svg",
                header: "Off Page Audit",
                text: "During our audit we check the backlink’s sources, its relevance with your website and quality. Apart from analyzing backlinks, we also analyze the present stage of the website (how much organic traffic it has) based on which we plan the link building process."
            },
            {
                icon: "/images/link.svg",
                header: "Link Building",
                text: "We build relevant and high authoritative links that bring in quality traffic to your website which in turn helps in Google ranking."
            }
        ]
    }
]