import React, { useRef, useEffect } from "react";
import styles from "./styles.module.scss";

const Modal = ({ isOpen, onClose, children }) => {
  const insideRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (insideRef.current && !insideRef.current.contains(event.target)) {
        onClose();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [insideRef]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal} ref={insideRef}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
