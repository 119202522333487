import DIYHero from "../../../components/DIY/Hero";
import DIYDetails from "../../../components/DIY/Details";
import DIYTestimonials from "../../../components/DIY/Testimonials";
import HomeFaqs from "../../../components/Home/Faqs";
import Footer from "../../../components/Footer";
import Details from "../../../components/DFY/Details";
import styles from "../styles.module.scss";
import Container from "../../../components/Shared/Container";
import PrimaryButton from "../../../components/Shared/Buttons/Primary";
import SwitchButton from "../../../components/Shared/Buttons/Switch";
import { useMediaQuery } from "react-responsive";
import PricingOptions from "../../../components/DIY/PricingOptions";
import DFYClients from "../../../components/DFY/Clients";
import {
  HaroData,
  TestimonialsData,
  TestimonialsDataHaro,
} from "../../../cont/ClientData";
import DIYClients from "../../../components/DIY/Clients";
import { Link } from "react-router-dom";

const logoList = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-5.png",
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-5.png",
];

const logoListMobile = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
];

const SureData = [
  {
    icon: "/images/ic_niche_two.svg",
    header: "Niche Edit",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
  {
    icon: "/images/ic_guest_two.svg",
    header: "Guest Posting",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
  {
    icon: "/images/ic_traffic.svg",
    header: "Increases traffic",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
];

const DominateData = [
  {
    icon: "/images/speaker.svg",
    header: "First thing first — SALES.",
    desc: "Haro Links elevate your business by featuring your website in authoritative stories, driving quality traffic, and increasing sales through enhanced visibility and credibility on reputable platforms.",
  },
  {
    icon: "/images/blog.svg",
    header: "Postive Impact on SEO",
    desc: "Getting backlinks is a major factor in SEO, letting the crawlers understand that your websites is worth others to visit and when you are getting backlinks from high DA sites, google appreciates it and as a result boosts up your ranking.",
  },
  {
    icon: "/images/link.svg",
    header: "Increased brand awareness",
    desc: "The publication sites are usually of a nature where audience has a trust on. So getting quotes in stories of big publication sites increases your brand awareness, authority and also leaves behind an imprint in the audience’s mind.",
  },
];

const Pricing = [
  {
    price: "$1,000",
    unit: "campaign",
    desc: "30+ PITCHES",
    descTwo: "2+ Successful Links",
    list: [
      "$500 (or less) per link",
      "Genuine HARO Outreach",
      "Quote Style Links",
      "High DA & Traffic Websites",
      "US & UK Written Pitches",
      "Full Pitch & Link Report",
    ],
  },
  {
    price: "$1,750",
    unit: "campaign",
    desc: "50+ PITCHES",
    descTwo: "4+ Successful Links",
    list: [
      "$437.50 (or less) per link",
      "Genuine HARO Outreach",
      "Quote Style Links",
      "High DA & Traffic Websites",
      "US & UK Written Pitches",
      "Full Pitch & Link Report",
    ],
  },
  {
    price: "$2,250",
    unit: "campaign",
    desc: "70+ PITCHES",
    descTwo: "6+ Successful Links",
    list: [
      "$375 (or less) per link",
      "Genuine HARO Outreach",
      "Quote Style Links",
      "High DA & Traffic Websites",
      "US & UK Written Pitches",
      "Full Pitch & Link Report",
    ],
  },
];

const FaqData = {
  Generals: [
    {
      question: "Can you Guarantee Links?",
      answer:
        "We charge on a per-link basis, meaning we continue working for you until you receive the links you paid for. While there is no way to guarantee links, we find that this method is about as close as you can get.",
    },
    {
      question: "Can I Use HARO Alongside Other Link Building Strategies?",
      answer:
        "Of course! HARO is an excellent way to get links from high DA publications. But other forms of link building still have a place in any good strategy. We also provide guest posting services and an all-inclusive link building solution.",
    },
    {
      question: "Do I get to see the links first?",
      answer:
        "To keep the entire link building process transparent, we share the entire process in a shared Google sheet. Where we add the sites domains, anchor text & target link urls etc. For you to approve or reject before we go ahead with placing your links on high domain authority websites.",
    },
    {
      question: "Who writes the content?",
      answer: "",
    },
  ],
};
const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's HARO links have been a game-changer for our website's visibility. Highly recommend their service to anyone serious about boosting their SEO efforts.",
    h3: "John Doe",
    designation: "CEO of XYZ Company",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Thanks to Rank-Jacker SEO's HARO links, our organic traffic has skyrocketed. Their service is top notch and worth every penny.",
    h3: "Jane Smith",
    designation: "Marketing Manager at ABC Corporation",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's HARO link-building service exceeded our expectations. Our search engine rankings have noticeably improved since we started working with them.",
    h3: "Mark Johnson",
    designation: "Founder of DEF Enterprises",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We've tried several SEO services before, but none have delivered results like Rank-Jacker SEO's HARO links. Our website's authority has increased significantly, thanks to their expertise.",
    h3: "Sarah Brown",
    designation: "Director of Marketing at GHI Solutions",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's HARO link building service is simply outstanding. Our website's traffic and domain authority have seen remarkable growth since partnering with them.",
    h3: "Michael Clark",
    designation: "COO of JKL Industries",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's HARO links have been instrumental in our SEO strategy. Their professional approach and high-quality backlinks have made a noticeable impact on our search engine rankings.",
    h3: "Emily White",
    designation: "Marketing Director at MNO Corporation",
    personSrc: "/images/i_client_one.png",
  },
];

const HaroLink = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <>
      <DIYHero
        bgImage={"/images/foundation-link-bg.png"}
        title={"HARO LINK BUILDING SERVICES"}
        subtitle={
          "With our Haro Link Building service acquire high authority quote links from reporters in exclusive media publications."
        }
        cta2Link={"https://calendly.com/bookadiscoverycall/15"}
      />
      <div className={styles.wrapper}>
        <div className={styles.bgContainer} />
        {/* <div className={styles.bgContainerBottom} /> */}

        <div className={styles.mainContent}>
          <Container>
            <div className={styles.partners}>
              {!isMobile
                ? logoList.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })
                : logoListMobile.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })}
            </div>

            <div className={styles.appointment}>
              <div className={styles.left}>
                <div className={styles.topText}>
                  WE JUST BUILD QUALITY LINKS
                </div>
                <div className={styles.header}>
                  HARO or Help A Reporter Out is a service where journalists and
                  bloggers ask for the input of subject matter experts on
                  various topics and industries for publishing articles on the
                  publication sites. We do a done-for-you custom outreach to
                  these bloggers and journalist to get backlinks for you from
                  their websites. But during the process we make sure we only
                  reach out to websites which have a very high DA so that we can
                  ensure and deliver you only quality links.
                </div>

                <div className={styles.buttonCont}>
                  <Link to={"https://calendly.com/bookadiscoverycall/15"}>
                    <PrimaryButton buttonText={"Book an appointment"} />
                  </Link>
                </div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.logoImage}
                  src={"/images/ic_appointment.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </Container>

          {/*Dominate*/}
          {/* <div className={styles.dominate}>
            <Container>
              <div className={styles.headerSection}>
                <h1>WE JUST BUILD QUALITY LINKS</h1>
                <h3>
                  HARO or Help A Reporter Out is a service where journalists and
                  bloggers ask for the input of subject matter experts on
                  various topics and industries for publishing articles on the
                  publication sites.
                  <br /> We do a done-for-you custom outreach to these bloggers
                  and journalist to get backlinks for you from their websites.
                  <br /> But during the process we make sure we only reach out
                  to websites which have a very high DA so that we can ensure
                  and deliver you only quality links.
                </h3>
              </div>

              <div className={`${styles.cardsSection} ${styles.cardsSectionAlter}`}>
                {DominateData.map((item, index) => {
                  return (
                    <div className={styles.cards} key={index}>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt={"logo"}
                      />

                      <h2>{item.header}</h2>
                      {!isMobile && (
                        <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                      )}
                    </div>
                  );
                })}
              </div>
            </Container>
          </div> */}
          <DFYClients
            data={HaroData}
            header={"THE TOP ADVANTAGES OF ADDING HARO LINKS TO YOUR WEBSITE"}
            desc={
              "With our experience & knowledge in SEO Domain, you can rely on us for an effective PR campaign, and rest assured that you would get complete value for your money. A few of the highlights of our service:-"
            }
            ctaLink={'https://forms.zohopublic.in/rankmakeup/form/MonthlyLinkBuildingServicesFrom/formperma/6D8s1zzWj6n07cdswYzlM7bE8s-QgUC8PocNl6DkAc4'}
          />
          <PricingOptions
            data={Pricing}
            heading={"Pricing and Options"}
            subHeading={
              "Through your RJ dashboard, you can Buy Pbn Links in a sizable quantity. You can get special wholesale pricing. Be it 10 or 60, each and every link of yours is posted safely on our high-quality blog network."
            }
            ctaLink={'https://rankjacker.spp.io/order/haro-link'}
          />
        </div>
      </div>
      <DIYTestimonials data={Testimonial} />
      <DIYClients data={TestimonialsDataHaro} />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};
export default HaroLink;
