import PrimaryButton from "../Shared/Buttons/Primary";
import Container from "../Shared/Container";
import styles from "./styles.module.scss";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import AnimatedContainer from "../Animations/AnimatedContainer";

const Header = () => {
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showResourcesDropdown, setShowResourcesDropdown] = useState(false);
  const servicesDropdownRef = useRef(null);
  const resourcesDropdownRef = useRef(null);
  const [servicesTimeoutId, setServicesTimeoutId] = useState(null);
  const [resourcesTimeoutId, setResourcesTimeoutId] = useState(null);
  const [showMobileHeader, setShowMobileHeader] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showResources, setShowResources] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();

  const toggleServicesDropdown = () =>
    setShowServicesDropdown(!showServicesDropdown);
  const toggleResourcesDropdown = () =>
    setShowResourcesDropdown(!showResourcesDropdown);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        servicesDropdownRef.current &&
        !servicesDropdownRef.current.contains(event.target)
      ) {
        setShowServicesDropdown(false);
      }
      if (
        resourcesDropdownRef.current &&
        !resourcesDropdownRef.current.contains(event.target)
      ) {
        setShowResourcesDropdown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [servicesDropdownRef, resourcesDropdownRef]);

  const closeDropdown = () => {
    setShowServicesDropdown(false);
    setShowResourcesDropdown(false);
  };

  const handleServicesMouseLeave = () => {
    const id = setTimeout(() => {
      setShowServicesDropdown(false);
    }, 300); // 2 seconds delay
    setServicesTimeoutId(id);
  };

  const handleServicesMouseEnter = () => {
    if (servicesTimeoutId) {
      clearTimeout(servicesTimeoutId);
      setServicesTimeoutId(null);
    }
    setShowServicesDropdown(true);
  };

  const handleResourcesMouseLeave = () => {
    const id = setTimeout(() => {
      setShowResourcesDropdown(false);
    }, 300); // 2 seconds delay
    setResourcesTimeoutId(id);
  };

  const handleResourcesMouseEnter = () => {
    if (resourcesTimeoutId) {
      clearTimeout(resourcesTimeoutId);
      setResourcesTimeoutId(null);
    }
    setShowResourcesDropdown(true);
  };
  useEffect(() => {
    return () => {
      if (servicesTimeoutId) clearTimeout(servicesTimeoutId);
      if (resourcesTimeoutId) clearTimeout(resourcesTimeoutId);
    };
  }, [servicesTimeoutId, resourcesTimeoutId]);

  const spring = {
    type: "spring",
    damping: 20,
    stiffness: 100
  }

  return (
    <header className={styles.header}>
      <Container>
        <AnimatedContainer className={styles.wrapper} variants={{
          visible: { opacity: 1, y: 0, scaleX: 1, },
          hidden: { opacity: 0, y: -50, scaleX: 0.8 }
        }}
          transition={spring}>
          {!isMobile && (
            <div onClick={() => navigate("/")}>
              <img
                className={styles.logoImage}
                src={"/images/ic_main_logo.png"}
                alt={"logo"}
              />
            </div>
          )}

          {!isMobile && (
            <ul>
              <li onClick={() => navigate("/")}>
                <p>Home</p>
              </li>
              <li
                onMouseEnter={handleServicesMouseEnter}
                onMouseLeave={handleServicesMouseLeave}
                ref={servicesDropdownRef}
              >
                <p>Services</p>
                {showServicesDropdown && (
                  <div className={styles.dropdown}>
                    <div className={styles.serviceDropdown}>
                      <div className={styles.container}>
                        <div className={styles.title}>Do it yourself</div>
                        <div className={styles.listContainer}>
                          <div className={styles.linkList}>
                            <a onClick={closeDropdown} href="/services/niche-edit-links">
                              <img
                                src="/images/niche-icon.svg"
                                alt="blog icon"
                              />
                              Niche Edits
                            </a>
                            <a onClick={closeDropdown} href="/services/buy-pbn-links">
                              <img src="/images/pbn-icon.svg" alt="blog icon" />
                              PBN links
                            </a>
                            <a onClick={closeDropdown} href="/services/foundation-packages/">
                              <img
                                src="/images/foundation-icon.svg"
                                alt="blog icon"
                              />
                              Foundation Link
                            </a>
                            <a onClick={closeDropdown} href="/services/buy-backlinks">
                              <img
                                src="/images/foundation-icon.svg"
                                alt="blog icon"
                              />
                              Buy Backlinks
                            </a>
                            <a onClick={closeDropdown} href="/services/haro-links">
                              <img
                                src="/images/foundation-icon.svg"
                                alt="blog icon"
                              />
                              Haro Links
                            </a>
                          </div>
                          <div className={styles.linkList}>
                            <a onClick={closeDropdown} href="/services/buy-guest-posts">
                              <img
                                src="/images/guest-post-icon.svg"
                                alt="blog icon"
                              />
                              Guest Posts{" "}
                            </a>
                            <a onClick={closeDropdown} href="/services/best-pr-distribution/">
                              <img
                                src="/images/press-icon.svg"
                                alt="blog icon"
                              />
                              Press release
                            </a>
                            <a onClick={closeDropdown} href="/custom-outreach">
                              <img
                                src="/images/customer-outreach-icon.svg"
                                alt="blog icon"
                              />
                              DFY Customer Outreach
                            </a>
                            <a onClick={closeDropdown} href="/services/advanced-local-citation-service">
                              <img
                                src="/images/customer-outreach-icon.svg"
                                alt="blog icon"
                              />
                              Local Citation
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className={styles.container}>
                        <div className={styles.title}>Done for you</div>
                        <div className={styles.listContainer}>
                          <div className={styles.linkList}>
                            <a onClick={closeDropdown} href="/services/dfy-managed-seo/">
                              <img
                                src="/images/managed-seo-icon.svg"
                                alt="blog icon"
                              />
                              Managed SEO
                            </a>
                            <a onClick={closeDropdown} href="/services/monthly-link-building">
                              <img
                                src="/images/link-building-icon.svg"
                                alt="blog icon"
                              />
                              Monthly link building
                            </a>
                            <a onClick={closeDropdown} href="/services/seo-consulting">
                              <img
                                src="/images/seo-consultation.svg"
                                alt="blog icon"
                              />
                              SEO consultation
                            </a>
                          </div>
                          <div className={styles.linkList}>
                            <a onClick={closeDropdown} href="/services/seo-audit">
                              <img
                                src="/images/seo-audit-icon.svg"
                                alt="blog icon"
                              />
                              SEO audit
                            </a>
                            <a onClick={closeDropdown} href="/services/white-label-seo">
                              <img
                                src="/images/white-label-seo-icon.svg"
                                alt="blog icon"
                              />
                              White Label SEO
                            </a>
                            <a onClick={closeDropdown} href="/services/seo-content-strategy">
                              <img
                                src="/images/content-strategy.svg "
                                alt="blog icon"
                              />
                              Content Strategy
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
              <li
                onMouseEnter={handleResourcesMouseEnter}
                onMouseLeave={handleResourcesMouseLeave}
                ref={resourcesDropdownRef}
              >
                <p>Resources</p>
                {showResourcesDropdown && (
                  <div className={styles.dropdown}>
                    <div className={styles.resourceDropdown}>
                      <div className={styles.title}>Resources</div>
                      <div className={styles.linkList}>
                        <a onClick={closeDropdown} href="/all-blogs">
                          <img src="/images/blog-icon.svg" alt="blog icon" />
                          Blogs
                        </a>
                        <a onClick={closeDropdown} href="#">
                          <img src="/images/samples-icon.svg" alt="blog icon" />
                          Samples
                        </a>
                        <a onClick={closeDropdown} href="/case-study">
                          <img
                            src="/images/case-study-icon.svg"
                            alt="blog icon"
                          />
                          Case Study
                        </a>
                        <a onClick={closeDropdown} href="/testimonials">
                          <img
                            src="/images/case-study-icon.svg"
                            alt="blog icon"
                          />
                          Testimonials
                        </a>
                        <a onClick={closeDropdown} href="/privacy-policy">
                          <img
                            src="/images/case-study-icon.svg"
                            alt="blog icon"
                          />
                          Privacy Policy
                        </a>
                        <a onClick={closeDropdown} href="/refund-policy">
                          <img
                            src="/images/case-study-icon.svg"
                            alt="blog icon"
                          />
                          Refund Policy
                        </a>
                        <a onClick={closeDropdown} href="/ebook">
                          <img
                            src="/images/case-study-icon.svg"
                            alt="blog icon"
                          />
                          E-Book
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </li>
              <li onClick={() => navigate("/about-us")}>
                <p>About us</p>
              </li>
            </ul>
          )}

          {!isMobile && (
            <div className={styles.buttonWrapper}>
              <Link to={"https://rankjacker.spp.io/login/?_ga=2.101955144.859561472.1713681588-1028127926.1706871095&_gl=1*hnopx1*_ga*MTAyODEyNzkyNi4xNzA2ODcxMDk1*_ga_3YWLK2XXC3*MTcxMzY4MTU4OC4xNC4xLjE3MTM2ODIxNTQuNTYuMC4w"} target="_blank" rel="noopener noreferrer">
                <PrimaryButton buttonText={"Login"} outlined={true} />
              </Link>
              <Link to={"/contact-us"}>
                <PrimaryButton buttonText={"Contact us"} />
              </Link>
            </div>
          )}

          {isMobile && (
            <div onClick={() => navigate("/")}>
              <img
                className={styles.logoMobile}
                src={"/images/ic_logo_mobile.png"}
                alt={"logo"}
              />
            </div>
          )}

          {isMobile && (
            <div>
              <img
                className={styles.burgerIcon}
                src={"/images/ic_burger_menu.svg"}
                alt={"logo"}
                onClick={() => setShowMobileHeader(!showMobileHeader)}
              />
            </div>
          )}

          {isMobile && showMobileHeader && <div className={styles.mobileHeader}>
            <div className={styles.topSection}>
              <div className={styles.each} onClick={() => {
                setShowMobileHeader(false);
                navigate('/');
              }}>
                Home
              </div>
              <hr />
              <div className={styles.each} onClick={() => {
                setShowMobileHeader(false);
                navigate('/about-us');
              }}>
                About us
              </div>
              <hr />
              <div className={styles.each} onClick={() => {
                setShowResources(false);
                setShowServices(!showServices);
              }}>
                Services
              </div>
              {showServices && <div className={styles.serviceDropdown}>
                <div className={styles.container}>
                  <div className={styles.title}>Do it yourself</div>
                  <div className={styles.listContainer}>
                    <div className={styles.linkList}>
                      <a onClick={closeDropdown} href="/services/niche-edit-links">
                        <img
                          src="/images/niche-icon.svg"
                          alt="blog icon"
                        />
                        Niche Edits
                      </a>
                      <a onClick={closeDropdown} href="/services/buy-pbn-links">
                        <img src="/images/pbn-icon.svg" alt="blog icon" />
                        PBN links
                      </a>
                      <a onClick={closeDropdown} href="/services/foundation-packages/">
                        <img
                          src="/images/foundation-icon.svg"
                          alt="blog icon"
                        />
                        Foundation Link
                      </a>
                      <a onClick={closeDropdown} href="/services/buy-backlinks">
                        <img
                          src="/images/foundation-icon.svg"
                          alt="blog icon"
                        />
                        Buy Backlinks
                      </a>
                      <a onClick={closeDropdown} href="/services/haro-links">
                        <img
                          src="/images/foundation-icon.svg"
                          alt="blog icon"
                        />
                        Haro Links
                      </a>
                    </div>
                    <div className={styles.linkList}>
                      <a onClick={closeDropdown} href="/services/buy-guest-posts">
                        <img
                          src="/images/guest-post-icon.svg"
                          alt="blog icon"
                        />
                        Guest Posts{" "}
                      </a>
                      <a onClick={closeDropdown} href="/services/best-pr-distribution/">
                        <img
                          src="/images/press-icon.svg"
                          alt="blog icon"
                        />
                        Press release
                      </a>
                      <a onClick={closeDropdown} href="/custom-outreach">
                        <img
                          src="/images/customer-outreach-icon.svg"
                          alt="blog icon"
                        />
                        Customer Outreach
                      </a>
                      <a onClick={closeDropdown} href="/services/advanced-local-citation-service">
                        <img
                          src="/images/customer-outreach-icon.svg"
                          alt="blog icon"
                        />
                        Local Citation
                      </a>
                    </div>
                  </div>
                </div>
                <div className={styles.container}>
                  <div className={styles.title}>Done for you</div>
                  <div className={styles.listContainer}>
                    <div className={styles.linkList}>
                      <a onClick={closeDropdown} href="/services/dfy-managed-seo/">
                        <img
                          src="/images/managed-seo-icon.svg"
                          alt="blog icon"
                        />
                        Managed SEO
                      </a>
                      <a onClick={closeDropdown} href="/services/monthly-link-building">
                        <img
                          src="/images/link-building-icon.svg"
                          alt="blog icon"
                        />
                        Monthly link building
                      </a>
                      <a onClick={closeDropdown} href="/services/seo-consulting">
                        <img
                          src="/images/seo-consultation.svg"
                          alt="blog icon"
                        />
                        SEO consultation
                      </a>
                    </div>
                    <div className={styles.linkList}>
                      <a onClick={closeDropdown} href="/services/seo-audit">
                        <img
                          src="/images/seo-audit-icon.svg"
                          alt="blog icon"
                        />
                        SEO audit
                      </a>
                      <a onClick={closeDropdown} href="/services/white-label-seo">
                        <img
                          src="/images/white-label-seo-icon.svg"
                          alt="blog icon"
                        />
                        White Label SEO
                      </a>
                      <a onClick={closeDropdown} href="/services/seo-content-strategy">
                        <img
                          src="/images/content-strategy.svg "
                          alt="blog icon"
                        />
                        Content Strategy
                      </a>
                    </div>
                  </div>
                </div>
              </div>}
              <hr />
              <div className={styles.each} onClick={() => {
                setShowResources(!showResources);
                setShowServices(false);
              }}>
                Resources
              </div>
              {showResources && <div className={styles.resourceDropdown}>
                <div className={styles.linkList}>
                  <a onClick={closeDropdown} href="/all-blogs">
                    <img src="/images/blog-icon.svg" alt="blog icon" />
                    Blogs
                  </a>
                  <a onClick={closeDropdown} href="#">
                    <img src="/images/samples-icon.svg" alt="blog icon" />
                    Samples
                  </a>
                  <a onClick={closeDropdown} href="/case-study">
                    <img
                      src="/images/case-study-icon.svg"
                      alt="blog icon"
                    />
                    Case Study
                  </a>
                  <a onClick={closeDropdown} href="/testimonials">
                    <img
                      src="/images/case-study-icon.svg"
                      alt="blog icon"
                    />
                    Testimonials
                  </a>
                  <a onClick={closeDropdown} href="/privacy-policy">
                    <img
                      src="/images/case-study-icon.svg"
                      alt="blog icon"
                    />
                    Privacy Policy
                  </a>
                  <a onClick={closeDropdown} href="/refund-policy">
                    <img
                      src="/images/case-study-icon.svg"
                      alt="blog icon"
                    />
                    Refund Policy
                  </a>
                  <a onClick={closeDropdown} href="/ebook">
                    <img
                      src="/images/case-study-icon.svg"
                      alt="blog icon"
                    />
                    E-Book
                  </a>
                </div>
              </div>}
              <hr />

            </div>
            <div className={styles.buttonContOne}>
              <Link className={styles.each} to={"https://rankjacker.spp.io/login/?_ga=2.101955144.859561472.1713681588-1028127926.1706871095&_gl=1*hnopx1*_ga*MTAyODEyNzkyNi4xNzA2ODcxMDk1*_ga_3YWLK2XXC3*MTcxMzY4MTU4OC4xNC4xLjE3MTM2ODIxNTQuNTYuMC4w"} target="_blank" rel="noopener noreferrer">
                <PrimaryButton buttonText={"Login"} outlined={true} />
              </Link>
              <Link to={"/contact-us"} className={styles.each}>
                <PrimaryButton buttonText={"Contact us"} />
              </Link>
            </div>
          </div>}
        </AnimatedContainer>
      </Container>
    </header>
  );
};

export default Header;
