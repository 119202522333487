import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import styles from "./styles.module.scss";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../Modal";

const Data = [
  {
    icon: "/images/ic_audit.svg",
    header: "On Page And Technical SEO Audit",
    desc: `Before beginning to develop backlinks, a well-thought-out on-page SEO strategy supported by a technical audit is essential to the success of your campaign. For any content marketing strategy to be successful, technical SEO must be fixed because it makes search engines more receptive to your website. Optimize your website's performance! Technical SEO dives into the nitty-gritty—site architecture, speed, XML sitemaps, On-page SEO and error-free pages—ensuring a seamless user experience and top-notch search engine ranking.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "On-Page SEO and targeted Content Strategy",
    desc: `The demographics, audience, and target goals of your website must all be precisely reflected in the on-page SEO of your website. This is also important for your website's long-term organic rankings. 
        A well-maintained blog is a crucial component of on-page SEO, as it guarantees that your company receives the most attention from search engines and guides visitors to your funnel.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Link Building And Off-Page Auditing",
    desc: `We examine the backlink's quality, relevance to your website, and sources in this audit. We evaluate the website's existing condition in addition to looking at backlinks, and we use the data to plan the link-building effort.`,
  },
];

const DFYClients = ({
  dark = false,
  header,
  desc,
  data,
  ctaLink,
  sliderBottomText,
  lightButton=false
}) => {
  const [activeIndex, setActive] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef();
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const SliderData = data ? data : Data;
  const navigate = useNavigate();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    centerMode: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    beforeChange: (oldIndex, newIndex) => {
      setActive(newIndex);
    },
  };
  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.topSection}>
          <div className={styles.left}>
            <div className={`${styles.header} ${dark && styles.dark}`}>
              {header ? header : "Helping clients do more with curated process"}
            </div>
            <div className={`${styles.desc} ${dark && styles.dark}`}>
              {desc
                ? desc
                : "Our software arms researchers with powerful data solutions that are simple to implement and utilize."}
            </div>
            <div className={styles.buttonCont}>
              {ctaLink ? (
                <PrimaryButton
                  buttonText={"Talk to us"}
                  onClick={openModal}
                  light={lightButton}
                />
              ) : (
                <Link to={"/contact-us"} target={"_blank"}>
                  <PrimaryButton buttonText={"Talk to us"} light={lightButton} />
                </Link>
              )}
            </div>
          </div>
          <div className={styles.right}>
            <Slider {...settings} ref={sliderRef}>
              {SliderData.map((item, index) => (
                <div key={index} className={styles.cardwrapper}>
                  <div className={styles.card}>
                    <img className={styles.icon} src={item.icon} alt={"logo"} />
                    <h3>{item.header}</h3>
                    <p>{item.desc}</p>
                  </div>
                </div>
              ))}
            </Slider>

            {/* <div className={styles.card}>
                                            <img className={styles.icon} src={Data[0].icon} alt={'logo'} />
                                            <h3>
                                                {Data[0].header}
                                            </h3>
                                            <p>
                                                {Data[0].desc}
                                            </p>
                                        </div>
                         */}

            {!isMobile && (
              <div className={styles.bottomSec}>
                <div className={`${styles.left} ${dark && styles.dark}`}>
                  {sliderBottomText ? sliderBottomText : `Our process`}
                </div>
                <div className={`${styles.right} ${dark && styles.dark}`}>
                  {activeIndex + 1}/{SliderData.length}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <iframe
          src={ctaLink}
          width="100%"
          height="600px"
          style={{ border: "none" }}
          title="Contact Form"
        ></iframe>
      </Modal>
    </Container>
  );
};

export default DFYClients;
