import ContentStategyDetails from "../../components/ContentStrategy/Details";
import ContentStrategyHero from "../../components/ContentStrategy/Hero";
import Footer from "../../components/Footer";
import HomeFaqs from "../../components/Home/Faqs";
import LocalSeoWorks from "../../components/LocalSEO/Works";
import DIYHero from "../../components/DIY/Hero";
import styles from "../DIY/styles.module.scss";
import Container from "../../components/Shared/Container";
import { Link } from "react-router-dom";
import PrimaryButton from "../../components/Shared/Buttons/Primary";
import SureSectionTwo from "../../components/DIY/SureTwo";
import DIYBacklinkPackages from "../../components/DIY/BacklinkPackages";
import DFYClients from "../../components/DFY/Clients";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { FaqHome } from "../../cont/Home";
import HomeTestimonial from "../../components/Home/Testimonial";
import DIYTestimonials from "../../components/DIY/Testimonials";
import OurServicesCS from "../../components/ContentStrategy/OurServicesCS";

const logoList = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-5.png",
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-5.png",
];

const logoListMobile = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
];

const SliderData = [
  {
    icon: "/images/ic_time.svg",
    header: "Time-Saving",
    desc: `Focus on your core business activities while we handle the content.`,
  },
  {
    icon: "/images/ic_bright_star.svg",
    header: "Expertise",
    desc: `Leverage the skills of seasoned content professionals.`,
  },
  {
    icon: "/images/ic_consistency.svg",
    header: "Consistency",
    desc: `Regularly published, high-quality content.`,
  },
  {
    icon: "/images/ic_seo_1.svg",
    header: "SEO Optimization",
    desc: `Improve search engine rankings with expertly crafted content.`,
  },
  {
    icon: "/images/scalability.svg",
    header: "Scalability",
    desc: `Flexible solutions that grow with your business.`,
  },
];

const SliderDataTwo = [
  {
    icon: "/images/scw_1.png",
    header: "Initial Consultation",
    desc: `We discuss your content needs and goals.`,
  },
  {
    icon: "/images/scw_2.png",
    header: "Customized Strategy Development",
    desc: `We create a tailored content plan.`,
  },
  {
    icon: "/images/scw_3.png",
    header: "Content Creation",
    desc: `Our expert writers craft your blog posts and landing pages.`,
  },
  {
    icon: "/images/scw_4.png",
    header: "Approval and Revisions",
    desc: `You review the content, and we make any necessary adjustments.`,
  },
];

const Testimonial = [
  {
    brand: "/images/ic_segate_dark.svg",
    p: "Rank Jacker went above and beyond to support the expansion of our online presence and delivered a much-needed boost. They provided us with a roadmap for content creation, which helped us rank higher for the content and drive more visitors to the website! Excellent outcomes and first-rate assistance.",
    h3: "D. Surrey",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-1.png",
  },
  {
    brand: "/images/ic_databox_dark.svg",
    p: "Finding an SEO team that produces results might be challenging, but we discovered that SEO Experts at Rank Jacker do just that. Mr Amit is focused, and receptive, and above all, he makes a difference in helping us boost our leads. I heartily suggest this SEO experts team. - Morris Tyndall , I had been collaborating with an e-commerce-focused company for several years to design my website. The business provided me with all the required services, including occasional SEO audits",
    h3: "Diane Tate",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-2.png",
  },
  {
    brand: "/images/ic_business_insider_dark.svg",
    p: "Working with Rank Jacker was the right choice. They are SEO experts, and their experience will benefit your business. With them, your business will expand and gain exposure more quickly as they effortlessly traverse the new Google guidelines. Strongly recommended.",
    h3: "Jerry Page",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-3.jpg",
  },
  {
    brand: "/images/ic_legalzoom_dark.svg",
    p: "Rank Jacker went above and beyond to support the expansion of our online presence and delivered a much-needed boost. They provided us with a roadmap for content creation, which helped us rank higher for the content and drive more visitors to the website! Excellent outcomes and first-rate assistance.",
    h3: "D. Surrey",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-4.png",
  },
  {
    brand: "/images/ic_segate_dark.svg",
    p: "Rank Jacker went above and beyond to support the expansion of our online presence and delivered a much-needed boost. They provided us with a roadmap for content creation, which helped us rank higher for the content and drive more visitors to the website! Excellent outcomes and first-rate assistance.",
    h3: "D. Surrey",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-5.png",
  },
];

const ContentStrategy = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <>
      <DIYHero
        bgImage={"/images/foundation-link-bg.png"}
        title={
          "Transform Your Online Presence with Our Done-For-You Blog & Landing Page Content Writing Service"
        }
        subtitle={"Unlock the Power of Compelling Content Without the Hassle"}
        cta2Link={"https://calendly.com/bookadiscoverycall/15"}
      />
      <div className={styles.wrapper}>
        <div className={styles.bgContainer} />
        <div className={styles.bgContainerBottom} />

        <div className={styles.mainContent}>
          <Container>
            <div className={styles.partners}>
              {!isMobile
                ? logoList.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })
                : logoListMobile.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })}
            </div>

            <div className={styles.appointment}>
              <div className={styles.left}>
                <div className={styles.topText}>
                  What is Done-For-You Blog & Landing Page Content Writing?
                </div>
                <div className={styles.header}>
                  In today’s digital landscape, content is king. Our
                  Done-For-You (DFY) blog and landing page content writing
                  service allows you to leverage expertly crafted content
                  without lifting a finger. Whether you need engaging blog posts
                  to attract readers or high-converting landing pages to boost
                  your sales, we have you covered.
                </div>
                <div className={styles.buttonCont}>
                  <Link to={"https://calendly.com/bookadiscoverycall/15"}>
                    <PrimaryButton buttonText={"Book an appointment"} />
                  </Link>
                </div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.logoImage}
                  src={"/images/ic_appointment.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </Container>
          <OurServicesCS />
          <DFYClients
            dark={true}
            header={
              "Why Choose Our DFY Blog & Landing Page Content Writing Service?"
            }
            desc={
              "Partnering with us means gaining access to a host of benefits that will elevate your content strategy and enhance your online presence:"
            }
            data={SliderData}
            sliderBottomText={"Our Benefits"}
          />
        </div>
      </div>
      <LocalSeoWorks
        heading={"Success Stories from Our Clients"}
        subHeading={
          "We take pride in the success of our clients. Here are some real-life examples of how our DFY content writing services have transformed businesses:"
        }
      />
      <HomeTestimonial data={Testimonial} />
      <DIYTestimonials />
      <div className={styles.orangeDiv}>
        <DFYClients
          dark={true}
          header={"How It Works"}
          desc={
            "We make it simple and straightforward to enhance your content strategy with our DFY services. Here’s how it works:"
          }
          data={SliderDataTwo}
          sliderBottomText={"Our Benefits"}
          lightButton={true}
        />
      </div>

      <Container>
        <div className={styles.pricingFpp}>
          <h2>Flexible Pricing Plans</h2>
          <p>
            We offer a range of pricing plans to suit different business sizes
            and needs. Choose the plan that best fits your requirements:
          </p>

          <div className={styles.cardsCont}>
            <div className={styles.card}>
              <img
                className={styles.logoImage}
                src={"/images/fpp_1.png"}
                alt={"logo"}
              />
              <h3>Blog Post Content</h3>
              <p>Delivery 3 To 5 Days</p>
              <h2>$125</h2>
              <hr />
              <ul>
                <li>1000 To 1500 Words</li>
                <li>SEO Optimized</li>
                <li>Intent Driven</li>
                <li>Relevant Media</li>
                <li>Goal Oriented</li>
              </ul>
              <Link to={"https://rankjacker.spp.io/order/QL3JO6"}>
                <div className={styles.outline}>Order now</div>
              </Link>
            </div>
            <div className={styles.card}>
              <img
                className={styles.logoImage}
                src={"/images/fpp2.png"}
                alt={"logo"}
              />
              <h3>Landing Page Content</h3>
              <p>Delivery 5 To 7 Days</p>
              <h2>$249</h2>
              <hr />
              <ul>
                <li>Copywriting</li>
                <li>Highly Engaging</li>
                <li>Goal Driven</li>
                <li>Skilled Writers</li>
                <li>Upto 3 Revisions</li>
              </ul>
              <Link to={"https://rankjacker.spp.io/order/QL3JO6"}>
                <div className={styles.outline}>Order now</div>
              </Link>
            </div>
            <div className={`${styles.card} ${styles.diff}`}>
              <img
                className={styles.logoImage}
                src={"/images/fpp_1.png"}
                alt={"logo"}
              />
              <h3>Blog content with content Strategy</h3>
              <p>
                Consult with our expert for custom blog content strategy plan
              </p>
              {!isMobile && <hr />}
              <Link
                className={styles.mainBtn}
                to={"https://calendly.com/bookadiscoverycall/15"}
              >
                <PrimaryButton buttonText={"Book a call now"} />
              </Link>
            </div>
          </div>
        </div>
      </Container>
      <HomeFaqs data={FaqHome} />
      <Footer />
    </>
  );
};

export default ContentStrategy;
