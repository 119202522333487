import CaseInternalHero from "../../../components/CaseInternal/Hero";
import Footer from "../../../components/Footer";
import Container from "../../../components/Shared/Container";
import styles from "./styles.module.scss";


function DentalLinkBuilding() {
    return (
        <>
            <CaseInternalHero header={'WEBSITE JUMPS FROM ZERO TO 17,304 VISITORS PER/MONTH WITH BACKLINKS!'} subHeader={'DENTAL LINK BUILDING'} />
            <Container>
                <div className={styles.requirementWrapper}>

                    <div className={styles.right}>
                        <h2>
                            ABOUT OUR CLIENT
                        </h2>
                        <p>
                            We did an email outreach to initiate communication with a Dentist client. Here’s a snapshot of the issues, they have been struggling with:
                            <br /><br />
                            1. A New Website with no Organic Presence
                            <br /><br />
                            2. Previous Bad Experience with Paid Marketing
                            <br /><br />
                            3. Lack of understanding of the Marketing Process
                            <br /><br />
                            4. Budgetary limitations
                            <br /><br />
                            Now all of the above, made our JOB massively challenging to make them understand the time, effort, and money required to invest in the whole process, convince them, and set their expectations right with respect to deliverability and performance measurement.
                            <br /><br />
                            So, Here’s a quick run-through of setting it up right from the beginning
                        </p>
                        <br /><br />
                        <h2>
                            DISCOVERY STAGE
                        </h2>
                        <p>
                            Since the client already had a bad experience with paid marketing, he attended the call with a lot of baggage, the first challenge for us to overcome.
                            <br /><br />
                            We simply tried to give him ample time to listen to his grievances and address them via a carefully structured plan to make him feel in control of the entire process.
                            <br /><br />
                            A 3 Months Roadmap To Organic Performance which included a monthly step-by-step approach that is trackable & measurable.
                            <br /><br />
                            1. Fixing Up the Technical & Onpage SEO
                            <br /><br />
                            2. Defining a Clear Content Strategy
                            <br /><br />
                            3. Optimized Goal Orientation
                            <br /><br />
                            4. Targeted Off-Page SEO
                            <br /><br />
                            Each of the tasks was to be further broken down and mapped to a monthly plan for execution and corresponding reporting structure against performance measurement.
                            <br /><br />
                            To further enhance trust and to close the deal, we offered a full refund of my consultation fee in the event of non-performance.
                        </p>
                    </div>
                </div>
            </Container>

            <div className={styles.solutionWrapper}>
                <Container>
                    <div className={styles.wrapperCont}>
                        <h2>
                            How we helped them solve this issue
                        </h2>
                        <div className={styles.mainCont}>
                            <div className={styles.left}>
                                <h3>
                                    Month 1
                                </h3>
                                <p>
                                    <b>Our Plan</b>
                                    <br /><br />
                                    We literally had to start from scratch and had an uphill task to pull off from the Sandbox.
                                    <br /><br />
                                    As a first step, we tasked my team to identify and benchmark competitors and find loopholes and gaps to break into, and structure a content plan to take leverage at the earliest.

                                    <br /><br />
                                    <b>Here’s the snapshot of the first month’s tasks that we set to accomplish:</b>
                                    <br /><br />
                                    1. Identify & Benchmark Competitors.
                                    <br /><br />
                                    2. Identify current issues with the Site’s on-page and Technical SEO & prepare an outline to fix them.
                                    <br /><br />
                                    3. Plan the Content Strategy after extensive KW research.
                                    <br /><br />
                                    It took our team about 10 days to finish the detailing. We set up a call with the client to apprise him of our findings & show an execution plan for the expected outcome, which was to be discussed in the next bi-monthly meeting.
                                    <br /><br />
                                    <b>Purpose</b>
                                    <br /><br />
                                    The purpose is to get the site free of any impediments that might be detrimental to its performance in search engines as well as against the competition.
                                    <br /><br />
                                    Some of the main objectives to achieve with the above-mentioned tasks were:
                                    <br /><br />
                                    * Speed & Technical Optimisation
                                    <br /><br />
                                    * Onpage Optimisation
                                    <br /><br />
                                    * Featured Snippet Optimization
                                    <br /><br />
                                    A Well-Defined Content Strategy that aligns with the overall objective of the website.
                                    <br /><br />
                                    <b>Outcome</b>
                                    <br /><br />
                                    There are not many tangible outcomes to show at this stage since search engines take time to crawl a site and administer changes to its algorithm, but this one step is crucial in the long run to take maximum advantage of our content plan and link building.
                                    <br /><br />
                                    <b>Some of the tasks that were accomplished at this stage.</b>
                                    <br /><br />
                                    * We managed to bring the load time down to 2 seconds to make it super fast and highly accessible.
                                    <br /><br />
                                    * On-Page SEO was perfectly aligned with established goals.
                                    <br /><br />
                                    * Continuous blog content flows out for the next 3 months.
                                </p>
                            </div>

                            <div className={styles.right}>
                                <img src={"https://www.rank-jacker.com/wp-content/uploads/2023/04/Month-1.png"} alt={"logo"} />
                                <img src={"https://www.rank-jacker.com/wp-content/uploads/2023/04/Month-2.png"} alt={"logo"} />
                            </div>
                        </div>

                        <div className={`${styles.mainCont} ${styles.mainContTwo}`}>
                            <div className={styles.left}>
                                <h3>
                                    Month 2
                                </h3>
                                <p>
                                    <b>Plan – Setting A Strong Foundation</b>
                                    <br /><br />
                                    We didn’t do any link building during the first month since the site didn’t have any previous history of backlinks and building them outright would have alerted the algorithm to kick in.
                                    <br /><br />
                                    Now that we’ve done some basic work, the next step is to build some foundation for our power link building to work in our favor.
                                    <br /><br />
                                    <b>Our Purpose</b>
                                    <br /><br />
                                    We formulated a detailed plan of foundation link building to following over the next few months to aid link building and make it look natural & authentic.
                                    <br /><br />
                                    Some of the other outcomes to achieve with Foundation Links were:
                                    <br /><br />
                                    * Warming Up for Link building
                                    <br /><br />
                                    * Building some credibility & trustworthiness
                                    <br /><br />
                                    * Anchor Diversification
                                    <br /><br />
                                    <b>Outcome</b>
                                    <br /><br />
                                    Some of the achievements at the end of 2nd month that we were able to pull off on almost on automation:
                                    <br /><br />
                                    1. Continuous increase in the Clicks & Impressions on Search Console
                                    <br /><br />
                                    2. Organic Rankings Improvement in the KW Tracker
                                    <br /><br />
                                    3. Organic Traffic Recorded in Analytics
                                </p>
                            </div>

                            <div className={styles.right}>
                                <img src={"https://www.rank-jacker.com/wp-content/uploads/2023/04/Month-3.png"} alt={"logo"} />
                            </div>
                        </div>

                        <div className={styles.mainCont}>
                            <div className={styles.left}>
                                <h3>
                                    Month 3
                                </h3>
                                <p>
                                    <b>Our Plan</b>
                                    <br /><br />
                                    Now that the site has started getting some traction from the search engines, the time is ripe to aid organic growth with Power link building.
                                    <br /><br />
                                    An elaborate plan for building links in troves was formulated to make it appear random & natural to the algorithms.
                                    <br /><br />
                                    Here’s the sequence in which links were planned to break into top organic rankings.
                                    <br /><br />
                                    * Building Links with Branded Anchors
                                    <br /><br />
                                    * Links with KW in Close proximity
                                    <br /><br />
                                    * Links with Synonyms Anchors
                                    <br /><br />
                                    * Exact match Anchors
                                    <br /><br />
                                    <b>Our Purpose</b>
                                    <br /><br />
                                    The idea was to earn link credibility as the KW rankings grew and keep them clean and natural. This strategy of building links is timeless as it follows the basic principles of marketing and has always worked without fail.
                                    <br /><br />
                                    <b>Results Achieved</b>
                                    <br /><br />
                                    The following screenshot shows the results that we were able to achieve with a consistent optimization and link-building plan.
                                    <br /><br />
                                    The growth in organic traffic and rankings was gradual initially but then exploded beyond expectation once the site gained prominence against the competition in SERPs.
                                </p>
                            </div>

                            <div className={styles.right}>
                                <img src={"https://www.rank-jacker.com/wp-content/uploads/2023/04/Month-4.png"} alt={"logo"} />
                            </div>
                        </div>




                    </div>
                </Container>
            </div>

            <Container>
                <div className={styles.impactWrapperCont}>
                    <h2>
                        Month 4 Onwards
                    </h2>
                    <div className={styles.mainCont}>
                        <div className={styles.left}>
                            <p>
                                The site is constantly growing in terms of Organic rankings and traffic, and our role has been limited to identifying opportunities for organic rankings, optimizing the content to match the intent, and aiding the rank growth in terms of Link Building.
                                <br /><br />
                                With extensive research to outdo the competition for targeted queries and consistent link building, it was like a cakewalk to growing over time.
                                <br /><br />
                                The below screenshots show the latest results that the client is enjoying and literally operating from a position of strength.
                            </p>

                            <img src='https://www.rank-jacker.com/wp-content/uploads/2023/04/Month-5.png' alt='result' />

                            <p>
                                P.S. The client is still active with us and has set his eyes on newer sub-niches to expand his service portfolio within dentistry. The scope never ends, and so does the client’s appetite to explore more potential.
                                <br /><br />
                                <b>Looking for a custom plan?</b>
                                <br /><br />
                                <b>Schedule a call with our co-founder now!</b> <a href="https://calendly.com/bookadiscoverycall/15">Check Availability</a>
                            </p>
                        </div>
                    </div>
                </div>
            </Container>

            <Container>
                <div className={styles.collabBanner}>
                    <img src={"/images/ic_rocket.svg"}
                        alt={"rocket"}
                        className={styles.rocket} />
                    <img src={"/images/i_collaborate_pattern.svg"}
                        alt={"pattern"}
                        className={styles.pattern} />
                    <div>
                        <h2>
                            Have a project in mind?
                        </h2>
                        <h2>
                            Let’s collaborate
                        </h2>
                    </div>

                    <div className={styles.button}>
                        <p>
                            Get Started Today
                        </p>
                        <img src={"/images/ic_arrow_dark.svg"}
                            alt={"arrow"}
                            className={styles.arrow} />
                    </div>

                </div>
            </Container>
            <Footer />
        </>
    );
}

export default DentalLinkBuilding;
