import DIYHero from "../../../components/DIY/Hero";
import DIYDetails from "../../../components/DIY/Details";
import DIYTestimonials from "../../../components/DIY/Testimonials";
import HomeFaqs from "../../../components/Home/Faqs";
import Footer from "../../../components/Footer";
import Details from "../../../components/DFY/Details";
import styles from "../styles.module.scss";
import Container from "../../../components/Shared/Container";
import PrimaryButton from "../../../components/Shared/Buttons/Primary";
import SwitchButton from "../../../components/Shared/Buttons/Switch";
import { useMediaQuery } from "react-responsive";
import SureSection from "../../../components/ContentStrategy/SureSection";
import DFYClients from "../../../components/DFY/Clients";
import { Link, useNavigate } from "react-router-dom";
import DIYClients from "../../../components/DIY/Clients";
import SureSectionTwo from "../../../components/DIY/SureTwo";
import {
  TestimonialsData,
  TestimonialsDataLinks,
} from "../../../cont/ClientData";
import PricingOptions from "../../../components/DIY/PricingOptions";
import DIYBacklinkPackages from "../../../components/DIY/BacklinkPackages";

const logoList = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-5.png",
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-5.png",
];

const logoListMobile = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
];

const Pricing = [
  {
    price: "$15",
    unit: "Link",
    desc: "Budget PBN links",
    list: [
      "DA 30+ (Domain Authority)",
      "From Multi Niche Sites",
      "Do-Follow Link",
      "Unique Content",
      "Hosted In Unique IP’s",
      "Zero Footprints",
      "Permanent Post",
      "100% Safe",
    ],
  },
  {
    price: "$20",
    unit: "Link",
    desc: "Budget PBN links",
    list: [
      "High DA 50+ (Domain Authority)",
      "From Multi Niche Sites",
      "Do-Follow Link",
      "Unique Content",
      "Hosted In Unique IP’s",
      "Zero Footprints",
      "Permanent Post",
      "100% Safe",
    ],
  },
  {
    price: "$25",
    unit: "Link",
    desc: "Budget PBN links",
    list: [
      "High DA 50+ (Domain Authority)",
      "From Multi Niche Sites",
      "Do-Follow Link",
      "Unique Content",
      "Hosted In Unique IP’s",
      "Zero Footprints",
      "Permanent Post",
      "100% Safe",
    ],
  },
];

const FaqData = {
  Generals: [
    {
      question: "Why should we outsource our SEO projects?",
      answer:
        "SEO best practices are continually being modified to resonate with the dynamic search engine algorithms; this makes it hard for you and your team to get results as you need to keep on coming up with the new modifications and techniques. Therefore outsourcing your work to an experienced team who is solely dedicated to SEO not only makes sense but also ensures that you have peace of mind. You can expect better results, and on-time delivery with a 100% confidentiality check, what else do you need, remains the last question?",
    },
    {
      question: "How can we get a site to the first page of Google?",
      answer:
        "First, we need to understand how does Google decide which pages rank on the first page of Google, Google being a search engine aims to provide the most relevant results to search queries, and therefore places those sites on its first page which are most relevant and authoritative matching the search query. Therefore in simple words, you would need to understand things like the search queries, keywords, competition, site authenticity, site authority, search engine-friendly practices, and much more, to be able to rank on the first page of Google. All these practices in a nutshell are known as SEO.",
    },
    {
      question: "How long does it take to start seeing results?",
      answer:
        "You might see some gains from the very first week of the campaign but there is no way to pinpoint an exact date.SEO is an ongoing process and results vary enormously from one business to another. There are some SEO service providers who promise to show results in a short span of time, but those results can have a negative long-term impact on your site’s performance and ranking. SEO results are dependent on many factors like your present site’s health, complexity, popularity, competition, etc.",
    },
    {
      question: "Can you guarantee results?",
      answer: "We guarantee results for every service we provide",
    },
    {
      question: "How do I know that I will get a return on my investment?",
      answer:
        "Because we put our money where our mouth is. Our guarantee is that if you do not like the results you get contact us within 7 days of receiving your report and we ensure to make it right if not we refund back your money.",
    },
    {
      question: "Is there any monthly contract needed?",
      answer:
        "No, absolutely not. We earn our next month with the results we bring in for you.",
    },
  ],
};

const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's buy backlinks service has truly transformed our website's visibility. Their strategic approach and high-quality backlinks have significantly boosted our search engine rankings, resulting in increased organic traffic and leads.",
    h3: "Samantha Johnson",
    designation: "CEO",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We were skeptical at first, but Rank-Jacker SEO proved us wrong. Their buy backlinks service delivered impressive results beyond our expectations. Our website now ranks higher for competitive keywords, driving more targeted traffic and conversions",
    h3: "Mark Smith",
    designation: "Founder",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's buy backlinks service is worth every penny. They provided us with relevant, authoritative backlinks that improved our website's credibility and search engine ranking. Highly recommend their services to anyone looking to enhance their online presence.",
    h3: "Amanda White",
    designation: "Marketing Manager",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Thanks to Rank-Jacker SEO, our website has experienced a significant increase in organic traffic and leads. Their buy backlinks service is top-notch, delivering tangible results and helping us stay ahead of the competition. Excellent work!",
    h3: "David Thompson",
    designation: "Director of Marketing",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's buy backlinks service has been instrumental in our SEO strategy. The quality of the backlinks they provided helped us establish authority in our niche and achieve higher rankings on search engines. We couldn't be happier with the results",
    h3: "Rachel Carter",
    designation: "E-commerce Manager",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We're extremely impressed with the results from Rank-Jacker SEO's buy backlinks service. Their team went above and beyond to understand our needs and deliver targeted backlinks that significantly improved our website's visibility and traffic. Highly recommended!",
    h3: "Michael Brown",
    designation: "Head of SEO Agency",
    personSrc: "/images/i_client_one.png",
  },
];

const BuyBackLinks = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();
  return (
    <>
      <DIYHero
        bgImage={"/images/foundation-link-bg.png"}
        title={"BUY HIGH DA BACKLINKS PACKAGES"}
        subtitle={
          "When You Buy Links From us, you can be rest assured that they not only stand strong on Promised Metrics but are also Topically Relevant with a Contextual Reference & an Effective CTA. So that they Transpire in Real Results. Watch the video below to Hear Out Our Take On This!!"
        }
        cta2Link={"https://calendly.com/bookadiscoverycall/15"}
      />
      <div className={styles.wrapper}>
        <div className={styles.bgContainer} />
        <div className={styles.bgContainerBottom} />

        <div className={styles.mainContent}>
          <Container>
            <div className={styles.partners}>
              {!isMobile
                ? logoList.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })
                : logoListMobile.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })}
            </div>

            <div className={styles.appointment}>
              <div className={styles.left}>
                <div className={styles.topText}>DIY WITH 24X7 SUPPORT</div>
                <div className={styles.header}>
                  If you are one of those who knows exactly what to do in SEO
                  and just falling short of time, then we got you. Choose what
                  best suits your Off-page needs and be in complete control,
                  with our 360° Backlink Offerings and 24X7 support.
                </div>
                <div className={styles.buttonCont}>
                  <Link to={"https://calendly.com/bookadiscoverycall/15"}>
                    <PrimaryButton buttonText={"Book an appointment"} />
                  </Link>
                </div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.logoImage}
                  src={"/images/ic_appointment.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </Container>

          <SureSectionTwo />

          {/*Dominate*/}
          {/* <div className={styles.dominate}>
            <Container>
              <div className={styles.headerSection}>
                <h1>Why you should work with us</h1>
              </div>

              <div className={styles.cardsSection}>
                {DominateData.map((item, index) => {
                  return (
                    <div className={styles.cards} key={index}>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt={"logo"}
                      />

                      <h2>{item.header}</h2>
                      {!isMobile && <p>{item.desc}</p>}
                    </div>
                  );
                })}
              </div>
            </Container>
          </div> */}
          <DIYBacklinkPackages />
          <DFYClients
            dark={true}
            header={"Working with Precision in Process, Excellence in Results"}
            desc={
              "Our impeccable back linking strategies give you access to robust data solutions- easy to implement and use. Here’s how we work."
            }
          />
        </div>
      </div>
      <DIYTestimonials data={Testimonial} />
      <DIYClients data={TestimonialsDataLinks} verticalSpace />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};
export default BuyBackLinks;
