import PrimaryButton from "../../Shared/Buttons/Primary";
import Container from "../../Shared/Container";
import InputTextButton from "../../Shared/Input/Button";
import styles from "./styles.module.scss";
import { useNavigate } from 'react-router-dom';

const LocalSEOHero = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            <img className={styles.leftHand} src={'/images/i_local_seo_hand_one.png'} alt={'banner'} />
            <img className={styles.rightHand} src={'/images/i_local_seo_hand_two.png'} alt={'banner'} />
            <Container>
                <div className={styles.contentWrapper}>
                    <h1 className={styles.top}>
                        Expert Local SEO Services to
                    </h1>
                    <h1> Boost Your Business Visibility
                    </h1>
                    {/* <h1 className={styles.highlighted}>
                        Service Provider
                    </h1> */}
                    <p>
                        Our Local SEO services specializes in formulating customized strategies to elevate your local market dominance. Our expertise lies in initiating Hyper-Local Citations, ensuring your business stands out in specific localities. Let's begin on this journey with Rank Jacker and witness your business rise to new heights.
                    </p>

                    <PrimaryButton buttonText={"Contact us"} onClick={() => navigate("/contact-us")} />
                </div>
            </Container>
        </div>
    )
};

export default LocalSEOHero;