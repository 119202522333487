import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from 'react-router-dom';

function DIYNetwork({ bgImage,title,subtitle }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.outlineButton}>
          450 Strong Syndication Network
        </div>

        <img
            className={styles.mainImage}
            src={"/images/press.png"}
            alt={"carousel item"}
          />

          <h2>
          LETS YOU REACH OUT TO MILLIONS WITH PRESS RELEASE SERVICES
          </h2>
        
          <p>
          Each of the sites on our Network, receives page views in millions, every single day and guarantees a huge readership. Get your business mentioned on sites like Fox News, Digital Journal, etc that can get a huge traffic inflow, increased sales potential, and legitimate Branding of your Business.
          </p>
          <br/>
          <p>
          Your marketing plan gets an effective boost with a targeted Niche PR syndication.
          </p>
      </div>
    </Container>
  );
}

export default DIYNetwork;
