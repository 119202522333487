import CaseInternalHero from "../../../components/CaseInternal/Hero";
import Footer from "../../../components/Footer";
import Container from "../../../components/Shared/Container";
import styles from "./styles.module.scss";


function EComSEO() {
    return (
        <>
            <CaseInternalHero header={'AMYDUS'} subHeader={'PLUS SIZE CLOTHING'} />
            <Container>
                <div className={styles.requirementWrapper}>

                    <div className={styles.right}>
                        <h2>
                            Challenge:
                        </h2>
                        <p>
                            Amydus is a Women Plus Size E-commerce store, that wanted to increase organic brand discovery & organic traffic growth. They needed help figuring out a solution to drive website visitors & purchases consistently and profitably. They also needed help optimizing search positioning in order to increase horizontal growth in terms of different search queries & collections that had on their store.
                        </p>
                        <br /><br />
                        <h2>
                            Solution:
                        </h2>
                        <p>
                            Amydus was experiencing flattened organic traffic trends for a long time and 81% of their organic visitors were landing on wrong collections resulting in lower engagement sessions and poor conversion prior to joining RankJacker SEO.
                            <br /><br />
                            We needed to figure out a way to identify & straighten out reasons that lead to poor search engine exposure as well as increase organic exposure & traffic within a limited budget. Since the client was reluctant to lend an open hand due to past experiences with other SEOs.
                            <br /><br />
                            We focused on the technical aspect first to draw out an extensive plan for Website Architecture Optimization & a highly targeted content strategy to build up their success story.
                        </p>
                    </div>
                </div>
            </Container>

            <div className={styles.solutionWrapper}>
                <Container>
                    <div className={styles.wrapperCont}>
                        <h2>
                            How we helped them solve this issue
                        </h2>
                        <div className={styles.mainCont}>
                            <div className={styles.left}>
                                <h3>
                                    Website Architecture Optimization
                                </h3>
                                <p>
                                    Prior SEO efforts for Amydus were unsuccessful since the past team tried to only rely on KW research & build out the content for every KW (with considerable volume) that they came across.
                                    <br /><br />
                                    They fail to understand the niche, competition, and challenges that Amydus faced in Organic SERPs. We’re quick to understand that there were a lot of cannibalization issues that prevented Search Engines from convincingly ranking the website. Our first challenge was to draw a website sitemap that was free from any such issues and focused on ranking the collections pages for long-term benefits.
                                    <br /><br />
                                    Since the site is only focusing on Plus Size Women, every collection on the website had plus size, & women attached to it. So, naturally, it took a great amount of effort to come up with KW combinations that were distinctly unique in spite of having plus, size & women in the URL. We were able to limit the no. of collections & sub-collections to 58 from 180. Here’s a screenshot from the
                                </p>
                            </div>

                            <div className={styles.right}>
                                <img src={"https://www.rank-jacker.com/wp-content/uploads/2023/06/URL-Indexing.jpg"} alt={"logo"} />
                            </div>
                        </div>

                        <div className={`${styles.mainCont} ${styles.mainContTwo}`}>
                            <div className={styles.left}>
                                <h3>
                                    Custom URL Structuring – The Biggest Challenge
                                </h3>
                                <p>
                                    The site was set up on Shopify which doesn’t allow you to create custom URL structures, but our team came up with a very unique and creative way of customizing the permalinks into categories & subcategories without altering the Shopify coding. It’s a very complex way but an effective one that resulted in a huge success for Amydus. I’d write a detailed blog on this sometime in the future.
                                </p>
                            </div>

                            <div className={styles.right}>
                                <img src={"https://www.rank-jacker.com/wp-content/uploads/2023/05/image_2023_05_31T11_59_17_115Z.png"} alt={"logo"} />
                            </div>
                        </div>

                        <div className={styles.mainCont}>
                            <div className={styles.left}>
                                <h3>
                                    Technical & On-page Optimization
                                </h3>
                                <p>
                                    After fixing the Website Sitemap, we optimized the other Technical SEO stuff and page SEO of the website which included Meta, Heading Tags, Alt tags & Structured Data.
                                    <br /><br />
                                    We also kept track of the Core Webs Vitals to make sure, there’s no SEO impediment left in the website’s Crawlability, Indexing & Rendering by the Search Engine and the website is ready to compete in the SERPs.
                                </p>
                            </div>

                            <div className={styles.right}>
                                <img src={"https://www.rank-jacker.com/wp-content/uploads/2023/06/Tech-On-Page.jpg"} alt={"logo"} />
                            </div>
                        </div>

                        <div className={`${styles.mainCont} ${styles.mainContTwo}`}>
                            <div className={styles.left}>
                                <h3>
                                    Targeted Content Strategy
                                </h3>
                                <p>
                                    We devised a highly targeted content strategy aligned with the ideal customer profile. The idea was to get a strong Search Engine Positioning and catch the attention of the ICP from every possible angle leading them to the requisite Collection Page.
                                    <br /><br />
                                    Using a data-based approach, we were able to create unique & creative angles to arrest the attention of ideal prospects resulting in increasing organic traffic from the blog. Amydus is generating 15K+ New Organic Visitors every month attributable to Collection Pages and Blog pages every month and is expected to grow further in the future.
                                </p>
                            </div>

                            <div className={styles.right}>
                                <img src={"https://www.rank-jacker.com/wp-content/uploads/2023/06/content-topics.jpg"} alt={"logo"} />
                            </div>
                        </div>

                        <div className={styles.mainCont}>
                            <div className={styles.left}>
                                <h3>
                                    Effective Link Building
                                </h3>
                                <p>
                                    Link Building being an important aspect of SEO, our agency left no stone unturned to create an effective link-building strategy that is practical, scalable & sustainable.
                                    <br /><br />
                                    Instead of building links in the air, we followed a tactical approach to identify key targets to push rankings and authority for incremental improvements.
                                    <br /><br />
                                    Every month, our link-building experts would prepare a detailed plan that included KW & URL Targets, SERP Positions, expected measurable outcomes, and the list of Websites suggested for link placement.
                                </p>
                            </div>

                            <div className={styles.right}>
                                <img src={"https://www.rank-jacker.com/wp-content/uploads/2023/06/Link-Building.jpg"} alt={"logo"} />
                            </div>
                        </div>


                    </div>
                </Container>
            </div>

            <Container>
                <div className={styles.impactWrapperCont}>
                    <h2>
                        The impact it caused
                    </h2>
                    <div className={styles.mainCont}>
                        <div className={styles.left}>
                            <p>
                            The following images show the continuous organic traffic improvements compared to the last 6 Months & 1 Year of Data.
                            </p>

                            <img src='https://www.rank-jacker.com/wp-content/uploads/2023/06/Console-.jpg' alt='result'/>
                        </div>
                    </div>
                </div>
            </Container>
            
            <Container>
                <div className={styles.collabBanner}>
                    <img src={"/images/ic_rocket.svg"}
                        alt={"rocket"}
                        className={styles.rocket} />
                    <img src={"/images/i_collaborate_pattern.svg"}
                        alt={"pattern"}
                        className={styles.pattern} />
                    <div>
                        <h2>
                            Have a project in mind?
                        </h2>
                        <h2>
                            Let’s collaborate
                        </h2>
                    </div>

                    <div className={styles.button}>
                        <p>
                            Get Started Today
                        </p>
                        <img src={"/images/ic_arrow_dark.svg"}
                            alt={"arrow"}
                            className={styles.arrow} />
                    </div>

                </div>
            </Container>
            <Footer />
        </>
    );
}

export default EComSEO;
