import React from "react";
import styles from "./styles.module.scss";
import { useMediaQuery } from 'react-responsive';

const InputTextButton = ({ placeholder, buttonText, buttonTextSmall }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 576px)'
});
  return <div className={styles.inputWrapper}>
    <input name="myInput" placeholder={placeholder} className={styles.inputField} />
    <div className={styles.button}>
        {isMobile && buttonTextSmall ? buttonTextSmall : buttonText}
    </div>
  </div>
};

export default InputTextButton;