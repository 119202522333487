import React from "react";
import styles from "./styles.module.scss";

const InputText = ({
  placeholder,
  borderLess = true,
  autoWidth = false,
  value,
  onChange,
  name,
}) => {
  return (
    <div className={styles.inputWrapper}>
      <input
        value={value}
        name={name}
        placeholder={placeholder}
        className={`${!borderLess && styles.bordered} ${autoWidth && styles.autoWidth}`}
        onChange={onChange}
      />
    </div>
  );
};

export default InputText;
