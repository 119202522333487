export const BlogList = [
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
];

export const BlogListThree = [
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
];

export const BlogListFour = [
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
];

export const BlogListSecond = [
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
  {
    src: "/images/i_blogs_main.png",
    header: "Blog 1 Title for reference",
    author: "Rohit kadam",
    date: "September 23 &sdot; 3 mins",
    type: "DIY service . Service . Industry",
  },
];
