import React from "react";
import styles from "./styles.module.scss";

const DIYClients = ({ data, smallText = false, verticalSpace = false }) => {
  return (
    <div className={`${verticalSpace && styles.spacing}`}>
      <h1>What our client say about us</h1>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <img
            className={styles.person}
            src={
              data[0].personSrc ? data[0].personSrc : "/images/diy_cl_one.png"
            }
            alt={"carousel item"}
          />
          <div className={`${styles.texts} ${smallText && styles.smallText}`}>
            {data[0].p}
            <br />
            <br />
            {data[0].h3}, {data[0].designation}
          </div>
        </div>
        <div className={styles.main}>
          <img
            className={styles.person}
            src={
              data[1].personSrc ? data[1].personSrc : "/images/diy_cl_two.png"
            }
            alt={"carousel item"}
          />
          <div className={`${styles.texts} ${smallText && styles.smallText}`}>
            {data[1].p}
            <br />
            <br />
            {data[1].h3}, {data[1].designation}
          </div>
        </div>
        <div className={styles.main}>
          <div className={`${styles.texts} ${smallText && styles.smallText}`}>
            {data[2].p}
            <br />
            <br />
            {data[2].h3}, {data[2].designation}
          </div>
          <img
            className={styles.person}
            src={
              data[2].personSrc ? data[2].personSrc : "/images/diy_cl_three.png"
            }
            alt={"carousel item"}
          />
        </div>
        <div className={styles.main}>
          <div className={`${styles.texts} ${smallText && styles.smallText}`}>
            {data[3].p}
            <br />
            <br />
            {data[3].h3}, {data[3].designation}
          </div>
          <img
            className={styles.person}
            src={
              data[3].personSrc ? data[3].personSrc : "/images/diy_cl_four.png"
            }
            alt={"carousel item"}
          />
        </div>
      </div>
    </div>
  );
};

export default DIYClients;
