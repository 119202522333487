import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import React from "react";
const CitationAudit = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.content}>
          <div className={styles.cta}>Here’s the process to prominence</div>
          <img
            src={"/images/citation_audit.svg"}
            alt={"Citation Audit"}
            width={80}
            height={80}
          />
            <h5 className={styles.heading}>
                CITATION AUDIT
            </h5>
            <p className={styles.desc}>
                A full-scale audit is the fulcrum of any Citation campaign that we undertake to ensure consistency, uniqueness, and accuracy of your business data.
                A detailed bifurcated report of all your business citations is set for the course correction. Mostly omitted by the cheap citation services, it’s like building over a faulty foundation.
            </p>
        </div>
      </Container>
    </div>
  );
};
export default CitationAudit;
