import styles from "./styles.module.scss";
import React, { useState } from "react";

const SwitchButton = ({ buttonTexts, onClick, indexClick = false }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        if(!indexClick) {
            onClick(buttonTexts[index]);
        } else {
            onClick(index);
        }
        setActiveIndex(index);
    };

    return (
        <div className={styles.wrapper}>
            {buttonTexts.map((text, index) => (
                <div
                    key={index}
                    className={`${styles.active} ${
                        activeIndex !== index && styles.inactive
                    }`}
                    onClick={() => handleClick(index)}
                >
                    {text}
                </div>
            ))}
        </div>
    );
};


export default SwitchButton;