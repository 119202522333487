import PrimaryButton from "../../Shared/Buttons/Primary";
import SwitchButton from "../../Shared/Buttons/Switch";
import Container from "../../Shared/Container";
import InputTextButton from "../../Shared/Input/Button";
import styles from "./styles.module.scss";
const Data = ['/images/ic_databox.svg', '/images/ic_business_insider.svg', '/images/ic_legalzoom.svg', '/images/ic_partner_one.svg', '/images/ic_segate.svg','/images/ic_databox.svg',];

const LocalService = [
    {
        h1: 'Local SEO Audits',
        p: 'We help you bring your local business at front with our comprehensive local SEO audit to understand your existing local SEO rating and create your local SEO checklist. ',
        icon: '/images/ic_local_service_1.svg'
    },
    {
        h1: 'Google Map pack',
        p: 'Our Google Map Pack is a powerful feature for local businesses seeking to improve their online presence and attract more local customers. Its integration with Google Maps plays a critical role in driving traffic to differentlocations.',
        icon: '/images/ic_local_service_2.svg'
    },
    {
        h1: 'Business page',
        p: "We optimize your Google My Business profile to ensure it's not only complete but also compelling. This includes accurate business information.",
        icon: '/images/ic_local_service_3.svg'
    },
    {
        h1: 'Online review sites',
        p: 'Our experts monitor the online review sites looking into the local SEO algorithm with our expertise! Increase your quality reviews today.',
        icon: '/images/ic_local_service_4.svg'
    }
];

const Requirements = [
    {
        icon: '/images/ic_niche_col.svg',
        h1: 'Niche Edits',
        p: `Our link building agency creates original, valuable content for every link we place. This is the best way to build links because it meets Google's guidelines.`
    },
    {
        icon: '/images/ic_pbn_col.svg',
        h1: 'PBN Links',
        p: 'We build valuable links with contextual anchor text. Our custom link building services are tailored to your keyword goals.'
    },
    {
        icon: '/images/ic_guest_col.svg',
        h1: 'Guest Post',
        p: 'We build backlinks through manual outreach. Your links will come from relevant sites in your industry that have strong search engine metrics.'
    }
]

const LocalSEOWhy = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.bgColor} />
            <div className={styles.bgColorOne} />
            {/* <img className={styles.leftHand} src={'/images/i_local_seo_hand_one.png'} alt={'banner'} /> */}
            <Container>
                <div className={styles.contentWrapper}>
                    <div className={styles.partners}>
                        {Data.map((item, index) => {
                            return (
                                <img className={styles.logoImage} src={item} alt={'logo'} />
                            )
                        })}
                    </div>

                    <div className={styles.services}>
                        <h1>
                            Why Do You Need Our Local SEO Services?
                        </h1>

                        <div className={styles.cardsContainer}>
                            {LocalService.map((item, index) => {
                                return (
                                    <div className={styles.cards}>
                                        <h1>
                                            {item.h1}
                                        </h1>
                                        <p>
                                            {item.p}
                                        </p>
                                        <img className={styles.icon} src={item.icon} alt={'banner'} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className={styles.requirement}>
                        <h1>
                            Choose as per your requirement
                        </h1>

                        <div className={styles.buttonContainer}>
                            <SwitchButton 
                                buttonTexts={['DA authority', 'Foundational links', 'Local SEO links']}
                                onClick={() => false} 
                            />
                        </div>

                        <p>
                        A powerful and evergreen method for attracting relevance, trust signals, and anchor diversification, the foundation links are most crucial at the start of any SEO project, to gain the initial momentum for link building & brand positioning.
                        </p>

                        <div className={styles.cardsContainer}>
                        {Requirements.map((item, index) => {
                                return (
                                    <div className={styles.cards}>

                                        <img className={styles.icon} src={item.icon} alt={'banner'} />
                                        <h1>
                                            {item.h1}
                                        </h1>
                                        <p>
                                            {item.p}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
};

export default LocalSEOWhy;