import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

import styles from "./styles.module.scss";
import Container from "../Container";
import InputText from "../Input";
import PrimaryButton from "../Buttons/Primary";
import { Link } from "react-router-dom";

const ContactUs = () => {
  // Validation schema
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    companyName: yup.string().required("Company name is required"),
    phone: yup.string().required("Phone number is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    website: yup.string(),
    serviceInterest: yup.string(),
    additionalDetails: yup.string(),
  });

  // Formik hook
  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      phone: "",
      email: "",
      website: "",
      serviceInterest: "",
      additionalDetails: "",
    },
    validationSchema,
    onSubmit: (values) => {
      //
      // let data = JSON.stringify({
      //   ...values,
      // });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://www.zohoapis.in/crm/v2/functions/contactsapi/actions/execute?auth_type=apikey&zapikey=1003.175ccd610c671b8d456b3998abbde1c3.9ab3b30d7072e221ad249862da50bd9c",
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.headerSection}>
          <h1>Get in touch</h1>
          <p>
            Have an idea? Fill the details in the below form and we’ll get in
            touch with you at the earliest
          </p>
        </div>

        {/*<form onSubmit={formik.handleSubmit} className={styles.formContainer}>*/}
        {/*  <div className={styles.rows}>*/}
        {/*    <div className={styles.inputs}>*/}
        {/*      <p>Name</p>*/}
        {/*      <InputText*/}
        {/*        name="name"*/}
        {/*        onChange={formik.handleChange}*/}
        {/*        value={formik.values.name}*/}
        {/*        borderLess={false}*/}
        {/*      />*/}
        {/*      {formik.touched.name && formik.errors.name ? (*/}
        {/*        <div>{formik.errors.name}</div>*/}
        {/*      ) : null}*/}
        {/*    </div>*/}
        {/*    <div className={styles.inputs}>*/}
        {/*      <p>Company name</p>*/}
        {/*      <InputText*/}
        {/*        name="companyName"*/}
        {/*        onChange={formik.handleChange}*/}
        {/*        value={formik.values.companyName}*/}
        {/*        borderLess={false}*/}
        {/*      />*/}
        {/*      {formik.touched.companyName && formik.errors.companyName ? (*/}
        {/*        <div>{formik.errors.companyName}</div>*/}
        {/*      ) : null}*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className={styles.rows}>*/}
        {/*    <div className={styles.inputs}>*/}
        {/*      <p>Phone no.</p>*/}
        {/*      <InputText*/}
        {/*        name="phone"*/}
        {/*        onChange={formik.handleChange}*/}
        {/*        value={formik.values.phone}*/}
        {/*        borderLess={false}*/}
        {/*      />*/}
        {/*      {formik.touched.phone && formik.errors.phone ? (*/}
        {/*        <div>{formik.errors.phone}</div>*/}
        {/*      ) : null}*/}
        {/*    </div>*/}
        {/*    <div className={styles.inputs}>*/}
        {/*      <p>Email</p>*/}
        {/*      <InputText*/}
        {/*        name="email"*/}
        {/*        onChange={formik.handleChange}*/}
        {/*        value={formik.values.email}*/}
        {/*        borderLess={false}*/}
        {/*      />*/}
        {/*      {formik.touched.email && formik.errors.email ? (*/}
        {/*        <div>{formik.errors.email}</div>*/}
        {/*      ) : null}*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className={styles.rows}>*/}
        {/*    <div className={styles.inputs}>*/}
        {/*      <p>Your website</p>*/}
        {/*      <InputText*/}
        {/*        name="website"*/}
        {/*        onChange={formik.handleChange}*/}
        {/*        value={formik.values.website}*/}
        {/*        borderLess={false}*/}
        {/*      />*/}
        {/*      {formik.touched.website && formik.errors.website ? (*/}
        {/*        <div>{formik.errors.website}</div>*/}
        {/*      ) : null}*/}
        {/*    </div>*/}
        {/*    <div className={styles.inputs}>*/}
        {/*      <p>Service your interest in</p>*/}
        {/*      <InputText*/}
        {/*        name="serviceInterest"*/}
        {/*        onChange={formik.handleChange}*/}
        {/*        value={formik.values.serviceInterest}*/}
        {/*        borderLess={false}*/}
        {/*        placeholder={"SEO, Link building, white label"}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className={`${styles.inputs} ${styles.other}`}>*/}
        {/*    <p>Additional Details</p>*/}
        {/*    <InputText*/}
        {/*      name="additionalDetails"*/}
        {/*      onChange={formik.handleChange}*/}
        {/*      value={formik.values.additionalDetails}*/}
        {/*      borderLess={false}*/}
        {/*      autoWidth={true}*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*  <div className={styles.buttonCont}>*/}
        {/*    <PrimaryButton buttonText={"Submit now"} type="submit" />*/}
        {/*  </div>*/}
        {/*</form>*/}
        <div className={styles.frameContainer}>
          <iframe
            src="https://forms.zohopublic.in/rankmakeup/form/Test/formperma/v4C_i1403XM2vT6dBTU-mimD_UoP791S7rJ43n7R9F8"
            width={300}
            height={700}
            frameborder="0"
            className={styles.frame}
          ></iframe>
        </div>
        <div className={styles.orSection}>
          <div className={styles.divider} />
          <p>Or</p>
          <div className={styles.divider} />
        </div>

        <div className={styles.bottomText}>
          In hurry for a project,{" "}
          <Link
            to={"https://calendly.com/bookadiscoverycall/15"}
            className={styles.highlight}
          >
            Book a meeting
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;
