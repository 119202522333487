import DIYHero from "../../../components/DIY/Hero";
import DIYDetails from "../../../components/DIY/Details";
import DIYTestimonials from "../../../components/DIY/Testimonials";
import HomeFaqs from "../../../components/Home/Faqs";
import Footer from "../../../components/Footer";
import Details from "../../../components/DFY/Details";
import styles from "../styles.module.scss";
import Container from "../../../components/Shared/Container";
import PrimaryButton from "../../../components/Shared/Buttons/Primary";
import SwitchButton from "../../../components/Shared/Buttons/Switch";
import LocalSEOWhy from "../../../components/LocalSEO/Why";
import SureSection from "../../../components/ContentStrategy/SureSection";
import DFYClients from "../../../components/DFY/Clients";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import DIYClients from "../../../components/DIY/Clients";
import DIYPackages from "../../../components/DIY/Packages";
import {
  TestimonialsData,
  TestimonialsDataNiche,
} from "../../../cont/ClientData";

const logoList = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-5.png",
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-5.png",
];

const logoListMobile = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
];

const DominateData = [
  {
    icon: "/images/speaker.svg",
    header: "Niche Edits",
    desc: "Niche Edit links are back-links that are inserted into present content pieces that are niche relevant, have a decent Domain Authority, and have the exposure that you need to help you rank even faster. These links are not inserted by anything other than white hat communication. We do the heavy lifting by finding all the potential intuitive links and weaving your link and preferred anchor text into the post.",
  },
  {
    icon: "/images/blog.svg",
    header: "INSTANT AS WELL AS LONG YIELD",
    desc: "The links that we source for your site are permanent in nature so that your site reaps a quick and a long-term yield. As the sites that we place links on our real blogs, your site also gets the benefits as these blogs continue to grow over time. If for any reason, you notice the backlink missing, we’re just a call away to get it live again to get you the replacement link within 6 months of ordering.",
  },
  {
    icon: "/images/link.svg",
    header: "NICHE RELEVANCE WITH BACKLINK AUTHORITY",
    desc: "Search Engines prefer links coming to you from sites that is relevant to your niche/service or product for its algorithm to work at its best to promote your content. You enjoy complete control over choice of niche to source links from, and we try to place the links on real, seasoned articles that are closely relevant to your site’s broader niche. This is the how the backlinks should always be built – relevant and within the purview of the page being linked to.",
  },
  {
    icon: "/images/wheel.svg",
    header: "KEY DOMAIN METRICS",
    desc: "In Contrast to off-beat metrics like DA/PA, we use Referring Domains and niche relevancy as a benchmark to offer Niche Edit Links. Backlink health is yet another factor, that we consider for any site to be included on our Niche Edits Network. Our filtration process knock off an unbelievable amount of sites before we take them on our network.",
  },
];

const LocalService = [
  {
    h1: "POWERFUL LINK PROFILES",
    p: "In-content, natural links that are integrated editorially within the content flow.",
    icon: "/images/ic_local_service_1.svg",
  },
  {
    h1: "CONTEXTUAL RELEVANCE",
    p: "Battery of experienced native writers ensure your links are articulately crafted within the content to pass on natural relevance with the links.",
    icon: "/images/ic_local_service_2.svg",
  },
  {
    h1: "Business page",
    p: "No random low lying sites, all niche edits sites undergo strict filters to conform to the required benchmarks.",
    icon: "/images/ic_local_service_3.svg",
  },
  {
    h1: "Online review sites",
    p: "We maintain your entire link history with us, to make sure you never get links from the same site ever. You can also share your past link placements to be double sure.",
    icon: "/images/ic_local_service_4.svg",
  },
];

const FaqData = {
  Generals: [
    {
      question: "Are These PBNs Or Real Sites?",
      answer:
        "Absolutely Not! Niche Edit Links is another name for curated links, these are link placements specified for your niche, presented on real sites, or articles. These are highly relevant placements.",
    },
    {
      question: "Do You Have Access To Every Niche?",
      answer:
        "Almost! But we don’t deal in links that are related to real money Gambling, Adult, Pharma, or Payday Loans. Apart from that, we pretty much have everything.",
    },
    {
      question: "Are The Links Priced By RD Of The Domain Or Linking Page?",
      answer:
        "We take the help of AHREFS, price depends upon the referring domain a certain domain has. The more references, the more price.",
    },
    {
      question: "Can I See The List Of URLs To Order From?",
      answer:
        "Sorry, We can’t reveal the lists and contact to protect the interests of our clients, vendors, and ourselves. But once you order, we can share the posts beforehand just for added trust, of course, you get the live links in reporting as well.",
    },
    {
      question: "How Long Will It Take Me To Rank My Site?",
      answer:
        "We wish there was an exact answer to this but it depends on a lot of things, your on-page SEO, your past link history, competition, your niche, and many other factors that collectively contribute to the ranks, so we can’t really give a timeline.",
    },
    {
      question: "Do You Offer Any Bulk Discount For Regular Requirements?",
      answer:
        "Yes, you can contact us regarding bulk orders via email here. If your requirements are repetitive and in large quantities, we can definitely do something.",
    },
    {
      question: "Is Niche Edits Better Than A Broken Link Building Service?",
      answer:
        "Well first, let’s start by saying that the process is very similar to a broken link-building service, and the final outcome of a link mentioned looks identical. The difference with niche edits is that we are searching for relevant blog posts which means the link mentioned we can incorporate usually fits quite naturally with very little content to add. The problem with a broken link-building service is that the relevancy of the link to the content can suffer because the opportunities are fewer.",
    },
    {
      question: "How Do You Ensure Content Relevance?",
      answer:
        "First of all, we have a robust process to identify only the most relevant content on our pool of sites, that are already segregated based on niche/industry. Secondly, we have a large team of seasoned native writers who are experienced enough to craft expressions to accommodate it within the existing perspective to make it super relevant and actionable, where possible, to create click-through opportunities.",
    },
    {
      question: "Can I Place One Large Order With Multiple Clients' Domains?",
      answer:
        "Yep, we don’t mind. You pay for the number of links ordered for given packages irrespective of the client URLs. We just need your anchor text and target URLs for each link.",
    },
  ],
};

const Testimonial = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rohin and his team have done some amazing work for me. I used them to get some links for an affiliate site I was working on and they came through with some very high quality links that really moved the needle. The turnaround time is also very fast compared to other vendors.",
    h3: "Kasem Bajwa",
    designation: "Founder",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "I have purchased a few niche edits from Amit, He was very cooperative. I had some issues with one link and instantly told him the issue. He quickly gave a new link without asking any questions. Top-notch service!",
    h3: "MD Nizam Uddin",
    designation: "CEO",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "I ordered the PRO Package and they are very good links with strong metrics and good rankings/traffic so I am very happy with them.",
    h3: "Steven",
    designation: "Agency Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rankjacker is one of the best service providers here. You can't go wrong with any of his services!",
    h3: "Keith White",
    designation: "Link Outreach Manager",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Would highly recommend these guys for great service and delivering the product on time. Communication was outstanding and will use them again soon.",
    h3: "James",
    designation: "SEO Agency Manager",
    personSrc: "/images/i_client_one.png",
  },
];

const NicheEdits = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <>
      <DIYHero
        bgImage={"/images/ne-bg.png"}
        title={"BUY NICHE EDITS OR CURATED LINK PLACEMENTS"}
        subtitle={
          "Quick , Safe, and Powerful way to get Powerful, Permanent Link Insertions for Contextual & Niche-Relevant Curated Links from Real, Trusted Websites with Traffic"
        }
        cta2Link={"https://calendly.com/bookadiscoverycall/15"}
      />
      <div className={styles.wrapper}>
        <div className={styles.bgContainer} />
        <div className={styles.bgContainerBottom} />

        <div className={styles.mainContent}>
          <Container>
            <div className={styles.partners}>
              {!isMobile
                ? logoList.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })
                : logoListMobile.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })}
            </div>

            <div className={styles.appointment}>
              <div className={styles.left}>
                <div className={styles.topText}>
                  NICHE RELEVANT BACKLINKS FROM INDEXED POSTS ON HIGH AUTHORITY
                  WEBSITES
                </div>
                <div className={styles.header}>
                  Let's help you get backlinks from aged, relevant articles on
                  established niche specific websites. Leverage our trusted
                  networks for a permanent backlinks placement.{" "}
                </div>
                <div className={styles.buttonCont}>
                  <Link to={"https://calendly.com/bookadiscoverycall/15"}>
                    <PrimaryButton buttonText={"Book an appointment"} />
                  </Link>
                </div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.logoImage}
                  src={"/images/ic_appointment.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </Container>
          {/* <Details
            heading={"WHAT ARE NICHE EDIT BACKLINKS?"}
            leftText={
              "<p>Niche Edit links are back-links that are inserted into present content pieces that are niche relevant, have a decent Domain Authority, and have the exposure that you need to help you rank even faster.\n" +
              "These links are not inserted by anything other than white hat communication. We do the heavy lifting by finding all the potential intuitive links and weaving your link and preferred anchor text into the post.</p> <b>INSTANT AS WELL AS LONG YIELD</b>" +
              "<p>The links that we source for your site are permanent in nature so that your site reaps a quick and a long-term yield. As the sites that we place links on our real blogs, your site also gets the benefits as these blogs continue to grow over time.\n" +
              "If for any reason, you notice the backlink missing, we’re just a call away to get it live again to get you the replacement link within 6 months of ordering.</p>"
            }
            rightText={
              "<b>NICHE RELEVANCE WITH BACKLINK AUTHORITY</b> <p>Search Engines prefer links coming to you from sites that is relevant to your niche/service or product for its algorithm to work at its best to promote your content.\n" +
              "You enjoy complete control over choice of niche to source links from, and we try to place the links on real, seasoned articles that are closely relevant to your site’s broader niche.\n" +
              "This is the how the backlinks should always be built – relevant and within the purview of the page being linked to.</p> <b>KEY DOMAIN METRICS</b>" +
              "<p>In Contrast to off-beat metrics like DA/PA, we use Referring Domains and niche relevancy as a benchmark to offer Niche Edit Links.\n" +
              "Backlink health is yet another factor, that we consider for any site to be included on our Niche Edits Network.\n" +
              "Our filtration process knock off an unbelievable amount of sites before we take them on our network.</p>"
            }
          /> */}
          {/*Dominate*/}
          <div className={styles.dominate}>
            <Container>
              <div className={styles.headerSection}>
                <h1>WHAT ARE NICHE EDIT BACKLINKS?</h1>
              </div>

              <div className={`${styles.cardsSection}`}>
                {DominateData.map((item, index) => {
                  return (
                    <div className={styles.cards} key={index}>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt={"logo"}
                      />

                      <h2>{item.header}</h2>
                      <p>{item.desc}</p>
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>

          <div className={styles.packages}>
            <DIYPackages />
          </div>
          <div className={styles.services}>
            <Container>
              <h1>Benefits of curated backlinks</h1>

              <div className={styles.cardsContainer}>
                {LocalService.map((item, index) => {
                  return (
                    <div className={styles.cards}>
                      <h1>{item.h1}</h1>
                      <p>{item.p}</p>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt={"banner"}
                      />
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>

          {/* <SureSection /> */}
        </div>
      </div>
      <DFYClients
        header={"Working with Precision in Process, Excellence in Results"}
        desc={
          "Our impeccable back linking strategies give you access to robust data solutions- easy to implement and use. Here’s how we work."
        }
        ctaLink={
          "https://forms.zohopublic.in/rankmakeup/form/MonthlyLinkBuildingServicesFrom/formperma/6D8s1zzWj6n07cdswYzlM7bE8s-QgUC8PocNl6DkAc4"
        }
      />
      <DIYTestimonials data={Testimonial} />
      <DIYClients data={TestimonialsDataNiche} />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};
export default NicheEdits;
