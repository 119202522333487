import PrimaryButton from "../../Buttons/Primary";
import Container from "../../Container";
import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";

const EBookSingle = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <img
        className={styles.pattern}
        src={"/images/ic_bg_pattern_e.png"}
        alt={"logo"}
      />
      <Container>
        <div className={styles.contWrapper}>
          <div className={styles.left}>
            <h1>Read The Expert Handbook To Effective SEO Strategies</h1>
            <p>
              Dive into our e-books on the best practices for SEO audits,
              acquiring quality backlinks, some DIY SEO tips, and more. Achieve
              wisdom for ultimate online success from the professionals in the
              field through our collections of e-books.
            </p>
            <Link
              className={styles.buttonCont}
              to={"/ebook/Structured-Approach-To-Link-Building"}
            >
              <PrimaryButton buttonText={"Read now"} />
            </Link>
          </div>
          <div className={styles.right}>
            <img
              className={styles.mainImage}
              src={"/images/Structured-Approach-To-Link-Building-pdf.png"}
              alt={"logo"}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EBookSingle;
