import styles from "./styles.module.scss";
import React, { useState } from "react";

const SwitchThreeButton = ({ firstText, secondText, thirdText, onClick }) => {
    const [active, setActive] = useState(firstText);
    return <div className={`${styles.wrapper}`}>
        <div className={`${styles.active} ${active !== firstText && styles.inactive}`} onClick={() => {onClick(firstText);setActive(firstText)}}>
            {firstText}
        </div>
        <div className={`${styles.active} ${active !== secondText && styles.inactive}`} onClick={() => {onClick(secondText);setActive(secondText)}}>
            {secondText}
        </div>
        <div className={`${styles.active} ${active !== thirdText && styles.inactive}`} onClick={() => {onClick(thirdText);setActive(thirdText)}}>
            {thirdText}
        </div>
    </div>;
};

export default SwitchThreeButton;