import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import styles from "./styles.module.scss";
import { useMediaQuery } from 'react-responsive';
import Slider from "react-slick";
import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import SwitchButton from "../../Shared/Buttons/Switch";

const DFYStages = ({ data, header }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);

  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  console.log(active, "hit");

  return (
    <Container>
      <div className={styles.wrapper}>
        <h1>
          {header}
        </h1>

        <div className={styles.buttonCont}>
          <SwitchButton buttonTexts={[data[0].pill, data[1].pill, data[2].pill, data[3].pill]} onClick={(i) => setActive(i)} indexClick={true} />
        </div>

        <p className={styles.descMin}>
          {data[active].desc}
        </p>

        <div className={styles.cardsSection}>
          {data[active].cards.map((item, index) => {
            return (
              <div className={styles.cards} key={index}>
                <img
                  className={styles.icon}
                  src={item.icon}
                  alt={"logo"}
                />

                <h2>{item.header}</h2>
                
                <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
              
              </div>
            );
          })}
        </div>



      </div>
    </Container>
  )
};

export default DFYStages;