import Footer from '../../../components/Footer';
import PrivacyHero from '../../../components/Policies/Privacy';

const PrivacyPolicy = () => {
    return (
        <>
            <PrivacyHero/>
            <Footer />
        </>
    )
};

export default PrivacyPolicy;