import FoundersPage from "../../components/Founders";
import Footer from "../../components/Footer";
import ReviewsPage from "../../components/Reviews";

const Reviews = () => {
    return (
        <>
            <ReviewsPage/>
            <Footer />
        </>
    )
};

export default Reviews;