import CaseStudiesHero from '../../components/CaseStudies/Hero';
import CaseStudiesList from '../../components/CaseStudies/List';
import Footer from '../../components/Footer';

function CaseStudies() {
  return (
    <>
      <CaseStudiesHero />
      <CaseStudiesList />
      <Footer />
    </>
  );
}

export default CaseStudies;
