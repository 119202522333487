import Container from "../../Shared/Container";
import styles from "./styles.module.scss";
import React, { useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { gsap, ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

import Slider from "react-slick";

const HomeTestimonial = ({ data }) => {
  const [activeIndex, setActive] = useState(0);
  const sliderRef = useRef();
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  const container = useRef();
  const headerText = useRef();
  const leftCont = useRef();
  const rightCont = useRef();
  useGSAP(
    () => {
      let t1 = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          markers: false,
          pin: true, // pin the trigger element while active
          start: "top +=120", // when the top of the trigger hits the top of the viewport
          end: "+=2000", // end after scrolling 500px beyond the start
          scrub: 1,
        },
      });

      let t3 = gsap
        .timeline({
          scrollTrigger: {
            trigger: container.current,
            start: "top +=600",
            end: "+=1200",
            scrub: 1,
            markers: false,
          },
        })
        .fromTo(
          headerText.current,
          {
            opacity: 0,
            y: -100,
            scale: 1.1,
            ease: "power1.inOut",
          },
          {
            opacity: 1,
            y: 0,
            scale: 1,
            ease: "power1.inOut",
          },
        );

      let t4 = gsap
        .timeline({
          scrollTrigger: {
            trigger: container.current,
            start: "top +=120",
            end: "+=1600",
            scrub: 1,
            markers: false,
          },
        })
        .fromTo(
          leftCont.current,
          {
            opacity: 0,
            x: -200,
            scale: 1.2,
            ease: "power1.easeInOut",
          },
          {
            opacity: 1,
            x: 0,
            scale: 1,
            ease: "power1.easeInOut",
          },
        );

      let t5 = gsap
        .timeline({
          scrollTrigger: {
            trigger: container.current,
            start: "top +=120",
            end: "+=1600",
            scrub: 1,
            markers: false,
          },
        })
        .fromTo(
          rightCont.current,
          {
            opacity: 0,
            x: 200,
            scale: 1.2,
            ease: "power1.easeInOut",
          },
          {
            opacity: 1,
            x: 0,
            scale: 1,
            ease: "power1.easeInOut",
          },
        );
    },
    { scope: container },
  );

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={"/images/ic_up_arrow.svg"}
        alt={"arrow"}
        className={styles.nextArrow}
        onClick={onClick}
      />
    );
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={"/images/ic_up_arrow.svg"}
        alt={"arrow"}
        className={styles.prevArrow}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    variableWidth: false,
    vertical: true,
    verticalSwiping: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (oldIndex, newIndex) => {
      setActive(newIndex);
    },
  };

  return (
    <Container>
      <div className={styles.wrapper} ref={container}>
        <div ref={headerText}>
          <h1>Dive Into The Voices of Client Satisfaction</h1>
          <p>
            Navigate Your Projects With Precision: Get Insights On The Process,
            Scope, Cost, And Timeline
          </p>
        </div>

        <div className={styles.mainContent}>
          {!isMobile && (
            <div className={styles.leftContent} ref={leftCont}>
              <Slider {...settings} ref={sliderRef}>
                {data.map((item, index) => {
                  return (
                    <div
                      className={`${styles.clientWrapper} ${index !== activeIndex && styles.disabled}`}
                      onClick={() => {
                        if (sliderRef.current) {
                          sliderRef.current.slickGoTo(index);
                        }
                      }}
                    >
                      <img
                        className={styles.partnerLogo}
                        src={item.brand}
                        alt={"carousel item"}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
          <div className={styles.rightContent} ref={rightCont}>
            {!isMobile && (
              <img
                className={styles.quotation}
                src={"/images/ic_quotation.svg"}
                alt={"icon"}
              />
            )}
            <img
              className={styles.partnerImage}
              src={data[activeIndex].personSrc}
              alt={"carousel item"}
            />

            <div className={styles.textWrapper}>
              {isMobile && (
                <img
                  className={styles.quotationMob}
                  src={"/images/ic_quotation.svg"}
                  alt={"icon"}
                />
              )}
              {data[activeIndex].h2  &&<h2>{data[activeIndex].h2}</h2>}
              <p className={styles.review}>{data[activeIndex].p}</p>
              <h3>{data[activeIndex].h3}</h3>
              <p className={styles.occup}>{data[activeIndex].designation}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HomeTestimonial;
