import Container from "../../Shared/Container";
import InputTextButton from "../../Shared/Input/Button";
import styles from "./styles.module.scss";
import PrimaryButton from "../../Shared/Buttons/Primary";
import { Link } from "react-router-dom";

const DFYHero = ({ header, desc, descTwo, ctaLink }) => {
  return (
    <div className={styles.wrapper}>
      <img
        className={styles.leftHand}
        src={"/images/i_left_hand.png"}
        alt={"banner"}
      />
      <img
        className={styles.rightHand}
        src={"/images/i_right_hand.png"}
        alt={"banner"}
      />
      <Container>
        <div className={styles.contentWrapper}>
          <h1>{header}</h1>
          {/* <h1 className={styles.highlighted}>
                        Service Provider
                    </h1> */}
          <p>{desc}</p>

          {descTwo && <p className={styles.descTwo}>{descTwo}</p>}

          {ctaLink ? (
            <Link to={ctaLink}>
              <PrimaryButton link={ctaLink} buttonText={"Book A Call Now"} />
            </Link>
          ) : (
            <InputTextButton
              placeholder={"Your website address"}
              buttonText={"Get your free Audit"}
              buttonTextSmall={"Get now"}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default DFYHero;
