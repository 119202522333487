import styles from "./styles.module.scss";
import Container from "../Shared/Container";
import PrimaryButton from "../Shared/Buttons/Primary";
import InputText from "../Shared/Input";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { gsap, ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

const Footer = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.main}>
          <div className={styles.details}>
            <img
              className={styles.logoMain}
              src={"/images/ic_main_logo.png"}
              alt={"carousel item"}
            />
            <p>
              Helping Agencies & Marketers achieve their true potential with our
              targeted, measurable, and result-driven SEO Solutions.
            </p>
            <br />
            <p>
              Feel confident in every purchase you make with us. If for any
              reason we are not able to keep up to our words and you aren't
              satisfied, contact us within 7 days of receiving your report &
              we'll ensure to make it right, if not, we provide a full refund.
            </p>
          </div>

          {isMobile && (
            <div className={styles.mobView}>
              <div className={styles.dfySection}>
                <p>DFY Service</p>
                <ul>
                  <li onClick={() => navigate("/services/dfy-managed-seo/")}>Fully managed SEO</li>
                  <li onClick={() => navigate("/services/white-label-seo")}>White label SEO</li>
                  <li onClick={() => navigate("/local-seo")}>Local SEO</li>
                  <li onClick={() => navigate("/services/seo-audit")}>SEO audit</li>
                  <li onClick={() => navigate("/services/monthly-link-building")}>Monthly link building</li>
                </ul>
              </div>

              <div className={styles.dfySection}>
                <p>DIY Service</p>
                <ul>
                  <li onClick={() => navigate("/services/foundation-packages/")}>Foundation links</li>
                  <li onClick={() => navigate("/services/buy-backlinks")}>Buy backlinks</li>
                  <li onClick={() => navigate("/services/advanced-local-citation-service")}>Local citation</li>
                  <li onClick={() => navigate("/services/buy-guest-posts")}>Guest post</li>
                  <li onClick={() => navigate("/services/haro-links")}>Haro links</li>
                  <li onClick={() => navigate("/services/niche-edit-links")}>Niche edits</li>
                </ul>
              </div>
            </div>
          )}

          {!isMobile && (
            <div className={styles.dfySection}>
              <p>DFY Service</p>
              <ul>
                <li onClick={() => navigate("/services/dfy-managed-seo/")}>Fully managed SEO</li>
                <li onClick={() => navigate("/services/white-label-seo")}>White label SEO</li>
                <li onClick={() => navigate("/local-seo")}>Local SEO</li>
                <li onClick={() => navigate("/services/seo-audit")}>SEO audit</li>
                <li onClick={() => navigate("/services/monthly-link-building")}>Monthly link building</li>
              </ul>
            </div>
          )}

          {!isMobile && (
            <div className={styles.dfySection}>
              <p>DIY Service</p>
              <ul>
                <li onClick={() => navigate("/services/foundation-packages/")}>Foundation links</li>
                <li onClick={() => navigate("/services/buy-backlinks")}>Buy backlinks</li>
                <li onClick={() => navigate("/services/advanced-local-citation-service")}>Local citation</li>
                <li onClick={() => navigate("/services/buy-guest-posts")}>Guest post</li>
                <li onClick={() => navigate("/services/haro-links")}>Haro links</li>
                <li onClick={() => navigate("/services/niche-edit-links")}>Niche edits</li>
              </ul>
            </div>
          )}

          <div className={styles.contactUs}>
            <p>Connect with us for trends and offers</p>

            <InputText placeholder={"Email address"} />

            <div className={styles.subsButton}>Subscribe</div>
            <ul>
              <li onClick={() => navigate("/privacy-policy")}>Privacy Policy</li>
              <li onClick={() => navigate("/refund-policy")}>Refund Policy</li>
            </ul>
          </div>
        </div>

        <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d13584.152394831517!2d74.8624088!3d31.6601774!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1716999766378!5m2!1sen!2sin" height="300" width={"300"} className={styles.mapStyle} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        <hr />

        <div className={styles.bottomSection}>
          <div className={styles.first}>
            <div className={styles.icons}>
              <Link to={"https://www.facebook.com/rankjacker"}>
                <img
                  className={styles.socialLogo}
                  src={"/images/ic_facebook.svg"}
                  alt={"social icons"}
                />
              </Link>
              <Link to={"https://www.linkedin.com/in/rankjackerseo/"}>
                <img
                  className={styles.socialLogo}
                  src={"/images/ic_linkdin.svg"}
                  alt={"social icons"}
                />
              </Link>
              <Link to={"https://www.instagram.com/rankjacker/"}>
                <img
                  className={styles.socialLogo}
                  src={"/images/ic_instagram.svg"}
                  alt={"social icons"}
                />
              </Link>
              <Link to={"https://www.youtube.com/channel/UCZ4kx1nmBxaJ5mCUI9O81Zw"}>
                <img
                  className={styles.socialLogo}
                  src={"/images/ic_youtube.svg"}
                  alt={"social icons"}
                />
              </Link>
              <Link to={"https://twitter.com/RankJackerSeo"}>
                <img
                  className={styles.socialLogo}
                  src={"/images/ic_x.svg"}
                  alt={"social icons"}
                />
              </Link>
            </div>
            <p>© 2014 To 2023 Rank Jacker. All Rights Reserved.</p>
          </div>
          {!isMobile && (
            <img
              className={styles.badgeIcon}
              src={"/images/ic_percent_badge.png"}
              alt={"social icons"}
            />
          )}
          {!isMobile && (
            <Link to={'https://www.bark.com/en/us/company/rankjacker-seo/gDGZ9/?utm_medium=referral&utm_source=gDGZ9&utm_campaign=seller-pro-widget&utm_content=seller-pro-widget'}>
              <img
                className={styles.stickerIcon}
                src={"/images/ic_bark_sticker.png"}
                alt={"social icons"}
              />
            </Link>
          )}

          {!isMobile && (

            <Link to={'https://www.trustpilot.com/review/rank-jacker.com'} className={styles.ratingSection}>
              <p>Rank Jacker SEO</p>

              <div className={styles.middle}>
                <p>5.0</p>
                <div className={styles.starsWrapper}>
                  <img
                    className={styles.stars}
                    src={"/images/ic_star.svg"}
                    alt={"stars"}
                  />
                  <img
                    className={styles.stars}
                    src={"/images/ic_star.svg"}
                    alt={"stars"}
                  />
                  <img
                    className={styles.stars}
                    src={"/images/ic_star.svg"}
                    alt={"stars"}
                  />
                  <img
                    className={styles.stars}
                    src={"/images/ic_star.svg"}
                    alt={"stars"}
                  />
                  <img
                    className={styles.stars}
                    src={"/images/ic_star.svg"}
                    alt={"stars"}
                  />
                </div>
              </div>

              <div className={styles.googleBefore}>
                powered by <span>Google</span>
              </div>
            </Link>
          )}

          {!isMobile && (
            <Link className={styles.review} to={"https://clutch.co/profile/rankjacker-seo?utm_source=widget&utm_medium=1&utm_campaign=widget&utm_content=stars&utm_term=www.rank-jacker.com#reviews"}>
              <div className={styles.starsWrapper}>
                <img
                  className={styles.stars}
                  src={"/images/ic_star.svg"}
                  alt={"stars"}
                />
                <img
                  className={styles.stars}
                  src={"/images/ic_star.svg"}
                  alt={"stars"}
                />
                <img
                  className={styles.stars}
                  src={"/images/ic_star.svg"}
                  alt={"stars"}
                />
                <img
                  className={styles.stars}
                  src={"/images/ic_star.svg"}
                  alt={"stars"}
                />
                <img
                  className={styles.stars}
                  src={"/images/ic_star.svg"}
                  alt={"stars"}
                />
              </div>
              <p>2 REVIEWS</p>
            </Link>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Footer;
