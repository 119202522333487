import FoundersPage from "../../../components/Founders";
import Footer from "../../../components/Footer";
import FoundersPageTwo from "../../../components/Founders/Two";

const FoundersTwo = () => {
    return (
        <>
            <FoundersPageTwo/>
            <Footer />
        </>
    )
};

export default FoundersTwo;