import RoundedButton from "../../Shared/Buttons/Rounded";
import Container from "../../Shared/Container";
import styles from "./styles.module.scss";
import React, { useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { gsap, ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";
import Slider from "react-slick";

const Perks = [
  {
    header: "Add your team",
    desc: "Establish a dream team with Rank Jacker and get started with results-driven strategies and unique SEO solutions.",
    icon: "/images/ic_perks_one.svg",
  },
  {
    header: "Easy Ordering",
    desc: "Select the best SEO services packages after a thorough audit from our team. ",
    icon: "/images/ic_perks_two.svg",
  },
  {
    header: "White Label",
    desc: "Our white-label SEO agency adopts tested and data-driven SEO services for relevance, value, and authority.",
    icon: "/images/ic_perks_three.svg",
  },
  {
    header: "Scalable prices",
    desc: "We serve the most scalable and cost-effective SEO options for all, from startups to industry leaders in SAAS, Healthcare, e-commerce, and beyond.",
    icon: "/images/ic_perks_four.svg",
  },
];

const EasyData = [
  {
    text: "Provide holistic SEO campaigns",
    icon: "/images/ic_easy_one.svg",
  },
  {
    text: "Secure relationships with the clients",
    icon: "/images/ic_easy_two.svg",
  },
  {
    text: "Provide transparent Reporting",
    icon: "/images/ic_easy_three.svg",
  },
];

const EasyDataTwo = [
  {
    text: "Employ industry-specific approach",
    icon: "/images/ic_easy_one.svg",
  },
  {
    text: "Conduct technical SEO and page speed optimization",
    icon: "/images/ic_easy_two.svg",
  },
  {
    text: "Provide excellent semantic SEO services",
    icon: "/images/ic_easy_three.svg",
  },
];

const HomeWorkspace = ({
  buttonText,
  onClick,
  outlined = false,
  subSectionList,
  tittle,
  subTitle,
  subSectionTitle,
  subSectionSubTitle,
}) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  const container = useRef();
  const headerText = useRef();
  const leftCont = useRef();
  const rightCont = useRef();
  const bottomCont = useRef();
  const bgCont = useRef();
  const headerTextTwo = useRef();
  const mainCont = useRef();

  const settingsTwo = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    variableWidth: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useGSAP(() => {
    let t1 = gsap.timeline({
      scrollTrigger: {
        trigger: container.current,
        markers: false,
        pin: true, // pin the trigger element while active
        start: "top +=80", // when the top of the trigger hits the top of the viewport
        end: "+=2000", // end after scrolling 500px beyond the start
        scrub: 1
      }
    });

    let t3 = gsap
      .timeline({
        scrollTrigger: {
          trigger: container.current,
          start: "top +=700",
          end: "+=2000",
          scrub: 1,
          markers: false
        }
      })
      // .fromTo(bgCont.current, {
      //   opacity: 0,
      //   y: -1170,
      //   ease: "power1.inOut",
      // }, {
      //   opacity: 1,
      //   y: -400,
      //   ease: "power1.inOut",
      // })
      .fromTo(headerText.current, {
        opacity: 0,
        y: 100,
        scale: 0.9,
        delay: 0.5,
        ease: "power1.easeInOut"
      }, {
        opacity: 1,
        y: 0,
        scale: 1,
        ease: "power1.easeInOut",
      })
      .fromTo(leftCont.current, {
        opacity: 0,
        x: -100,
        ease: "power1.easeInOut"
      }, {
        opacity: 1,
        x: 0,
        ease: "power1.easeInOut"
      })
      .fromTo(rightCont.current, {
        opacity: 0,
        x: 100,
        ease: "power1.easeInOut"
      }, {
        opacity: 1,
        x: 0,
        ease: "power1.easeInOut"
      })
      .fromTo(bottomCont.current, {
        opacity: 0,
        y: 50,
        ease: "power1.easeInOut"
      }, {
        opacity: 1,
        y: 0,
        ease: "power1.easeInOut"
      })
    // .fromTo(headerTextTwo.current, {
    //   opacity: 0,
    //   y: 200,
    //   ease: "power1.easeInOut"
    // }, {
    //   opacity: 1,
    //   y: 0,
    //   ease: "power1.easeInOut",
    //   delay: 3
    // })
    // .fromTo(mainCont.current, {
    //   opacity: 0,
    //   y: 150,
    //   ease: "power1.easeInOut"
    // }, {
    //   opacity: 1,
    //   y: 0,
    //   ease: "power1.easeInOut",
    //   delay: 1
    // })

  }, { scope: container });

  return (
    <div className={styles.wrapper} ref={container}>
      <div className={styles.bgContainer} ref={bgCont} />
      <Container>
        <div className={styles.mainContent}>
          <div ref={headerText}>
            <h1>{tittle}</h1>
            <p>{subTitle}</p>
          </div>


          <div className={styles.perksSection}>
            <img
              className={styles.dashboard}
              src={"/images/i_dashboard.png"}
              alt={"logo"}
              ref={leftCont}
            />

            <div className={styles.serviceCards} ref={rightCont}>
              {Perks.map((item, index) => {
                return (
                  <div className={styles.serviceCard}>
                    <img
                      className={styles.serviceItem}
                      src={item.icon}
                      alt={"logo"}
                    />
                    <h2>{item.header}</h2>
                    {!isMobile && <p>{item.desc}</p>}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.buttonWrapper} ref={bottomCont}>
            <Link to={"/contact-us"}>
              <RoundedButton buttonText={buttonText} />
            </Link>
          </div>


          <div ref={headerTextTwo}>

            <h1>{subSectionTitle}</h1>
            <p>{subSectionSubTitle}</p>
          </div>

          {!isMobile && <div className={styles.easyContainer} ref={mainCont}>
            <div className={styles.easyWrapper}>
              {subSectionList.map((item, index) => {
                return (
                  <div className={styles.easyItem}>
                    <img
                      className={styles.icon}
                      src={item.icon}
                      alt={"logo"}
                    />
                    <p>{item.text}</p>
                  </div>
                );
              })}
            </div>
            <img
              className={styles.patternOne}
              src={"/images/i_pattern_one.svg"}
              alt={"logo"}
            />


          </div>}
          {isMobile && <div className={styles.easyContainerTwo}>
            <Slider {...settingsTwo}>
            {subSectionList.map((item) => {
              return (
                <div>
                <div className={styles.easyCont}>
                  <img
                    className={styles.easyMob}
                    src={item.icon}
                    alt={"logo"}
                  />
                  <p className={styles.textMob}>
                    1{item.text} 
                  </p>
                </div>
                </div>
              )
            })}
          </Slider>
          </div>}
        </div>
      </Container>
    </div>
  );
};

export default HomeWorkspace;
