export const Books = [
  {
    name: "Guide To An Effective SEO Strategy",
    img: "/images/Guide-to-an-Effective-SEO-Strategy.png",
    link: "/ebook/effective-SEO-strategy",
  },
  {
    name: "Structured Approach To Link Building",
    img: "/images/Structured-Approach-To-Link-Building-pdf.png",
    link: "/ebook/Structured-Approach-To-Link-Building",
  },
  {
    name: "Tactical Framework for Anchor Text Optimization",
    img: "/images/TACTICAL FRAMEWORK.png",
    link: "/ebook/Tactical-Framework-for-Anchor-Text-Optimization",
  },
  {
    name: "E-Commerce Website Design Checklist",
    img: "/images/e-COMMERCE-WEBSITE-DESIGN-CHECKLIST.png",
    link: "/ebook/web-design-checklist",
  },
  {
    name: "28 Fatal SEO Errors Most E-Commerce Businesses Make",
    img: "/images/most-common-seo-errors-on-e-commerce-websites.png",
    link: "/ebook/ecommerce-seo-errors",
  },
];

export const Podcasts = [
  "https://www.youtube-nocookie.com/embed/qkjHd-qrnE0?si=LB5vDSBQXoxN1MDJ",
  "https://www.youtube-nocookie.com/embed/8OLt_ho5vHI?si=_ef8LacKsm07d241",
  "https://www.youtube-nocookie.com/embed/7HGBAyUrhWo?si=qw8Mm6zxnYM-wGCf",
  "https://www.youtube-nocookie.com/embed/kEotDTr1FDs?si=ZXMHqIjwNN6dhnMR",
  "https://www.youtube-nocookie.com/embed/YGKgTbJpVho?si=GTdLpDVUXTh2GN3J",
  "https://www.youtube-nocookie.com/embed/dkd2LxjEfgE?si=2828dPJ__PgVsJfv",
  "https://www.youtube-nocookie.com/embed/eeQGhqnx9S4?si=zc6cWq-lM6iAA0xn",
  "https://www.youtube-nocookie.com/embed/Z4LWI9brZYI?si=Z7aWE2TWfHdgg9sT",
  "https://www.youtube-nocookie.com/embed/V5j7eCyCQIA?si=YdF58Sv4TPC24r6z",
  "https://www.youtube-nocookie.com/embed/DChI2IyyVQI?si=O2mSiVOlkiQkJS_G",
  "https://www.youtube-nocookie.com/embed/MoUuKg_WsDU?si=Qno_xlBrWQw-6VEw",
  "https://www.youtube-nocookie.com/embed/4lxwXMJqajw?si=UWUEZCjj8sU-nroy",
];
