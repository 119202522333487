import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import SwitchThreeButton from "../../Shared/Buttons/SwitchThree";
import { useMediaQuery } from "react-responsive";
import React, { useState, useRef, createRef } from "react";
import { Link } from "react-router-dom";
import { gsap, ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

const HomeFaqs = ({ data, enableAnimation = false }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const [active, setActive] = useState("Generals");

  const container = useRef();
  const headerTextLeft = useRef();
  const headerTextRight = useRef();
  const bottomCont = useRef();
  // const rightCont = useRef();

  const [activeIndex, setActiveIndex] = useState(null);

  const elementsRef = useRef(data[active]?.map(() => createRef()));

  useGSAP(
    () => {
      if (enableAnimation) {
        let t1 = gsap.timeline({
          scrollTrigger: {
            trigger: container.current,
            markers: false,
            pin: true, // pin the trigger element while active
            start: "top +=30", // when the top of the trigger hits the top of the viewport
            end: "+=2500", // end after scrolling 500px beyond the start
            scrub: 1,
          },
        });

        let t3 = gsap
          .timeline({
            scrollTrigger: {
              trigger: container.current,
              start: "top +=600",
              end: "+=1250",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            headerTextLeft.current,
            {
              opacity: 0,
              x: -100,
              ease: "power1.easeInOut",
            },
            {
              opacity: 1,
              x: 0,
              ease: "power1.easeInOut",
            },
          );

        let t4 = gsap
          .timeline({
            scrollTrigger: {
              trigger: container.current,
              start: "top +=600",
              end: "+=1250",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            headerTextRight.current,
            {
              opacity: 0,
              x: 100,
              ease: "power1.easeInOut",
            },
            {
              opacity: 1,
              x: 0,
              ease: "power1.easeInOut",
            },
          );
        let t5 = gsap
          .timeline({
            scrollTrigger: {
              trigger: container.current,
              start: "top +=200",
              end: "+=1500",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            [
              elementsRef.current[0].current,
              elementsRef.current[2]?.current,
              elementsRef.current[4]?.current,
              elementsRef.current[6]?.current,
            ],
            {
              opacity: 0,
              x: -250,
              ease: "power1.inOut",
            },
            {
              opacity: 1,
              x: 0,
              ease: "power1.inOut",
            },
          );
        let t6 = gsap
          .timeline({
            scrollTrigger: {
              trigger: container.current,
              start: "top +=200",
              end: "+=2500",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            [
              elementsRef.current[1].current,
              elementsRef.current[3].current,
              elementsRef.current[5]?.current,
            ],
            {
              opacity: 0,
              x: 250,
              ease: "power1.inOut",
            },
            {
              opacity: 1,
              x: 0,
              ease: "power1.inOut",
            },
          );

        let t7 = gsap
          .timeline({
            scrollTrigger: {
              trigger: container.current,
              start: "top +=100",
              end: "+=3000",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            bottomCont.current,
            {
              opacity: 0,
              y: 200,
              ease: "power1.inOut",
            },
            {
              opacity: 1,
              y: 0,
              delay: 0.3,
              ease: "power1.inOut",
            },
          );
      }
    },
    { scope: container, dependencies: [enableAnimation] },
  );

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <Container>
      <div className={styles.wrapper} ref={container}>
        <div className={styles.topSection}>
          <h1 ref={headerTextLeft}>For all your queries</h1>
          {data["Service"] && data["Payments"] && (
            <div ref={headerTextRight}>
              <SwitchThreeButton
                firstText={"Generals"}
                secondText={"Service"}
                thirdText={"Payments"}
                onClick={(i) => {
                  setActive(i);
                  setActiveIndex(null);
                }}
              />
            </div>
          )}
        </div>

        <div className={styles.faqsCont}>
          {data &&
            data[active].length > 0 &&
            data[active]?.map((item, index) => {
              return (
                <div
                  className={`${styles.items} ${
                    activeIndex === index ? styles.active : ""
                  }`}
                  ref={elementsRef.current[index]}
                  key={index}
                >
                  <div
                    className={styles.header}
                    onClick={() => handleClick(index)}
                  >
                    <p>{item.question}</p>
                    <img
                      src={"/images/ic_down_two.png"}
                      alt={"arrow"}
                      className={`${styles.arrow} ${activeIndex === index && styles.oppo}`}
                    />
                  </div>
                  <div className={styles.answer}>{item.answer}</div>
                </div>
              );
            })}
        </div>

        <div className={styles.collabBanner} ref={bottomCont}>
          <img
            src={"/images/ic_rocket.svg"}
            alt={"rocket"}
            className={styles.rocket}
          />
          <img
            src={"/images/i_collaborate_pattern.svg"}
            alt={"pattern"}
            className={styles.pattern}
          />
          <div>
            <h2>Have a project in mind?</h2>
            <h2>Let’s collaborate</h2>
          </div>

          <Link
            to={"https://rankjacker.spp.io/signup"}
            className={styles.button}
          >
            <p>Get Started Today</p>
            <img
              src={"/images/ic_arrow_dark.svg"}
              alt={"arrow"}
              className={styles.arrow}
            />
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default HomeFaqs;
