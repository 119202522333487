import Home from "./containers/Home";
import Header from "./components/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./containers/AboutUs";
import Blogs from "./containers/Blogs";
import DIY from "./containers/DIY";
import DFY from "./containers/DFY";
import BlogsInternal from "./containers/Blogs/components/Internal";
import LocalSEO from "./containers/LocalSEO";
import NicheEdits from "./containers/DIY/NicheEdits";
import ContentStrategy from "./containers/ContentStrategy";
import CaseStudies from "./containers/CaseStudies";
import Founders from "./components/Founders";
import ManaagedSEO from "./containers/DFY/ManagedSEO";
import PNBLinks from "./containers/DIY/PNBlinks";
import FoundationLink from "./containers/DIY/FoundationLink";
import GuestPosts from "./containers/DIY/GuestPosts";
import HaroLink from "./containers/DIY/HaroLink";
import PressRelease from "./containers/DIY/PressRelease";
import LocalCitation from "./containers/DIY/LocalCitation";
import WhiteLabelSEO from "./containers/DFY/WhiteLabelSEO";
import LinkBuilding from "./containers/DFY/LinkBuilding";
import SEOConsultation from "./containers/DFY/SEOConsultation";
import SEOAudit from "./containers/DFY/SEOAudit";
import CustomOutreach from "./containers/DFY/CustomOutreach";
import ContactUs from "./components/Shared/ContactUs";
import EBook from "./components/Shared/EBook";
import BuyBackLinks from "./containers/DIY/BuyBackLinks";
import Reviews from "./containers/Reviews";
import CaseStudiesInternal from "./containers/CaseStudies/Internal";
import PrivacyPolicy from "./containers/Policies/Privacy";
import RefundPolicy from "./containers/Policies/Refund";
import ScrollToTop from "./components/Shared/ScrollToTop";
import FoundersTwo from "./containers/Founders/Two";
import GuideToEffectiveSEO from "./components/Shared/EBook/GuideToEffectiveSEO";
import StructuredApproachToLinkBuildning from "./components/Shared/EBook/StructuredApproachToLinkBuildning";
import TacticalFramework from "./components/Shared/EBook/TacticalFramework";
import WebsiteChecklist from "./components/Shared/EBook/WebsiteChecklist";
import CommonSEOErrors from "./components/Shared/EBook/CommonSEOErrors";
import EComSEO from "./containers/CaseStudies/EComSEO";
import CarDealerSEO from "./containers/CaseStudies/CarDealerSEO";
import LawFirmSEO from "./containers/CaseStudies/LawFirmSEO";
import DentalLinkBuilding from "./containers/CaseStudies/DentalLinkBuilding";
import LocalSEOCase from "./containers/CaseStudies/LocalSEO";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/about-us" element={<AboutUs />}></Route>
        <Route exact path="/all-blogs" element={<Blogs />}></Route>
        <Route exact path="/blogs/:slug" element={<BlogsInternal />}></Route>
        <Route exact path="/diy" element={<DIY />}></Route>
        <Route exact path="/dfy" element={<DFY />}></Route>
        <Route exact path="/local-seo" element={<LocalSEO />}></Route>
        <Route exact path="/case-study" element={<CaseStudies />}></Route>
        <Route
          exact
          path="/services/seo-content-strategy"
          element={<ContentStrategy />}
        ></Route>
        <Route exact path="/founders/anil-kumar" element={<Founders />}></Route>
        <Route exact path="/founders/rohit-dua" element={<FoundersTwo />}></Route>
        <Route exact path="/services/niche-edit-links" element={<NicheEdits />}></Route>
        <Route exact path="/services/buy-pbn-links" element={<PNBLinks />}></Route>
        <Route
          exact
          path="/services/foundation-packages/"
          element={<FoundationLink />}
        ></Route>
        <Route exact path="/services/buy-guest-posts" element={<GuestPosts />}></Route>
        <Route exact path="/services/haro-links" element={<HaroLink />}></Route>
        <Route exact path="/services/best-pr-distribution/" element={<PressRelease />}></Route>
        <Route exact path="/services/advanced-local-citation-service" element={<LocalCitation />}></Route>
        <Route exact path="/services/dfy-managed-seo/" element={<ManaagedSEO />}></Route>
        <Route exact path="/services/white-label-seo" element={<WhiteLabelSEO />}></Route>
        <Route exact path="/services/monthly-link-building" element={<LinkBuilding />}></Route>
        <Route exact path="/services/seo-consulting" element={<SEOConsultation />}></Route>
        <Route exact path="/services/seo-audit" element={<SEOAudit />}></Route>
        <Route exact path="/custom-outreach" element={<CustomOutreach />}></Route>
        <Route exact path="/services/buy-backlinks" element={<BuyBackLinks />}></Route>
        <Route exact path="/contact-us" element={<ContactUs />}></Route>
        <Route exact path="/ebook" element={<EBook />}></Route>
        <Route exact path="/ebook/effective-SEO-strategy" element={<GuideToEffectiveSEO />}></Route>
        <Route exact path="/ebook/Structured-Approach-To-Link-Building" element={<StructuredApproachToLinkBuildning />}></Route>
        <Route exact path="/ebook/Tactical-Framework-for-Anchor-Text-Optimization" element={<TacticalFramework />}></Route>
        <Route exact path="/ebook/web-design-checklist" element={<WebsiteChecklist />}></Route>
        <Route exact path="/ebook/ecommerce-seo-errors" element={<CommonSEOErrors />}></Route>
        <Route exact path="/testimonials" element={<Reviews />}></Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route exact path="/refund-policy" element={<RefundPolicy />}></Route>
        <Route exact path="/case-study/:slug" element={<CaseStudiesInternal />}></Route>
        <Route exact path="/case-study/ecommerce-seo-case-study" element={<EComSEO />}></Route>
        <Route exact path="/case-study/car-dealership-seo" element={<CarDealerSEO />}></Route>
        <Route exact path="/case-study/law-firm-seo-growth" element={<LawFirmSEO />}></Route>
        <Route exact path="/case-study/dental-link-building-case-study" element={<DentalLinkBuilding />}></Route>
        <Route exact path="/case-study/local-seo" element={<LocalSEOCase />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
