import CaseInternalHero from "../../../components/CaseInternal/Hero";
import CaseInternalImpact from "../../../components/CaseInternal/Impact";
import CaseInternalRequirement from "../../../components/CaseInternal/Requirement";
import CaseInternalSolution from "../../../components/CaseInternal/Solution";
import CaseInternalTime from "../../../components/CaseInternal/Time";
import CaseStudiesHero from "../../../components/CaseStudies/Hero";
import Footer from "../../../components/Footer";
import HomeTestimonial from "../../../components/Home/Testimonial";
import Container from "../../../components/Shared/Container";
import { TestimonialDefault } from "../../../cont/Reviews";
import styles from "./styles.module.scss";


function CaseStudiesInternal() {
    return (
        <>
            <CaseInternalHero />
            <CaseInternalRequirement/>
            <CaseInternalSolution />
            <CaseInternalTime />
            <CaseInternalImpact />
            {/* <HomeTestimonial data={TestimonialDefault} /> */}
            <Container>
            <div className={styles.collabBanner}>
                    <img src={"/images/ic_rocket.svg"}
                        alt={"rocket"}
                        className={styles.rocket} />
                    <img src={"/images/i_collaborate_pattern.svg"}
                        alt={"pattern"}
                        className={styles.pattern} />
                    <div>
                        <h2>
                            Have a project in mind?
                        </h2>
                        <h2>
                            Let’s collaborate
                        </h2>
                    </div>

                    <div className={styles.button}>
                        <p>
                            Get Started Today
                        </p>
                        <img src={"/images/ic_arrow_dark.svg"}
                            alt={"arrow"}
                            className={styles.arrow} />
                    </div>

                </div>
            </Container>
            <Footer />
        </>
    );
}

export default CaseStudiesInternal;
