import PrimaryButton from "../../Shared/Buttons/Primary";
import Container from "../../Shared/Container";
import styles from "./styles.module.scss";

const PricingData = [
    {
        icon: '/images/ic_niche_two.svg',
        header: 'Ignite',
        desc: `Fully Managed SEO`,
        price: `599$`,
        listData: [`5 Corner Stone Back Links`,
            `1X DA 30+ Guest Post`,
            `1X DA 20+ Guest Post`,
            `Deep Level Website Audit Included`,
            `On page Recommendations + Implementations`,
            `Data-Driven Anchor / URL Strategy `,
            `Keyword Performance Tracking `,
            `Monthly Performance Review `,
            `Dedicated Account Manager `,
            `Contextual Links to Blog and Service Pages`
        ]
    },
    {
        icon: '/images/ic_niche_two.svg',
        header: 'Boost',
        desc: `Fully Managed SEO`,
        price: `1299$`,
        listData: [`9 Corner Stone Back Links`,
            `2X DA 40+ Guest Post`,
            `3X DA 30+ Guest Post `,
            `2X DA 20+ Guest Post`,
            `Anchor / URL strategy`,
            `On page Recommendations + Implementations`,
            `Keyword Performance Tracking `,
            `Monthly Performance Review `,
            `Dedicated Account Manager `,
            `Contextual Links to Blog and Service Pages`
        ]
    },

    {
        icon: '/images/ic_niche_two.svg',
        header: 'Skyrocket',
        desc: `Fully Managed SEO`,
        price: `1999$`,
        listData: [`5 Corner Stone Back Links`,
            `1X DA 30+ Guest Post`,
            `1X DA 20+ Guest Post`,
            `Deep Level Website Audit Included`,
            `On page Recommendations + Implementations`,
            `Data-Driven Anchor / URL Strategy `,
            `Keyword Performance Tracking `,
            `Monthly Performance Review `,
            `Dedicated Account Manager `,
            `Contextual Links to Blog and Service Pages`
        ]
    }
]

function LocalSEOPricing() {
    return (
        <Container>
            <div className={styles.wrapper}>
                <div className={styles.headerSection}>
                    <h1>
                        Know The Pricing
                    </h1>
                    <p>
                        Automate your local SEO strategies with our monthly Managed SEO service plans for professional support and top-notch control of your brand value.
                    </p>
                </div>

                <div className={styles.cardsSection}>
                    {PricingData.map((item, index) => {
                        return (
                            <div className={styles.cards}>
                                <img className={styles.logoImage} src={item.icon} alt={'logo'} />
                                <h2>
                                    {item.header}
                                </h2>
                                <p>
                                    {item.desc}
                                </p>

                                <div className={styles.price}>
                                    {item.price}
                                </div>

                                <div className={styles.buttonCont}>
                                    <PrimaryButton buttonText={'Order now'} />
                                </div>
                                <ul>
                                    {item.listData.map((item, index) => {
                                        return (
                                            <li>
                                                {item}
                                            </li>
                                        )
                                    })}
                                </ul>


                            </div>
                        )
                    })}
                </div>
            </div>
        </Container>
    );
}

export default LocalSEOPricing;
