import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import SwitchButton from "../../Shared/Buttons/Switch";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Data = Array.from({ length: 3 });

const mainData = {
  "Authority links": [
    {
      icon: "/images/ic_niche_two.svg",
      header: "Niche Edit",
      desc: `Premium Niche Edit Links placements with KW Anchors on already proven web resources with established authority & Industry / Niche Relevance.`,
      anchors: ["Branded Keywords", "Generic Links", "Naked URL"],
      orientation: [
        "Increases Brand Awareness, Positioning & Appeal",
        "To create relevance and Trust",
        "Push Authority",
      ],
      outcome: [
        "Helps Push through Google Sandbox",
        "Initial Link Boost & Authority",
        "Anchor Profile Diversification",
      ],
      link: "https://rankjacker.spp.io/order/Niche-Edits",
    },
    {
      icon: "/images/ic_guest_two.svg",
      header: "Guest Post",
      desc: `Start building influence in your niche right off the hook with our Premium Editorial Quality Backlinks on high-traffic websites.`,
      anchors: ["Branded Keywords", "Generic Links", "Naked URL"],
      orientation: [
        "Increases Brand Awareness, Positioning & Appeal",
        "To create relevance and Trust",
        "Push Authority",
      ],
      outcome: [
        "Helps Push through Google Sandbox",
        "Initial Link Boost & Authority",
        "Anchor Profile Diversification",
      ],
      link: "https://rankjacker.spp.io/order/7ZN16P",
    },
    {
      icon: "/images/ic_traffic.svg",
      header: "PBN Links",
      desc: `DFY PBN Links with Homepage placements, Contextual & KW Anchors on our highly secure PBN Network to add an instant boost to your rankings. `,
      anchors: ["Branded Keywords", "Generic Links", "Naked URL"],
      orientation: [
        "Increases Brand Awareness, Positioning & Appeal",
        "To create relevance and Trust",
        "Push Authority",
      ],
      outcome: [
        "Helps Push through Google Sandbox",
        "Initial Link Boost & Authority",
        "Anchor Profile Diversification",
      ],
      link: "https://rankjacker.spp.io/order/2LRQ63",
    },
  ],
  "Foundational links": [
    {
      icon: "/images/ic_niche_two.svg",
      header: "Google Site Stacks",
      desc: `A perfect way to use the trust of Google’s own properties in a complex link wheel on Google Site that pushes through local competition for multiple KWs.`,
      anchors: [
        "Long Tail Keywords",
        "Synonyms",
        "Alternate Keywords",
        "Branded",
      ],
      orientation: [
        "Informational",
        "Listicles",
        "Comparison",
        "Alternate View",
      ],
      outcome: [
        "Pushes KW Relevance & Trust",
        "Brand Positioning",
        "Helps SERP Ranking Improvement",
      ],
      link: "/services/haro-links",
    },
    {
      icon: "/images/ic_guest_two.svg",
      header: "Domain Authority Stacks",
      desc: `Increase your money site’s Trust, Authority & relevance in SERPs with premium Web 2.0 property’s link stacks. `,
      anchors: [
        "Long Tail Keywords",
        "Synonyms",
        "Alternate Keywords",
        "Branded",
      ],
      orientation: [
        "Informational",
        "Listicles",
        "Comparison",
        "Alternate View",
      ],
      outcome: [
        "Pushes KW Relevance & Trust",
        "Brand Positioning",
        "Helps SERP Ranking Improvement",
      ],
      link: "/services/niche-edit-links",
    },
    {
      icon: "/images/ic_traffic.svg",
      header: "Social Fortress",
      desc: `The first set of safest links to build on the web that let you amplify your brand, and lock your brand name on prominent Social Properties in your desired niche. `,
      anchors: [
        "Long Tail Keywords",
        "Synonyms",
        "Alternate Keywords",
        "Branded",
      ],
      orientation: [
        "Informational",
        "Listicles",
        "Comparison",
        "Alternate View",
      ],
      outcome: [
        "Pushes KW Relevance & Trust",
        "Brand Positioning",
        "Helps SERP Ranking Improvement",
      ],
      link: "/",
    },
  ],
  "Local SEO Links": [
    {
      icon: "/images/ic_niche_two.svg",
      header: "Localized Map Networks",
      desc: `A unique way to push your GMB rankings with mentions on random non-competing local sources. Map Networks lets you appear in Local Map packs.`,
      anchors: ["Keyword", "Synonyms", "Variations"],
      orientation: ["Infulensive", "Authoritative"],
      outcome: [
        "Targeted Keyword Rankings",
        "Competitive Advantage",
        "Authority Building",
      ],
      link: "/services/niche-edit-links",
    },
    {
      icon: "/images/ic_guest_two.svg",
      header: "Press Release",
      desc: `Targeted Media Outreach to 450+ News & Media Publications with Guaranteed Google News Inclusion for a strong Brand Statement.`,
      anchors: ["Keyword", "Synonyms", "Variations"],
      orientation: ["Infulensive", "Authoritative"],
      outcome: [
        "Targeted Keyword Rankings",
        "Competitive Advantage",
        "Authority Building",
      ],
      link: "/services/buy-guest-posts",
    },
    {
      icon: "/images/ic_traffic.svg",
      header: "Advanced Citations",
      desc: `Grace Your Business with the most Advanced, Niche-relevant Geo specific Local Directories. 100% Manually Built with out-most NAP Consistency.`,
      anchors: ["Keyword", "Synonyms", "Variations"],
      orientation: ["Infulensive", "Authoritative"],
      outcome: [
        "Targeted Keyword Rankings",
        "Competitive Advantage",
        "Authority Building",
      ],
      link: "/services/buy-pbn-links",
    },
  ],
};

const DescData = {
  "Authority links":
    "Start building authority in your niche right away with a variety of High DA/DR backlinks. Along with great metrics, we also make sure to place your backlinks tactically within the flow of a topically relevant article to attract eyeballs, inducing action with an effective CTA. So, no random placements devoid of any Context or Relevance; the key ingredients to pass on the desired impact of a backlink & to build an invincible backlink Profile. Take a look at different types of links",
  "Foundational links": `A powerful and evergreen method for attracting relevance, trust signals, and anchor diversification, the foundation links are most crucial at the start of any SEO project, to gain the initial momentum for link building & brand positioning. Inspired by basic marketing principles, we can help you build High DA properties of varied nature to create optimal brand exposure among random audiences, as well as passing niche/topical/industry relevance to make it the perfect marketing launch for your website.`,
  "Local SEO Links": `DFY Local SEO Link Building solutions for Business Owners who prefer to keep control of their SEO themselves. As a Marketer, you can also resell these services to your clients as and when required based on your campaign requirements.`,
};

function SureSectionTwo() {
  const [active, setActive] = useState("Authority links");
  const navigate = useNavigate();

  return (
    <Container>
      <div className={styles.sureSection}>
        <div className={styles.headerSection}>
          <h1>Choose as per your needs</h1>
          <div className={styles.buttonCont}>
            <SwitchButton
              buttonTexts={[
                "Authority links",
                "Foundational links",
                "Local SEO Links",
              ]}
              onClick={(i) => setActive(i)}
            />
          </div>
          <p>{DescData[active]}</p>
        </div>

        <div className={styles.cardsSection}>
          {mainData[active].map((item, index) => {
            return (
              <div className={styles.cards}>
                <img
                  className={styles.logoImage}
                  src={item.icon}
                  alt={"logo"}
                />
                {active === "Authority links" && (
                  <h2 className={styles.highlight}></h2>
                )}
                <h2>{item.header}</h2>
                <p>{item.desc}</p>

                <Link to={item.link}>
                  <div className={styles.buttonCont}>Order Now</div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export default SureSectionTwo;
