import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import { useMediaQuery } from "react-responsive";
import SwitchButton from "../../Shared/Buttons/Switch";
import SureSection from "../../ContentStrategy/SureSection";
const Data = [1, 2, 3, 4, 5];
const MobileData = [1, 2];

const RequirementData = [
  {
    icon: "/images/ic_niche_one.svg",
    header: "Niche Edits",
    desc: `Our link building agency creates original, valuable content for every link we place. This is the best way to build links because it meets Google's guidelines.`,
  },
  {
    icon: "/images/ic_pbn.svg",
    header: "PBN Links",
    desc: `We build valuable links with contextual anchor text. Our custom link building services are tailored to your keyword goals.`,
  },
  {
    icon: "/images/ic_guest_one.svg",
    header: "Guest Post",
    desc: `We build backlinks through manual outreach. Your links will come from relevant sites in your industry that have strong search engine metrics.`,
  },
];

const SureData = [
  {
    icon: "/images/ic_niche_two.svg",
    header: "Niche Edit",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
  {
    icon: "/images/ic_guest_two.svg",
    header: "Guest Posting",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
  {
    icon: "/images/ic_traffic.svg",
    header: "Increases traffic",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
];

function DIYDetails({ logoList }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.bgContainer} />
      <div className={styles.bgContainerBottom} />

      <Container>
        <div className={styles.mainContent}>
          <div className={styles.partners}>
            {logoList.map((item, key) => {
              return (
                <img
                  className={styles.logoImage}
                  src={item}
                  alt={"logo"}
                  key={key}
                />
              );
            })}
          </div>

          <div className={styles.appointment}>
            <div className={styles.left}>
              <div className={styles.topText}>What you get</div>
              <div className={styles.header}>
                Improve your search engine signals, build brand awareness,
                improve organic rankings for long-terms
              </div>
              <div className={styles.buttonCont}>
                <PrimaryButton buttonText={"Book an appointment"} />
              </div>
            </div>
            <div className={styles.right}>
              <img
                className={styles.logoImage}
                src={"/images/ic_appointment.png"}
                alt={"logo"}
              />
            </div>
          </div>

          <div className={styles.requirement}>
            <div className={styles.headerSection}>
              <h1>Choose as per your requirement</h1>
              <div className={styles.buttonCont}>
                <SwitchButton
                  buttonTexts={["DA authority", "Foundational links", "Local SEO links"]}
                />
              </div>
              <p>
                A powerful and evergreen method for attracting relevance, trust
                signals, and anchor diversification, the foundation links are
                most crucial at the start of any SEO project, to gain the
                initial momentum for link building & brand positioning.
              </p>
            </div>

            <div className={styles.cardsSection}>
              {RequirementData.map((item, index) => {
                return (
                  <div className={styles.cards}>
                    <img
                      className={styles.logoImage}
                      src={item.icon}
                      alt={"logo"}
                    />
                    <h2>{item.header}</h2>
                    <p>{item.desc}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <SureSection />

          <div className={styles.clientSection}>
            <div className={styles.topSection}>
              <div className={styles.left}>
                <div className={styles.header}>
                  Helping clients do more with curated process
                </div>
                <div className={styles.desc}>
                  Our software arms researchers with powerful data solutions
                  that are simple to implement and utilize.
                </div>
                <div className={styles.buttonCont}>
                  <PrimaryButton buttonText={"Talk to us"} />
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.card}>
                  <img
                    className={styles.icon}
                    src={"/images/ic_audit.svg"}
                    alt={"logo"}
                  />
                  <h3>On page and technical audit</h3>
                  <p>
                    On Page Audit and Optimization – There are several factors
                    which when done right makes a successful on-page
                    optimization. We have seen a lot of our clients who have
                    beautiful and well-crafted websites that just can’t rank in
                    search engines. Factors like thin content, image to text
                    ratio, duplicate contents, similar or missing title tags and
                    meta descriptions and the list keeps going on…We have a
                    checklist of more than 200 checkpoints, which we analyze and
                    implement for optimizing your website’s On-page SEO.
                  </p>
                </div>
                <div className={styles.bottomSec}>
                  <div className={styles.left}>Our process</div>
                  <div className={styles.right}>1/5</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default DIYDetails;
