import AboutUsDetails from "../../components/AboutUs/Details";
import AboutUsHero from "../../components/AboutUs/Hero";
import AboutUsValues from "../../components/AboutUs/Values";
import Footer from '../../components/Footer';
import styles from "./styles.module.scss";

const AboutUs = () => {
    return (
        <>
            <AboutUsHero />
            <AboutUsDetails />
            <AboutUsValues />
            <Footer />
        </>
    )
};

export default AboutUs;