import Footer from '../../../components/Footer';
import RefundHero from '../../../components/Policies/Refund';

const RefundPolicy = () => {
    return (
        <>
            <RefundHero/>
            <Footer />
        </>
    )
};

export default RefundPolicy;