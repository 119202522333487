import SwitchButton from "../Shared/Buttons/Switch";
import Container from "../Shared/Container";
import styles from "./styles.module.scss";
import { useMediaQuery } from 'react-responsive';
import React, { useState } from "react";
import { ReviewsData, TestimonialsData } from "../../cont/Reviews";

const ReviewsPage = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 576px)'
    });

    const [active, setActive] = useState('Testimonials');
    console.log(active);
    return (
        <Container>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h1>
                        Reviews of the students and
                        <br />
                        researchers we have helped
                    </h1>
                    <p>
                        Trusted by over 2000 students who no longer struggle with
                        <br />
                        SOP, LOR, Resume and Academic Writing.
                    </p>

                    <img src={"/images/people.png"}
                        alt={"people"} />
                </div>

                <div className={styles.mainCont}>
                    <div className={styles.buttonWrapper}>
                        <SwitchButton buttonTexts={['Testimonials', 'Video reviews']} onClick={(i) => setActive(i)} />
                    </div>


                    {active === 'Video reviews' && <div className={styles.reviewCards}>
                        {ReviewsData.map((item, index) => {
                            return (
                                <div className={styles.card}>
                                    <img src={item}
                                        alt={"people"} />
                                </div>
                            )
                        })}


                    </div>}

                    {active === 'Video reviews' && <div className={styles.more}>
                        and many more
                    </div>}

                    {active === 'Testimonials' && <div className={styles.testimonials}>
                        {TestimonialsData.map((item, index) => {
                            return (<div>
                                <h1>
                                    {item.data}
                                </h1>
                                <p>
                                    {item.name}
                                </p>
                                <img src={item.image}
                                    alt={"people"} />
                                    {index !== (TestimonialsData.length - 1) && <hr/>}
                            </div>)
                        })}
                    </div>}
                </div>

                <div className={styles.collabBanner}>
                    <img src={"/images/ic_rocket.svg"}
                        alt={"rocket"}
                        className={styles.rocket} />
                    <img src={"/images/i_collaborate_pattern.svg"}
                        alt={"pattern"}
                        className={styles.pattern} />
                    <div>
                        <h2>
                            Have a project in mind?
                        </h2>
                        <h2>
                            Let’s collaborate
                        </h2>
                    </div>

                    <div className={styles.button}>
                        <p>
                            Get Started Today
                        </p>
                        <img src={"/images/ic_arrow_dark.svg"}
                            alt={"arrow"}
                            className={styles.arrow} />
                    </div>

                </div>
            </div>
        </Container>
    )
};

export default ReviewsPage;