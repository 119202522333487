export const FaqHome = {
    Generals: [
        {
            question: "How much does it cost to outsource SEO?",
            answer: "Your costs depend on your scope, competition in your niche & of course your budget. The best approach shall be to book a discovery call with us, a little bit of basic information shall be enough for us to share pricing."
        },
        {
            question: "Is there a monthly agreement?",
            answer: "No, absolutely not. We earn our next month with the results we bring in for you."
        },
        {
            question: "What makes you a trusted white label SEO agency?",
            answer: "Being in this industry for more than 8 years and counting, we know that trust is earned with commitment and results. We open our mouths only when we know we can guarantee you the results. No matter what service you choose to work with us, we can guarantee you results, if not, we provide you a full refund."
        },
        {
            question: "Are your white label SEO solutions scalable?",
            answer: "Our white label solutions are in resonance with the latest market trends, Google algorithm, and resources that are sure to keep you ahead of your competitors. Our dedicated research and development team monitors the industry and implements the most potent strategies to scale your business."
        },
        {
            question: "What are the main advantages of using white label SEO services?",
            answer: "White labeling SEO makes it less hectic and easier to scale your business than making it happen by your own team if you have fewer human resources or less experienced team members. We can be an extension of your current team and easily collaborate with your project managers to make it a success for your clients. We have the expertise and know the latest trends that are going to work every time you implement them."
        },
        {
            question: "What does your white label service include?",
            answer: "We let you have full control… be it you need us for DFY services or DIY solutions, we got your back. Feel free to reach out to us to avail your 30 min free consultation. For a more specific answer please check out the list of services."
        },
        {
            question: "How do you structure your SEO campaign?",
            answer: "Our SEO campaign starts with a technical and on-page SEO audit, then we move on towards the research, content strategy, and content creation. As an ongoing process, we keep on monitoring the content for the content effectiveness and SERP ranking. Along with content marketing which increases your organic ranking, we also audit your website for link building and start off with generating high DA quality links that ensure to bring an influx amount of traffic. Then we send you the report containing every minute detail of the project so that you can be in complete control of our executions. To know more about any specific service please visit that individual service page."
        }
    ],
    // Service: [
    //     {
    //         question: "Is there any monthly agreement for your services?",
    //         answer: "No. We do not believe in fixed agreement, but the outcomes we get for you earn us our next month."
    //     },
    //     {
    //         question: "Does Rank Jacker Provide scalable white-label SEO services?",
    //         answer: "Our white-label services follow the most recent developments in the industry, the Google algorithm, and the tools you need to stay one step ahead of your rivals. Our committed research and development staff analyzes the market and applies the most effective business-scaling tactics."
    //     },
    //     {
    //         question: "What benefits can I expect by investing in your SEO services?",
    //         answer: "Our white-labeling SEO simplifies and eases scaling your business compared to doing it yourself. We can work seamlessly with your project managers as an extension of your present team."
    //     },
    //     {
    //         question: "What kinds of SEO services does Rank Jacker offer?",
    //         answer: "Rank Jacker provides a unique range of DFY services or DIY solutions. Contact us to schedule your free 30-minute consultation. See the list of services for a more detailed response."
    //     },
    //     {
    //         question: "What is the structure of your SEO campaign?",
    //         answer: "Our SEO campaigns usually begin with a technical audit and evolve through research, content strategy, and creation. We continually monitor content effectiveness and SERP ranking- while optimizing link-building for increased organic traffic. Detailed progress reports keep you in control."
    //     },
    //     {
    //         question: "Why should you select Rank Jacker for white-label link building?",
    //         answer: "We are the masters of successful link-building techniques since we have a comprehensive record of the stylistic needs of every publisher we have ever pitched to. Get quick editing and rewriting so you do not miss any possible placement opportunities."
    //     },
    // ],
    // Payments: [
    //     {
    //         question: "What payment method do you use for the project?",
    //         answer: "We accept several payment options, like PayPal, Stripe, and Mulya. Clients can also use Bank Transfers or Credit cards or Select Crypto Currency. Our team sends comprehensive instructions after you register with us!"
    //     },
    //     {
    //         question: "Can I change my SEO service plan based on my budget?",
    //         answer: "Yes. Rank Jacker offers DFY and DIY services that fit clients with distinctive budgetary limits. Our effective SEO plans vary based on client requirements and customisation."
    //     },
    //     {
    //         question: "How much does Rank Jacker charge for each SEO service?",
    //         answer: "The cost of our SEO packages usually depends on your product niche, specific requirements, and the budget you set. We provide 24/7 staff for any budgetary discussions with the team."
    //     },
    //     {
    //         question: "Should I expect hidden fees with your SEO services?",
    //         answer: "No. At Rank Jacker, we work ethically, keeping all the information and project progress transparent with our clients. We provide regular updates to the client if they ask for services not included in the SEO package they opt for."
    //     },
    //     {
    //         question: "How can I know the total cost of my SEO package?",
    //         answer: "Rank Jacker provides a detailed breakdown of costs for every service you opt for. Register with our program to receive a budgetary list from our in-house team."
    //     },
    // ],
};