import DIYHero from "../../../components/DIY/Hero";
import DIYDetails from "../../../components/DIY/Details";
import DIYTestimonials from "../../../components/DIY/Testimonials";
import HomeFaqs from "../../../components/Home/Faqs";
import Footer from "../../../components/Footer";
import Details from "../../../components/DFY/Details";
import styles from "../styles.module.scss";
import Container from "../../../components/Shared/Container";
import PrimaryButton from "../../../components/Shared/Buttons/Primary";
import SwitchButton from "../../../components/Shared/Buttons/Switch";
import { useMediaQuery } from "react-responsive";
import PricingOptions from "../../../components/DIY/PricingOptions";
import DFYClients from "../../../components/DFY/Clients";
import { useNavigate } from "react-router-dom";
import {
  PBNData,
  TestimonialsData,
  TestimonialsDataPBN,
} from "../../../cont/ClientData";
import DIYClients from "../../../components/DIY/Clients";
import DFYExperts from "../../../components/DFY/Experts";

const logoList = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-5.png",
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-5.png",
];

const logoListMobile = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
];

const DominateData = [
  {
    icon: "/images/speaker.svg",
    header: "Authoritative AGED DOMAINS",
    desc: "High-quality and aged domains up to 7 years old and we continuously add new domains to keep our PBN ever-evolving, impactful, and authoritative to do the trick for you.",
  },
  {
    icon: "/images/blog.svg",
    header: "Huge Diverse Network",
    desc: "Addition of new sites into the general network is what we do constantly. This way we can continue the supply of links month after month without any repetition of sites.",
  },
  {
    icon: "/images/link.svg",
    header: "High End Maintenance",
    desc: "As a standard practice, we take a great amount of effort to continuously monitor, identify & trim contents off our PBNs to keep their quality intact, but don’t worry, we make sure, that any link stays before we knock them off.",
  },
  {
    icon: "/images/wheel.svg",
    header: "Superior-Quality Content",
    desc: "For contextualizing your link, we do not use any sort of spun content. Each write-up is free of grammatical errors and is written natively.",
  },
];

const Pricing = [
  {
    price: "$15",
    unit: "Link",
    desc: "Budget PBN links",
    list: [
      "DA 30+ (Domain Authority)",
      "From Multi Niche Sites",
      "Do-Follow Link",
      "Unique Content",
      "Hosted In Unique IP’s",
      "Zero Footprints",
      "Permanent Post",
      "100% Safe",
    ],
  },
  {
    price: "$20",
    unit: "Link",
    desc: "Budget PBN links",
    list: [
      "High DA 50+ (Domain Authority)",
      "From Multi Niche Sites",
      "Do-Follow Link",
      "Unique Content",
      "Hosted In Unique IP’s",
      "Zero Footprints",
      "Permanent Post",
      "100% Safe",
    ],
  },
  {
    price: "$25",
    unit: "Link",
    desc: "Budget PBN links",
    list: [
      "High DA 50+ (Domain Authority)",
      "From Multi Niche Sites",
      "Do-Follow Link",
      "Unique Content",
      "Hosted In Unique IP’s",
      "Zero Footprints",
      "Permanent Post",
      "100% Safe",
    ],
  },
];

const FaqData = {
  Generals: [
    {
      question: "What Steps Are Taken By You To Ensure A Safe Network?",
      answer:
        "We minutely monitor and track each & every site in our networks to assure the most trustworthy private links available. Non-Seo hosting is used by us for ensuring that all blogs possess 100% varied A, B, & C class IPs. We differ with our CMS – from WP to Html to everything from themes to logos to site structure all you get is a world and extraordinary material. For maintaining the optimum level of the network we block bots and offer only partial screen-shot reports. For an enduring and strong blog network, we simply avoid footprints and exposure.",
    },
    {
      question: "Can We Know The Turn-Around Time?",
      answer:
        "To get your link order it may take just about 14 business days. In case we notice any sort of turn-around times coming then we will surely close the service of ours to new clients.",
    },
    {
      question: "Can I Know The Refund Policy?",
      answer:
        "We want to make it clear that there is no refund after links are placed. If the link drops within a time span of 12 months then we happily replace it with an equal or greater link.",
    },
    {
      question: "What Time It Will Take My Link To Index?",
      answer:
        "There is no exact answer for this but most of the results try to show-up in a few days and not weeks or months, which may be the case for some lower-quality links.",
    },
    {
      question: "In How Much Time We Can Start Seeing The Results?",
      answer:
        "Many of the clients witness good gains and report within some days of link placement. Though any exact date cannot be pinpointed. Since these are some of the best links, hence they are bound to outperform anything over the lower quality links.",
    },
    {
      question: "Can You Suggest Which Anchor Text To Use?",
      answer: "Of course, the add-on service can be ordered by you.",
    },
  ],
};

const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "RankJacker is one of the most professional sellers with great services and A++ Grade communication. I saw movement from these links and for the price they're hard to beat.",
    h3: "Steve",
    designation: "SEO Link Outreach Manager",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Another nice job. Very honest PBN link seller. Looking forward to a bump in rankings. Thanks to Rankjacker SEO.",
    h3: "Ron Stone",
    designation: "Business Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank Jacker delivered this gig promptly and as advertised. I'm very satisfied with this service and I'm going to use it again. Now I'm looking forward to seeing my keywords rankings after receiving these links. Thanks!",
    h3: "Garry",
    designation: "Agency Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Guys, what should I say?! I ordered this gig 3 times over the last 3 months, now the 4th time. I ranked a high selling KW on top 3 with a SV of 30k EXCLUSIVELY with these links here! They are beautiful beasts! Everyone who is looking for links, this seller here is the best one on Konker!",
    h3: "PSBrain",
    designation: "Customer",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Thanks to Rank-Jacker SEO's PBN links, our online visibility has improved dramatically. Their links are not only high-quality but also generate genuine leads, resulting in a noticeable boost in sales.",
    h3: "Michael Thompson",
    designation: "Owner Of E-commerce Business",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Good seller and really helpful as well which is nice when you don't really know what you're talking about hahaha. have seen good results with this seller",
    h3: "Alex Johnson",
    designation: "CEO",
    personSrc: "/images/i_client_one.png",
  },
];

const PNBLinks = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();

  return (
    <>
      <DIYHero
        bgImage={"/images/pnb-bg.png"}
        title={"BUY HIGH DA PBN BACKLINKS"}
        subtitle={
          "The Most Authoritative, Vibrant & The Safest Private Blog NetworkTo Push Your Rankings Through The Roof"
        }
      />
      <div className={styles.wrapper}>
        <div className={styles.bgContainer} />
        <div className={styles.bgContainerBottom} />

        <div className={styles.mainContent}>
          <Container>
            <div className={styles.partners}>
              {!isMobile
                ? logoList.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })
                : logoListMobile.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })}
            </div>

            <div className={styles.appointment}>
              <div className={styles.left}>
                <div className={styles.topText}>
                  WHAT MAKES OUR PBN SO POWERFUL?
                </div>
                <div className={styles.header}>
                  PBN links have proven time & again, to be a genuine solution
                  to your Off-Page needs, provided they’re scraped, created &
                  managed efficiently. At RankJacker, other than the obvious
                  DA/RD metrics & Spam control, here’s a whole bunch of safety &
                  authority parameters that we ensure to help you.
                </div>
                <div className={styles.buttonCont}>
                  <PrimaryButton
                    buttonText={"Book an appointment"}
                    onClick={() => navigate("/contact-us")}
                  />
                </div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.logoImage}
                  src={"/images/ic_appointment.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </Container>

          {/*Dominate*/}
          <div className={styles.dominate}>
            <Container>
              <div className={styles.headerSection}>
                <h1>Dominate the Local or National SERPs</h1>
              </div>

              <div className={`${styles.cardsSection}`}>
                {DominateData.map((item, index) => {
                  return (
                    <div className={styles.cards} key={index}>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt={"logo"}
                      />

                      <h2>{item.header}</h2>
                      <p>{item.desc}</p>
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>

          <PricingOptions
            data={Pricing}
            heading={"Pricing and Options"}
            subHeading={
              "Through your RJ dashboard, you can Buy Pbn Links in a sizable quantity. You can get special wholesale pricing. Be it 10 or 60, each and every link of yours is posted safely on our high-quality blog network."
            }
          />
          <DFYClients
            dark={true}
            data={PBNData}
            header={"Working with Precision in Process, Excellence in Results"}
            desc={
              "Our impeccable back linking strategies give you access to robust data solutions- easy to implement and use. Here’s how we work."
            }
          />
        </div>
      </div>
      <div className={styles.expWrapper}>
        <DFYExperts bannerHeader={'Claim Your Free Backlink Audit Now'} bannerDesc={'Let us help you wead out Toxic, Irrelevant backlinks from your website!'} />
      </div>

      {/* <DIYTestimonials data={Testimonial} /> */}
      <DIYClients data={TestimonialsDataPBN} verticalSpace />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};
export default PNBLinks;
