import styles from './styles.module.scss';
import Container from '../../Shared/Container';
import PrimaryButton from '../../Shared/Buttons/Primary';
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';

const Data = Array.from({ length: 3 })

function BlogsHero() {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  return (
    <div className={styles.wrapper}>
      <img className={styles.leftAbs} src={'/images/i_blogs_illus_one.png'} alt={'carousel item'} />
      <img className={styles.middleAbs} src={'/images/i_blogs_illus_two.png'} alt={'carousel item'} />
      <img className={styles.rightAbs} src={'/images/i_blogs_illus_three.png'} alt={'carousel item'} />
      <Container>
        <div className={styles.contentWrapper}>
          <div className={styles.headerText}>
            Learn Top Strategies for Online Visibility <span className={styles.highlight}> Through Our Blogs</span>

          </div>

          <div className={styles.blocksWrapper}>
            <div className={styles.blocks}>
              <h3>
                1500+
              </h3>
              <p>
                Clients served              </p>
            </div>
            <div className={`${styles.blocks} ${styles.nonZero}`}>
              <h3>
                30k+
              </h3>
              <p>
                Link each year              </p>
            </div>
            <div className={`${styles.blocks} ${!isMobile && styles.nonZero}`}>
              <h3>
                700+
              </h3>
              <p>
                SEO projects
              </p>
            </div>
            <div className={`${styles.blocks} ${styles.nonZero}`}>
              <h3>
                8+
              </h3>
              <p>
                total experience
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BlogsHero;
