import DFYClients from "../../../components/DFY/Clients";
import DFYDetails from "../../../components/DFY/Details";
import DFYExperts from "../../../components/DFY/Experts";
import DFYGrow from "../../../components/DFY/Grow";
import DFYHero from "../../../components/DFY/Hero";
import Footer from '../../../components/Footer';
import HomeTestimonial from "../../../components/Home/Testimonial";
import HomeFaqs from "../../../components/Home/Faqs";
import Container from "../../../components/Shared/Container";
import { useMediaQuery } from 'react-responsive';
import styles from "./styles.module.scss";

const Data = ['/images/ic_databox.svg', '/images/ic_business_insider.svg', '/images/ic_legalzoom.svg', '/images/ic_partner_one.svg', '/images/ic_segate.svg'];
const MobileData = ['/images/ic_databox.svg', '/images/ic_business_insider.svg', '/images/ic_legalzoom.svg', '/images/ic_partner_one.svg', '/images/ic_segate.svg', '/images/ic_segate.svg'];

const DominateData = [
    {
        header: "100% Real Website",
        desc: "We make sure all your backlinks are strong are No ordinary or low-grade backlinks from spammy directories. <br/>"+
        "• Ranking on local search results not only gets you lots of leads and direct customers, it can also help your website rank faster <br/>"+
        "• GEO Tagging Images and NAP optimization helps us achieve a better rank and active GMB profile",
        image: '/images/ic_complement_one.svg'
    },
    {
        header: "Rigorous Outreach Process",
        desc: "Our 15+ outreach methods with multiple touch points makes sure that we get a great backlink. <br/>"+
        "• Ranking on local search results not only gets you lots of leads and direct customers, it can also help your website rank faster <br/>"+
        "• GEO Tagging Images and NAP optimization helps us achieve a better rank and active GMB profile",
        image: '/images/ic_complement_two.svg'
    },
    {
        header: "Personalized topic selection",
        desc: "Our Content marketing specialists segregate and select topics that impacts directly. <br/>"+
        "• Ranking on local search results not only gets you lots of leads and direct customers, it can also help your website rank faster <br/>"+
        "• GEO Tagging Images and NAP optimization helps us achieve a better rank and active GMB profile",
        image: '/images/ic_complement_three.svg'
    },
    {
        header: "Buyer Persona Oriented",
        desc: "Before starting the content writing or link prospective process we create an ideal customer profile to target. <br/>"+
        "• Ranking on local search results not only gets you lots of leads and direct customers, it can also help your website rank faster <br/>"+
        "• GEO Tagging Images and NAP optimization helps us achieve a better rank and active GMB profile",
        image: '/images/ic_complement_four.svg'
    }
];

const DataClients = [
    {
        icon: '/images/ic_audit.svg',
        header: 'Link Prospecting',
        desc: `With your anchor texts and URL with us, we create a plan for contextual and relevant link outreach according to your niche and industry.`
    },
    {
        icon: '/images/ic_audit.svg',
        header: 'Content Creation',
        desc: `Our super team of content marketing specialists makes sure that we have the relevant guest posts and content for our prospected links.`
    },
    {
        icon: '/images/ic_audit.svg',
        header: 'Link Placement',
        desc: `Make sure to reach out to all prospect websites. Getting our guest posts published in time with specific anchor texts to have maximum impact.`
    }
]

const CustomOutreach = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 576px)'
    });
    return (
        <>
            <DFYHero
                header={'DFY CUSTOM OUTREACH'}
                desc={'Get high-authority permanent contextual backlinks from relevant and niche specific websites with our Done-For-You Custom Outreach service'}
            />

            {/* Details section */}
            <div className={styles.wrapper}>
                <div className={styles.bgColor} />
                <Container>
                    <div className={styles.contentWrapper}>
                        <div className={styles.partners}>
                            {isMobile ? MobileData.map((item, index) => {
                                return (
                                    <img className={styles.logoImage} src={item} alt={'logo'} />
                                )
                            }) : Data.map((item, index) => {
                                return (
                                    <img className={styles.logoImage} src={item} alt={'logo'} />
                                )
                            })}
                        </div>

                        <h1>
                            CUSTOM OUTREACH DONE 
                        </h1>
                        <h1>
                            FOR YOU
                        </h1>
                        <div className={styles.textBody}>
                            <div className={styles.left}>
                            Take care of reaching out to multiple high authority domains that suit specifically your nice. Our manual outreach process looks for strong, steady & high-traffic websites that will get you the right exposure & link value.
                
                            </div>
                            {/* <div className={styles.right}>
                                Technical SEO is one of the most underrated & effective measures to gain a critical ranking boost when you have a large website.
                                <br />
                                <br />
                                No website can stand without a strong backbone and that backbone is technical SEO. Technical SEO is the structure of your website without which everything else falls apart.
                                <br />
                                <br />
                                There are several files on your server — like .htaccess, robots.txt and sitemap.xml to name a few — that dictate how search engines access and index your content. We optimize and configure those files so that the search engine can easily crawn and index your website, which gives you an upper hand for ranking in the first page of Google.
                            </div> */}
                        </div>


                        <div className={styles.complement}>
                            SOME OTHER FEATURES THAT ARE COMPLEMENTARY TO THE SERVICE
                        </div>
                        <div className={styles.cardsSection}>
                            {DominateData.map((item, index) => {
                                return (
                                    <div className={styles.cards} key={index}>
                                        <img
                                            className={styles.icon}
                                            src={item.image}
                                            alt={"logo"}
                                        />

                                        <h2>{item.header}</h2>
                                        {!isMobile && <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                </Container>

            </div>

                                
            <DFYClients header={'HOW WE DO IT'} desc={'We will carry out a full-scale analysis of your SEO to find out the best strategy that will give you the most gains with niche-specific. Drive traffic to your website by achieving page 1 rankings in search engines for valuable keywords .'} data={DataClients} />
            {/*<HomeTestimonial />*/}
            <DFYExperts />
            {/*<HomeFaqs />*/}
            <Footer />
        </>
    )
};

export default CustomOutreach;