import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import SwitchButton from "../../Shared/Buttons/Switch";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Data = Array.from({ length: 3 });

const mainData = [
  {
    icon: "/images/sure_2.png",
    header: "BASIC ENHANCEMENT",
    price: "<b>$399</b> per link",
    desc: `Delivery 2 Links in 15 Days`,
    list: [
      "Premium PR Links (DF/NF): 1",
      "DA 50+ Authority Guest Posts (DF): 1",
      "DA 30+ Guest Posts (DF): 0",
      "DA 30+ Niche Edit Backlinks (DF): 0",
    ],
    listtwo: [
      "Niche Relevant",
      "Fast Indexing",
      "Content Included",
      "100% Genuine Websites",
    ],

    link: "/services/foundation-packages/",
  },
  {
    icon: "/images/ic_guest_two.svg",
    header: "STANDARD POWER-UP",
    price: "<b>$549</b> per link",
    desc: `Delivery 4 Links in 28 Days`,
    list: [
      "Premium PR Links (DF/NF): 1",
      "DA 50+ Guest Posts (DF): 1",
      "DA 30+ Guest Posts (DF): 1",
      "DA 30+ Niche Edit Backlinks (DF): 1",
    ],
    listtwo: [
      "Niche Relevant",
      "Fast Indexing",
      "Content Included",
      "100% Genuine Websites",
    ],

    link: "/services/best-pr-distribution/",
  },
  {
    icon: "/images/ic_turbo.svg",
    header: "PREMIUM TURBOCHARGE",

    price: "<b>$899</b> per link",
    desc: `Delivery 7 Links in 28 Days`,
    list: [
      "Premium PR Links (DF/NF): 2",
      "DA 50+ Authority Guest Posts (DF): 2",
      "DA 30+ Guest Posts (DF): 2",
      "DA 30+ Niche Edit Backlinks (DF): 1",
    ],
    listtwo: [
      "Niche Relevant",
      "Fast Indexing",
      "Content Included",
      "100% Genuine Websites",
    ],

    link: "/services/advanced-local-citation-service",
  },
  {
    icon: "/images/ic_chess.svg",
    header: "ULTIMATE DOMINANCE",
    price: "<b>$1,299</b>  Per link",
    desc: `Delivery 12 Links in 28 Days`,
    list: [
      "Premium PR Links (DF/NF): 3",
      "DA 50+ Authority Guest Posts (DF): 3",
      "DA 30+ Guest Posts (DF): 3",
      "DA 30+ Niche Edit Backlinks (DF): 2",
    ],

    listtwo: [
      "Niche Relevant",
      "Fast Indexing",
      "Content Included",
      "100% Genuine Websites",
    ],

    link: "/services/advanced-local-citation-service",
  },
];

function DIYBacklinkPackages() {
  return (
    <Container>
      <div className={styles.sureSection}>
        <div className={styles.headerSection}>
          <h5>Buy packages</h5>
        </div>

        <div className={styles.cardsSection}>
          {mainData.map((item, index) => {
            return (
              <div className={styles.cards}>
                <img
                  className={styles.logoImage}
                  src={item.icon}
                  alt={"logo"}
                />
                <h2 dangerouslySetInnerHTML={{ __html: item.header }}></h2>
                <p>{item.desc}</p>

                <div
                  className={styles.buttonCont}
                  dangerouslySetInnerHTML={{ __html: item.price }}
                ></div>
                <hr />
                <ul>
                  {item.list?.map((anch) => (
                    <li className={styles.highlight}>{anch}</li>
                  ))}
                </ul>
                <hr />
                <ul>
                  {item.listtwo?.map((anch) => (
                    <li className={styles.highlight}>{anch}</li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export default DIYBacklinkPackages;
