import AboutUsHero from "../../components/AboutUs/Hero";
import AboutUsValues from "../../components/AboutUs/Values";
import DFYClients from "../../components/DFY/Clients";
import DFYDetails from "../../components/DFY/Details";
import DFYExperts from "../../components/DFY/Experts";
import DFYGrow from "../../components/DFY/Grow";
import DFYHero from "../../components/DFY/Hero";
import Footer from '../../components/Footer';
import HomeTestimonial from "../../components/Home/Testimonial";
import HomeFaqs from "../../components/Home/Faqs";

const DFY = () => {
    return (
        <>
            <DFYHero />
            <DFYDetails />
            <DFYGrow />
            <DFYClients />
            <HomeTestimonial />
            <DFYExperts />
            <HomeFaqs />
            <Footer />
        </>
    )
};

export default DFY;