import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import { Link } from "react-router-dom";

const DIYSure = ({ data, heading, subHeading, ctaLink }) => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <h4>{heading}</h4>
        {subHeading && <p>{subHeading}</p>}
        <div className={styles.pricingList}>
          {data.map((item, index) => {
            return (
              <div className={styles.card}>
                <img className={styles.icon} src={item.icon} alt={"logo"} />
                <div className={styles.price}>{item.head}</div>
                <div className={styles.desc}>{item.sub}</div>

                <Link to={ctaLink ? ctaLink : "/contact-us"}>
                  <button>Get started</button>
                </Link>
                <hr />
                <ul>
                  {item.list.map((item, index) => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};
export default DIYSure;
