import Container from "../../Shared/Container";
import styles from './styles.module.scss';

function CaseInternalSolution() {
    return (
        <div className={styles.wrapper}>
            <Container>
                <div className={styles.wrapperCont}>
                    <h2>
                        how we helped them solve this issue
                    </h2>
                    <div className={styles.mainCont}>
                        <div className={styles.left}>
                            <p>
                            Lorem ipsum dolor sit amet consectetur. Ipsum purus lectus pretium dolor fringilla gravida interdum. Dui a senectus ut senectus sed. Orci non pulvinar ac sed quis. Egestas nam amet eu bibendum vitae sed id eleifend.Lorem ipsum dolor sit amet consectetur. Ipsum purus lectus pretium dolor fringilla gravida interdum. Dui a senectus ut senectus sed. Orci non pulvinar ac sed quis. Egestas nam amet eu bibendum vitae sed id eleifend.
                            </p>

                            <div className={styles.steps}>
                                <div className={styles.items}>
                                    <h3>
                                        Step 1
                                    </h3>
                                    <p>
                                        We crafted a complex trip and cargo algorithm
                                    </p>
                                </div>

                                <div className={`${styles.items} ${styles.bordered}`}>
                                    <h3>
                                        Step 2
                                    </h3>
                                    <p>
                                        We crafted a complex trip and cargo algorithm
                                    </p>
                                </div>

                                <div className={styles.items}>
                                    <h3>
                                        Step 3
                                    </h3>
                                    <p>
                                        We crafted a complex trip and cargo algorithm
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={styles.right}>

                        </div>
                    </div>
                </div>
            </Container>
        </div>

    );
}

export default CaseInternalSolution;
