import React, { useState, useEffect } from 'react';
import BlogsHero from "../../components/Blogs/Hero";
import BlogsListings from "../../components/Blogs/Listing";
import Footer from "../../components/Footer";
import HomeFaqs from "../../components/Home/Faqs";
import axios from 'axios';

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
        try {
            const response = await axios.get('https://www.rank-jacker.com/wp-json/wp/v2/posts', { crossdomain: true });
            setPosts(response.data);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setPosts([]);
        }
    };

    fetchPosts();
}, []);
  return (
    <>
      <BlogsHero />
      <BlogsListings featured={posts[0]} topThree={posts.slice(1, 4)} middleFive={posts.slice(4, 9)} allBlogs={posts.slice(9)} />
      <Footer />
    </>
  );
};

export default Blogs;
