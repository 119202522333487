import DFYClients from "../../../components/DFY/Clients";
import DFYDetails from "../../../components/DFY/Details";
import DFYExperts from "../../../components/DFY/Experts";
import DFYGrow from "../../../components/DFY/Grow";
import DFYHero from "../../../components/DFY/Hero";
import Footer from "../../../components/Footer";
import HomeTestimonial from "../../../components/Home/Testimonial";
import HomeFaqs from "../../../components/Home/Faqs";
import Container from "../../../components/Shared/Container";
import { useMediaQuery } from "react-responsive";
import styles from "./styles.module.scss";
import DFYAppointments from "../../../components/DFY/Appointment";
import DFYStages from "../../../components/DFY/Stages";
import { StagesData } from "../../../cont/Stages";
import { ManaagedSEOData } from "../../../cont/ClientData";
import LocalSEOWorks from "../../../components/LocalSEO/Works";
import DIYClients from "../../../components/DIY/Clients";

const Data = [
  "/images/ic_databox.svg",
  "/images/ic_business_insider.svg",
  "/images/ic_legalzoom.svg",
  "/images/ic_partner_one.svg",
  "/images/ic_segate.svg",
];
const MobileData = [
  "/images/ic_databox.svg",
  "/images/ic_business_insider.svg",
  "/images/ic_legalzoom.svg",
  "/images/ic_partner_one.svg",
  "/images/ic_segate.svg",
  "/images/ic_segate.svg",
];

const DominateData = [
  {
    header: "Hands Free SEO",
    desc: "With Google rolling new updates every other day, SEO gets messy. Stay free, save time and let us do the heavy lifting.",
    image: "/images/i_learn_one.png",
  },
  {
    header: "Customized Plan",
    desc: "Our campaign strategy changes with every project. Deep competitive analysis and research on your sales process helps us to beat your competition.",
    image: "/images/i_learn_two.png",
  },
  {
    header: "White Label SEO",
    desc: "We do the hard work and bring results, but make you the superstar in front of your client. We keep all our work 100% confidential.",
    image: "/images/i_learn_three.png",
  },
  {
    header: "Result Driven",
    desc: "We make sure our 360 degree hands free managed SEO gets you the best of results, 100% guaranteed if not we give you a complete refund, no question asked.",
    image: "/images/i_learn_four.png",
  },
];

const FaqData = {
  Generals: [
    {
      question: "What Exactly Does ‘Fully Managed SEO’ Mean?",
      answer:
        "Fully managed SEO is a term given to providing SEO solutions, and everything that comes with it, on one plate. It covers services like outreach, guest posting, reports, backlinks, and everything related to SEO.",
    },
    {
      question: "Is There A Contract Involved?",
      answer:
        "There is no contract involved, however, you need to give us a quarter to give you results.",
    },
    {
      question:
        "Can I Talk To The Project Manager Before I Decide To Purchase Fully Managed SEO Services?",
      answer: "Yes, you can, please Contact Us.",
    },
    {
      question:
        "How Long It Will Take To See The Results After Purchasing Fully Managed SEO Services?",
      answer:
        "It depends upon the website, niche, budget, and as well as competition. Usually, it takes 6 months to see a considerable difference in the rankings.",
    },
    {
      question:
        "If I Cancel Fully Managed SEO Services, Will My Rankings Go Down?",
      answer:
        "After cancellation, our hard work will thrive for a very long term. We will provide proper documentation of our efforts. As SEO is a process and if no efforts are put into SEO, then the rank will go down gradually.",
    },
    {
      question: "What If I Want To Keep Control Of My Website Content?",
      answer:
        "You can request us to take this into consideration while ordering. If information is provided then we take approvals of every piece of content that is to be published on your website.",
    },
    {
      question: "Can I Choose What I Want?",
      answer:
        "Yes, you can pick and choose according to your budget. Please check out our pricing area above to know more.",
    },
  ],
};
const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO is hands down the best investment we've made for our company's digital marketing strategy. Their team's personalized approach and consistent efforts have helped us dominate our niche market. We couldn't be happier with the results!",
    h3: "David Mark",
    designation: "Founder",
    personSrc: "/images/David Mark.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We've tried several SEO agencies in the past, but none have delivered the same level of results as Rank-Jacker SEO. Their managed SEO service has significantly boosted our website's search engine rankings, leading to a noticeable increase in organic traffic and revenue. Trustworthy and highly recommended!",
    h3: "Emily White",
    designation: "Director of Marketing",
    personSrc: "/images/Emily White.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Choosing Rank-Jacker SEO for our SEO needs was one of the best decisions we've made. Their team's proactive approach and attention to detail have significantly improved our website's performance in search results. We're extremely satisfied with the results they've delivered.",
    h3: "Laura",
    designation: "Marketing Director",
    personSrc: "/images/Laura.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO has exceeded our expectations in every way possible. Their managed SEO service has helped us outrank our competitors and establish a strong online presence within our industry. Their dedication to client success is truly commendable.",
    h3: "Mark Thomas",
    designation: "CEO",
    personSrc: "/images/Mark Thomas.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We've been working with Rank-Jacker SEO for over a year now, and the results speak for themselves. Their team's expertise and commitment to driving results have significantly improved our website's search visibility and traffic. We highly recommend their services to anyone looking to elevate their online presence.",
    h3: "Dana",
    designation: "Digital Marketing Manager",
    personSrc: "/images/i_client_one.png",
  },
];

const ManaagedSEO = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <>
      <DFYHero
        header={"DFY FULLY MANAGED SEO SERVICE"}
        desc={
          "Stop letting your customers find your competitors. 70-80% of search engine users are only focusing on the organic results i.e., they skip ads and directly click on the website that ranks organically."
        }
        descTwo={
          "Request An Audit Today, to verify your website is Search Engine Ready!!"
        }
      />

      {/* Details section */}
      <div className={styles.wrapper}>
        <div className={styles.bgColor} />
        <Container>
          <div className={styles.contentWrapper}>
            <div className={styles.partners}>
              {isMobile
                ? MobileData.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })
                : Data.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })}
            </div>
          </div>
        </Container>
        <DFYAppointments
          header={
            "Try Rank Jacker's Managed SEO Services For Strategic SEO Excellence"
          }
          desc={
            "User experience is at the heart of Google since its creation, and now the intensity of its importance has grown more with time. \n\n With changing Google’s ranking algorithm from time to time something which has been constant is Google’s priority to user experience and dwell time. \n\nTherefore a whole new concept has been introduced SXO. \n\nSXO can be defined as the set of techniques for optimizing the user experience on search engines. It’s an increasingly common notion these days which corresponds to the quality of the user’s experience in digital or physical environments. \n\nBut does this mean you only focus on this? ? we lay down our complete step-by-step process which takes you from ZERO to the HERO stage."
          }
          ctaLink={"https://calendly.com/bookadiscoverycall/15"}
        />

        <DFYStages
          header={"OUR COMPLETE STEP-BY-STEP PROCESS FOR MANAGED SEO SERVICE"}
          data={StagesData}
        />
        <div className={styles.dominate}>
          <Container>
            <div className={styles.headerSection}>
              <h1>DOMINATE YOUR MARKET</h1>
              <h3>
                With our DFY Managed SEO get a hands free experience to get your
                website ranked on the first page of Google, leaving you to have
                more time for doing what you are good at i.e, running your
                business.
              </h3>
            </div>

            <div className={styles.cardsSection}>
              {DominateData.map((item, index) => {
                return (
                  <div className={styles.cards}>
                    {isMobile && (
                      <img
                        className={styles.logoImage}
                        src={"/images/ic_risks.svg"}
                        alt={"logo"}
                      />
                    )}
                    <h2>{item.header}</h2>
                    <p>{item.desc}</p>
                    {!isMobile && (
                      <img
                        className={styles.bottomImage}
                        src={item.image}
                        alt={"logo"}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </Container>
        </div>
      </div>

      <DFYClients
        data={ManaagedSEOData}
        ctaLink={
          "https://forms.zohopublic.in/rankmakeup/form/ManagedSEOServiceForm/formperma/xD3vQReHd6_-bC1FlVLX-fhK3UJGRXF9raq3TYp_x5w"
        }
      />
      <LocalSEOWorks />
      <DIYClients data={Testimonial} verticalSpace={true} />
      <DFYExperts />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};

export default ManaagedSEO;
