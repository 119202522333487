import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

function DIYHero({ bgImage, title, subtitle, cta2Link, cta1Link }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.top}>Do it yourself</div>
          <div
            className={styles.header}
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <div className={styles.subtitle}>{subtitle}</div>

          {!isMobile && (
            <div className={styles.buttonWrapper}>
              <Link to={cta1Link ? cta1Link : "/contact-us"}>
                <PrimaryButton buttonText={"Contact us"} />
              </Link>
              <Link to={cta2Link ? cta2Link : "/contact-us"}>
                <PrimaryButton
                  buttonText={"Talk with expert"}
                  outlined={true}
                />
              </Link>
            </div>
          )}
        </div>
        <div className={styles.right}>
          <img
            className={styles.heroIllus}
            src={bgImage}
            alt={"carousel item"}
          />
        </div>
        {isMobile && (
          <div className={styles.buttonWrapper}>
            <Link to={cta1Link ? cta1Link : "/contact-us"}>
              <PrimaryButton buttonText={"Contact us"} />
            </Link>
            <Link to={cta2Link ? cta2Link : "/contact-us"}>
              <PrimaryButton buttonText={"Talk with expert"} outlined={true} />
            </Link>
          </div>
        )}
      </div>
    </Container>
  );
}

export default DIYHero;
