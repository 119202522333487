import Container from '../../Shared/Container';
import styles from './styles.module.scss';

const CaseStudiesHero = () => {
    return (
        <div className={styles.wrapper}>
            <Container>
                <div className={styles.content}>

                    <div className={styles.mainText}>
                        Case studies. Transforming
                        Challenges into <span className={styles.highlighted}>Real-World Solutions</span>
                    </div>
                    <p>
                        Lörem ipsum supratt vasa explainer i tresade kronat plases, fast senar. tresade kronat plases, fast senar.
                    </p>
                    <img className={styles.leftHand} src={'/images/i_case_study_hero.png'} alt={'banner'} />

                </div>
            </Container>
        </div>
    );
}

export default CaseStudiesHero;
