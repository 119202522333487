import Container from "../../Shared/Container";
import styles from "./styles.module.scss";

const AboutUsHero = () => {
    return (
        <Container>
            <div className={styles.wrapper}>
                <h1>
                Strategic SEO Mastery, Tailored to Fuel Your Business Objectives
                </h1>
                <p>
                Automation Redefined: No room for clichés. SEO agencies trust us for unrivalled delivery, scalable solutions, and a commitment that speaks louder than results.

                </p>

                <p className={styles.italicText}>
                    Scroll down
                </p>

                <img className={styles.vector} src={'/images/ic_about_vector.png'} alt={'logo'} />
            </div>
        </Container>
    )
};

export default AboutUsHero;