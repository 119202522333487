export const ManaagedSEOData = [
  {
    icon: "/images/ic_audit.svg",
    header: "Discover",
    desc: `During our first call, we explain how we can help you with your project and scale your business. If we are a good fit we schedule a second call.`,
  },
  {
    icon: "/images/cl_one.png",
    header: "Strategy & Plan",
    desc: `In this call, we narrow down to your project goals, scope, plan, cost, and timeline of your project. If you agree, we provide you with the strategy and mind-map.`,
  },
  {
    icon: "/images/cl_two.png",
    header: "Agreement & Execution",
    desc: `Once the agreement and initial payment are done, we start working on your project in accordance with the strategy and mind-map made.`,
  },
  {
    icon: "/images/cl_three.png",
    header: "Deliver",
    desc: `We deliver your project within the timeline promised and more importantly deliver you the project with your desired results. Our entire process is always 100% transparent.`,
  },
  {
    icon: "/images/cl_four.png",
    header: "After Support",
    desc: `Throughout our entire process, feel free to connect with us anytime, 24X7.`,
  },
];

export const PBNData = [
  {
    icon: "/images/ic_audit.svg",
    header: "Zero Footprints",
    desc: `Footprints free domains using “A”, “B”, “C” Class IPs with separate Hosting & Disntict persona, No interlinking`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Design/Appearance",
    desc: `Genuine Looking Sites with Distinct Theme, Feel & Design`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Optimized Blog",
    desc: `One External Link per Blog from uniquely written content posts with relevant/varied KWs in the Title. H tags & Contextually placed Anchor Text.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Link Juice",
    desc: `Constantly updated PBN Network to keep OBL’s under safe limits`,
  },
];

export const GuestData = [
  {
    icon: "/images/ic_audit.svg",
    header: "Link Prospecting ",
    desc: `With your anchor texts and URL with us, we create a plan for contextual and relevant link outreach according to your niche and industry.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Content creation",
    desc: `Our super team of content marketing specialists makes sure that we have relevant guest post and content for our prospected links`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Link placement",
    desc: `Make sure to reach out at all prospect websites. getting our guest post published on time with`,
  },
];

export const HaroData = [
  {
    icon: "/images/haro_1.png",
    header: "First thing first — SALES",
    desc: `Haro Links helps in bringing quality traffic as well as increase your sales. This is because when you’re quoted in a story, you’ll be able to include a link to your website. And when people click on that link, they’ll be able to learn more about your business or product. And since the links will be placed on high authoritive sites people will be more likely to do business with you.`,
  },
  {
    icon: "/images/haro_2.png",
    header: "Postive Impact on SEO",
    desc: `Getting backlinks is a major factor in SEO, letting the crawlers understand that your websites is worth others to visit and when you are getting backlinks from high DA sites, google appreciates it and as a result boosts up your ranking.`,
  },
  {
    icon: "/images/haro_three.png",
    header: "Increased brand awareness and authority",
    desc: `The publication sites are usually of a nature where audience has a trust on. So getting quotes in stories of  big publication sites increases your brand awareness, authority and also leaves behind an imprint in the audience’s mind.`,
  },
];

export const CitationData = [
  {
    icon: "/images/ic_audit.svg",
    header: "ENHANCED VISIBILITY",
    desc: `The presence of your business on prime listings ensures you don't miss when your prospect searches for your business category. Positive Reviews on Local Directories generates further traction and easy conversion for your sales team.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "FAST TURN AROUND",
    desc: `We maintain a 12 day Turnaround, however it might take up to 3 weeks to complete the service. You also have an option to take up a 7 days fast delivery at a little extra charge.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "WHITE LABEL REPORTS",
    desc: `If you wish to resell our services, we can also prepare White Label reports for you to forward to your clients.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "AUTHORITY DIRECTORIES",
    desc: `Only trusted directories find a place on our list, we just don't like adding shady properties with no real following and likewise, our team keeps a tab while researching to add any property which has a live and growing community.`,
  },
];

export const TestimonialsData = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rohin and his team have done some amazing work for me. I used them to get some links for an affiliate site I was working on and they came through with some very high quality links that really moved the needle. The turnaround time is also very fast compared to other vendors.",
    h3: "Kasem Bajwa",
    designation: "Founder",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "I have purchased a few niche edits from Amit, He was very cooperative. I had some issues with one link and instantly told him the issue. He quickly gave a new link without asking any questions. Top-notch service!",
    h3: "MD Nizam Uddin",
    designation: "CEO",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "I ordered the PRO Package and they are very good links with strong metrics and good rankings/traffic so I am very happy with them.",
    h3: "Steven",
    designation: "Agency Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rankjacker is one of the best service providers here. You can't go wrong with any of his services!",
    h3: "Keith White",
    designation: "Link Outreach Manager",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Would highly recommend these guys for great service and delivering the product on time. Communication was outstanding and will use them again soon.",
    h3: "James",
    designation: "SEO Agency Manager",
    personSrc: "/images/i_client_one.png",
  },
];

export const TestimonialsDataNiche = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rohin and his team have done some amazing work for me. I used them to get some links for an affiliate site I was working on and they came through with some very high quality links that really moved the needle. The turnaround time is also very fast compared to other vendors.",
    h3: "Kasem Bajwa",
    designation: "Founder",
    personSrc: "/images/n-p-1.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "I have purchased a few niche edits from Amit, He was very cooperative. I had some issues with one link and instantly told him the issue. He quickly gave a new link without asking any questions. Top-notch service!",
    h3: "MD Nizam Uddin",
    designation: "CEO",
    personSrc: "/images/n-p-2.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "I ordered the PRO Package and they are very good links with strong metrics and good rankings/traffic so I am very happy with them.",
    h3: "Steven",
    designation: "Agency Owner",
    personSrc: "/images/n-p-3.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rankjacker is one of the best service providers here. You can't go wrong with any of his services!",
    h3: "Keith White",
    designation: "Link Outreach Manager",
  },
];

export const TestimonialsDataGuest = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "We’ve been using RankJacker for probably the last 4-5 years and have become part of the family. We’ve been looking for a provider to outsource, where you don’t have to look over to check that it’s going to be done in a quality fashion and billed correctly. I highly recommend going with them because he knows what he’s doing and trusts them with the work to deliver it on time, every time at the right price.",
    h3: "David Krauter",
    designation: "Founder",
    personSrc: "/images/gp-l-1.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "We are happy with the outcomes of the custom outreach campaign for the Pet niche. The placements are not only relevant to our business, but also mind-blowing terms of authority and organic search traffic.",
    h3: "Jack Troung",
    designation: "Business Owner",
    personSrc: "/images/gp-l-2.png",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Very fast placement. Links look great and the articles are well written. Looking forward to the results.",
    h3: "Brett Nordin",
    designation: "Outreach Expert",
    personSrc: "/images/gp-l-3.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Working as the sole SEO within our brand, link building is a huge area which I struggled to allocate sufficient time to. Amit and her team at RankJacker SEO have thus far worked wonders for me, with not just excellent communication and understanding of my objectives, but also fantastic link placements in well written articles on highly relevant sites. The results have been instantaneous! I’m very glad that I entrusted them with this work.",
    h3: "Matthew Martin",
    designation: "Head Of SEO",
    personSrc: "/images/gp-l-4.jpg",
  },
];

export const TestimonialsDataPBN = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "RankJacker is one of the most professional sellers with great services and A++ Grade communication. I saw movement from these links and for the price they're hard to beat.",
    h3: "DSteve",
    designation: "SEO Link Outreach Manager",
    personSrc: "/images/pbn-l-1.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Another nice job. Very honest PBN link seller. Looking forward to a bump in rankings. Thanks to Rankjacker SEO.",
    h3: "Ron Stone",
    designation: "Business Owner",
    personSrc: "/images/pbn-l-2.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rank Jacker delivered this gig promptly and as advertised. I'm very satisfied with this service and I'm going to use it again. Now I'm looking forward to seeing my keywords rankings after receiving these links. Thanks!",
    h3: "Garry",
    designation: "Agency Owner",
    personSrc: "/images/pbn-l-3.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Guys, what should I say?! I ordered this gig 3 times over the last 3 months, now the 4th time. I ranked a high selling KW on top 3 with a SV of 30k EXCLUSIVELY with these links here! They are beautiful beasts! Everyone who is looking for links, this seller here is the best one on Konker!",
    h3: "PSBrain",
    //   designation: "Head Of SEO",
    personSrc: "/images/pbn-l-4.jpg",
  },
];

export const TestimonialsDataFound = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "The team at RankJacker is great and super responsive to my needs any time of the day or night. I rate them as super professional, skilled & reasonably resourceful when it comes to SEO.",
    h3: "Chris R.",
    designation: "Founder",
    personSrc: "/images/fl-p-1.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Another nice job. Very honest PBN link seller. Looking forward to a bump in rankings. Thanks to Rankjacker SEO.",
    h3: "Joe C. Harris",
    //   designation: "Business Owner",
    personSrc: "/images/fl-p-2.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rank Jacker always over-delivers and does a fantastic job! I highly recommend them.",
    h3: "Chris Wright",
    designation: "Agency Owner",
    personSrc: "/images/fl-p-3.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "I'm really impressed by the RankJacker team. Not only do they deliver all the backlinks on time, their customer service is top-notch. They also provide updates when the content is indexed and the links are registered on SEMRush as well. Legit stuff.",
    h3: "Yvonne",
    designation: "Agency Owner",
    personSrc: "/images/i_client_one.png",
  },
];

export const TestimonialsDataLinks = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rank-Jacker SEO's buy backlinks service has truly transformed our website's visibility. Their strategic approach and high-quality backlinks have significantly boosted our search engine rankings, resulting in increased organic traffic and leads.",
    h3: "Samantha Johnson",
    designation: "CEO",
    personSrc: "/images/bl-l-1.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "We were skeptical at first, but Rank-Jacker SEO proved us wrong. Their buy backlinks service delivered impressive results beyond our expectations. Our website now ranks higher for competitive keywords, driving more targeted traffic and conversions",
    h3: "Mark Smith",
    designation: "Founder",
    personSrc: "/images/bl-l-2.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rank-Jacker SEO's buy backlinks service is worth every penny. They provided us with relevant, authoritative backlinks that improved our website's credibility and search engine ranking. Highly recommend their services to anyone looking to enhance their online presence.",
    h3: "Amanda White",
    designation: "Marketing Manager",
    personSrc: "/images/bl-l-4.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Thanks to Rank-Jacker SEO, our website has experienced a significant increase in organic traffic and leads. Their buy backlinks service is top-notch, delivering tangible results and helping us stay ahead of the competition. Excellent work!",
    h3: "David Thompson",
    designation: "Director of Marketing",
    personSrc: "/images/bl-l-3.jpg",
  },
];

export const TestimonialsDataHaro = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rank-Jacker SEO's HARO links have been a game-changer for our website's visibility. Highly recommend their service to anyone serious about boosting their SEO efforts.",
    h3: "John Doe",
    designation: "CEO of XYZ Company",
    personSrc: "/images/hl-l-2.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Thanks to Rank-Jacker SEO's HARO links, our organic traffic has skyrocketed. Their service is top notch and worth every penny",
    h3: "Jane Smith",
    designation: "Marketing Manager at ABC Corporation",
    personSrc: "/images/hl-l-1.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rank-Jacker SEO's HARO link-building service exceeded our expectations. Our search engine rankings have noticeably improved since we started working with them",
    h3: "Mark Johnson",
    designation: "Founder of DEF Enterprises",
    personSrc: "/images/hl-l-3.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "We've tried several SEO services before, but none have delivered results like Rank-Jacker SEO's HARO links. Our website's authority has increased significantly, thanks to their expertise.",
    h3: "Sarah Brown",
    designation: "Director of Marketing at GHI Solutions",
    personSrc: "/images/hl-l-4.jpg",
  },
];

export const TestimonialsDataLocal = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rank-Jacker SEO has truly transformed our business with their local citation service. Our online visibility skyrocketed, bringing in a flood of new customers from our local area. Highly recommended!",
    h3: "John Smith",
    //   designation: "CEO of XYZ Company",
    personSrc: "/images/John Smith.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Thanks to Rank-Jacker SEO's local citation service, our small boutique saw a significant increase in foot traffic and online inquiries. Their attention to detail and effective strategies have made a tangible difference in our business.",
    h3: "Sarah Johnson",
    //   designation: "Marketing Manager at ABC Corporation",
    personSrc: "/images/Sarah Johnson.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rank-Jacker SEO's local citation service has been a game-changer for our plumbing company. We're now ranking higher in local searches, and our phone hasn't stopped ringing with new leads. Couldn't be happier with the results!",
    h3: "Michael Thompson",
    //   designation: "Founder of DEF Enterprises",
    personSrc: "/images/Michael Thompson.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "We were struggling to attract customers to our dental clinic until we discovered Rank-Jacker SEO's local citation service. Since then, our appointment books have been consistently full. Thank you for helping our practice thrive!",
    h3: "Dr. Emily Parker",
    designation: "Dental Clinic Owner",
    personSrc: "/images/Dr. Emily Parker.jpg",
  },
];

export const TestimonialsDataPress = [
  {
    brand: "/images/ic_client_dark.svg",
    p: "Rank-Jacker SEO's press release service skyrocketed our visibility! Within days of distribution, our company saw a significant surge in media coverage and website traffic. Highly recommended!",
    h3: "Jane Doe",
    designation: "CEO",
    personSrc: "/images/Jane Doe.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "We've seen a noticeable increase in website traffic and inquiries since partnering with 'Rank-Jacker SEO' for our press releases. Their team's expertise in storytelling and media relations has been pivotal in elevating our brand presence.",
    h3: "Alex Turner",
    designation: "CEO",
    personSrc: "/images/Alex Turner.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "Choosing 'Rank-Jacker SEO' for our press release needs was one of the best decisions we've made. Their team's professionalism and attention to detail have helped us secure valuable media placements and expand our reach exponentially.",
    h3: "Mark Roberts",
    designation: "CEO",
    personSrc: "/images/Mark Roberts.jpg",
  },
  {
    brand: "/images/ic_client_dark.svg",
    p: "We've seen remarkable results since partnering with 'Rank-Jacker SEO' for our press releases. Their attention to detail and strategic approach have helped us garner media attention and attract new customers consistently.",
    h3: "Michael Brown",
    designation: "Founder",
    personSrc: "/images/Michael Brown.jpg",
  },
];
