import DIYHero from "../../../components/DIY/Hero";
import DIYDetails from "../../../components/DIY/Details";
import DIYTestimonials from "../../../components/DIY/Testimonials";
import HomeFaqs from "../../../components/Home/Faqs";
import Footer from "../../../components/Footer";
import Details from "../../../components/DFY/Details";
import styles from "../styles.module.scss";
import Container from "../../../components/Shared/Container";
import PrimaryButton from "../../../components/Shared/Buttons/Primary";
import SwitchButton from "../../../components/Shared/Buttons/Switch";
import { useMediaQuery } from "react-responsive";
import SureSection from "../../../components/ContentStrategy/SureSection";
import DFYClients from "../../../components/DFY/Clients";
import { Link, useNavigate } from "react-router-dom";
import DIYClients from "../../../components/DIY/Clients";
import PricingOptions from "../../../components/DIY/PricingOptions";
import {
  GuestData,
  TestimonialsData,
  TestimonialsDataGuest,
} from "../../../cont/ClientData";
import DFYExperts from "../../../components/DFY/Experts";
import EBook from "../../../components/Shared/EBook";
import EBookSingle from "../../../components/Shared/EBook/SingleEbook";

const logoList = [
  "/images/gp-logo-1.svg",
  "/images/gp-logo-2.svg",
  "/images/gp-logo-3.svg",
  "/images/gp-logo-4.svg",
  "/images/gp-logo-5.svg",
  "/images/gp-logo-6.svg",
  "/images/gp-logo-7.svg",
  "/images/gp-logo-2.svg",
  "/images/gp-logo-3.svg",
  "/images/gp-logo-4.svg",
];

const logoListMobile = [
  "/images/gp-logo-1.svg",
  "/images/gp-logo-2.svg",
  "/images/gp-logo-3.svg",
  "/images/gp-logo-4.svg",
  "/images/gp-logo-5.svg",
  "/images/gp-logo-6.svg",
  "/images/gp-logo-7.svg",
];

const SureData = [
  {
    icon: "/images/ic_niche_two.svg",
    header: "Niche Edit",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
  {
    icon: "/images/ic_guest_two.svg",
    header: "Guest Posting",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
  {
    icon: "/images/ic_traffic.svg",
    header: "Increases traffic",
    desc: `With a dependable team of SEO Experts to help your automate your SEO`,
  },
];

const DominateData = [
  {
    icon: "/images/speaker.svg",
    header: "High-Quality Links",
    desc:
      "We make sure all your backlinks are strong by ensuring that all domains have 100 – 10,000+ referring domains. <br/><br/>" +
      "Great backlinks right in your niche for the most impact.\n" +
      "Link placement within the content on 90% of the backlinks\n" +
      "Link selection on the basis of relevance, traffic, and credibility",
  },
  {
    icon: "/images/blog.svg",
    header: "Custom Outreach",
    desc:
      "Segregating and classifying our PBN network helps us serve you backlinks for your niche, location, and industry.<br/><br/>" +
      "\n" +
      "Great backlinks right in your niche for the most impact\n" +
      "Our outreach team manually negotiates with bloggers\n" +
      "Blogs which we reach out to and the content we produce are highly relevant to your niche and brand",
  },
  {
    icon: "/images/link.svg",
    header: "Scalable Approach",
    desc:
      "Our strategy and execution can be scaled when needed as we only work with a lot of guest posting networks.<br/><br/>" +
      "\n" +
      "Permanent Placement of links makes your brand scale easily.<br/><br/>" +
      "\n" +
      "Based on your keyword difficulty and competition you can choose from different packages to scale better.",
  },
  {
    icon: "/images/wheel.svg",
    header: "Result Driven",
    desc:
      "Handpicking each and every spot and link placement on high-traffic and high-authority real websites get 100% results.<br/><br/>" +
      "\n" +
      "Our outreach strategy is safe and legitimate, a white-hat strategy where links get us the best results possible.\n" +
      "Reaching out to people interested in your service or product.<br/>" +
      "We only submit content to 100% real authority blogs that are ranking in Google with high organic traffic.",
  },
];
const FaqData = {
  Generals: [
    {
      question: "What Does Blogger Outreach Do For My Business?",
      answer:
        "Blogger outreach is the process of reaching out to the relevant bloggers, which we can further use for guest posting, backlinking, and placements to increase the traffic on the client’s website.",
    },
    {
      question: "How Blogger Outreach Is Helpful?",
      answer:
        "Blogger outreach is a process in which you build a quality inventory for your clients. It is helpful for businesses because this outreach helps in building relevant and very targeted traffic for the client’s website.",
    },
    {
      question: "What Would Be The Quality Of Content?",
      answer:
        "Our team of experienced writers makes sure that your guest posting or any placement has articles that are free from grammatical errors and portray a good brand image.",
    },
    {
      question: "What Is Outreach Strategy?",
      answer:
        "The outreach strategy is to deliver constant content or message to the targeted audience through various websites and placements, which then drive relevant traffic to the website.",
    },
    {
      question: "What Are Outreach Activities?",
      answer:
        "The activities done for reaching out to bloggers are email outreach, establishing relationships with influencers, brand promotion via blogging, unique content creation, PR blogging, etc. anything that gives the desired exposure.",
    },
    {
      question: "What Are The Benefits Of Outreach Programs?",
      answer:
        "It increases brand visibility and gives exposure to the relevant niche audience. This helps in diversifying the revenue streams, developing relations, and contributing to the overall sustainability of the business.",
    },
  ],
};

const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We’ve been using RankJacker for probably the last 4-5 years and have become part of the family. We’ve been looking for a provider to outsource, where you don’t have to look over to check that it’s going to be done in a quality fashion and billed correctly. I highly recommend going with them because he knows what he’s doing and trusts them with the work to deliver it on time, every time at the right price.",
    h3: "David Krauter",
    designation: "Founder",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We are happy with the outcomes of the custom outreach campaign for the Pet niche. The placements are not only relevant to our business, but also mind-blowing terms of authority and organic search traffic.",
    h3: "Jack Troung",
    designation: "Business Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Very fast placement. Links look great and the articles are well written. Looking forward to the results.",
    h3: "Brett Nordin",
    designation: "Outreach Expert",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Working as the sole SEO within our brand, link building is a huge area which I struggled to allocate sufficient time to. Amit and her team at RankJacker SEO have thus far worked wonders for me, with not just excellent communication and understanding of my objectives, but also fantastic link placements in well written articles on highly relevant sites. The results have been instantaneous! I’m very glad that I entrusted them with this work.",
    h3: "Matthew Martin",
    designation: "Head Of SEO",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "RankJacker has been tremendous to work with. They over deliver and make great time on my orders. The links are real and more relevant than I would have expected. Love that they do month to month for packages, but as it turns out, I couldn't imagine not using them into perpetuity. Thanks guys!",
    h3: "Christopher Glazer",
    designation: "Director",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Working with RankJacker SEO as my link building partner has been a great experience. They listen to you and understand what you expect. No wonder, they are able to deliver results you appreciate.",
    h3: "Cody",
    designation: "SEO Team Lead",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "RankJacker SEO's guest post service helped my website climb the search engine rankings faster than I ever imagined. The quality of the posts and the authority of the sites they were published on made a significant difference in our online visibility.",
    h3: "Sarah T.",
    designation: "CEO",
    personSrc: "/images/i_client_one.png",
  },
];
const Pricing = [
  {
    price: "Basic",
    desc: "Ahrefs Traffic 500 To 1000",
    list: [
      "Moz DA- 10",
      "Ahrefs DR 10+",
      "100% Manual Outreach",
      "Permanent Do-Follow Backlink",
      "1 Anchor Text/Target URL",
      "Contextual Link",
      "Link Recovery Support",
      "Spam Free Links",
      "700 Words Article",
    ],
  },
  {
    price: "Silver",
    desc: "Ahrefs Traffic 1000 To 3000",
    list: [
      "Moz DA- 20",
      "Ahrefs DR 20+",
      "100% Manual Outreach",
      "Permanent Do-Follow Backlink",
      "1 Anchor Text/Target URL",
      "Contextual Link",
      "Link Recovery Support",
      "Spam Free Links",
      "700 Words Article",
    ],
  },
  {
    price: "Gold",
    desc: "Ahrefs Traffic 3000 To 5000",
    list: [
      "Moz DA- 30",
      "Ahrefs DR 30+",
      "100% Manual Outreach",
      "Permanent Do-Follow Backlink",
      "1 Anchor Text/Target URL",
      "Contextual Link",
      "Link Recovery Support",
      "Spam Free Links",
      "700 Words Article",
    ],
  },
  {
    price: "Platinum",
    desc: "Ahrefs Traffic 5000 To 10000",
    list: [
      "Moz DA- 40",
      "Ahrefs DR 40+",
      "100% Manual Outreach",
      "Permanent Do-Follow Backlink",
      "1 Anchor Text/Target URL",
      "Contextual Link",
      "Link Recovery Support",
      "Spam Free Links",
      "700 Words Article",
    ],
  },
  {
    price: "Enterprise",
    desc: "Ahrefs Traffic 10000+",
    list: [
      "Moz DA- 50",
      "Ahrefs DR 50+",
      "100% Manual Outreach",
      "Permanent Do-Follow Backlink",
      "1 Anchor Text/Target URL",
      "Contextual Link",
      "Link Recovery Support",
      "Spam Free Links",
      "700 Words Article",
    ],
  },
];

const GuestPosts = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();
  return (
    <>
      <DIYHero
        bgImage={"/images/foundation-link-bg.png"}
        title={"GUEST POST & BLOGGER OUTREACH SERVICE"}
        subtitle={
          "Increase relevant links and shares by leveraging our network of multiple bloggers that influence large loyal fan bases & bring in real traffic and domain authority"
        }
        cta2Link={"https://calendly.com/bookadiscoverycall/15"}
      />
      <div className={styles.wrapper}>
        <div className={styles.bgContainer} />
        <div className={styles.bgContainerBottom} />

        <div className={styles.mainContent}>
          <Container>
            <div className={styles.partners}>
              {!isMobile
                ? logoList.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })
                : logoListMobile.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })}
            </div>

            <div className={styles.appointment}>
              <div className={styles.left}>
                <div className={styles.topText}>
                  STRONG & GENIUNE BLOGGING NETWORK
                </div>
                <div className={styles.header}>
                  With over more than 5000+ strong, steady & high traffic
                  websites. We help you get contextual real backlinks for your
                  website Mat brings real traffic and helps you rank.
                </div>
                <div className={styles.buttonCont}>
                  <Link to={"https://calendly.com/bookadiscoverycall/15"}>
                    <PrimaryButton buttonText={"Book an appointment"} />
                  </Link>
                </div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.logoImage}
                  src={"/images/ic_appointment.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </Container>

          {/*Dominate*/}
          <div className={styles.dominate}>
            <Container>
              <div className={styles.headerSection}>
                <h1>WHAT ARE BLOGGER OUTREACH LINKS?</h1>
                <h3>
                  Placing a link on a website blog that has a good audience and
                  footprint into your niche for your website to get better
                  authority.
                  <br />
                  Blogger outreach is the most secure way to make links by
                  manually contacting and generating links. Finding bloggers
                  that have actual traffic to their website is difficult, let
                  alone the idea of communicating and cracking a deal with them
                  to get a link placed for your website. This is where our
                  massive network of bloggers in almost every niche can help you
                  get blogger links fast and effectively
                </h3>
              </div>

              <div className={styles.cardsSection}>
                {DominateData.map((item, index) => {
                  return (
                    <div className={styles.cards} key={index}>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt={"logo"}
                      />

                      <h2>{item.header}</h2>
                      <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>

          <PricingOptions
            data={Pricing}
            heading={"Pricing and Options"}
            subHeading={
              "Through your RJ dashboard, you can Buy Pbn Links in a sizable quantity. You can get special wholesale pricing. Be it 10 or 60, each and every link of yours is posted safely on our high-quality blog network."
            }
          />

          {/* <SureSection /> */}
          <DFYClients
            dark={true}
            data={GuestData}
            header={"How we do it"}
            desc={
              "We will carry out a full-scale analysis of your SEO to find out the best strategy that will give you the most gains with niche-specific. Drive Traffic to your website by achieving page 1 rankings in search engines for valuable keywords"
            }
          />
        </div>
      </div>
      {/* Keep below ebook while merging(nihal) */}
      <EBookSingle />
      <div className={styles.expWrapper}>
        <DFYExperts
          bannerHeader={"Claim Your Free Backlink Audit Now"}
          bannerDesc={
            "Let us help you wead out Toxic, Irrelevant backlinks from your website!"
          }
        />
      </div>
      <DIYClients data={TestimonialsDataGuest} smallText={true} />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};
export default GuestPosts;
