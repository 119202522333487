import Container from "../../Shared/Container";
import InputTextButton from "../../Shared/Input/Button";
import styles from "./styles.module.scss";
import { useMediaQuery } from 'react-responsive';

const Data = [
    {
        header: "Increase monthly traffic",
        desc: "With a dependable team of SEO Experts to help your automate your SEO"
    },
    {
        header: "Increase annual revenue",
        desc: "With a dependable team of SEO Experts to help your automate your SEO"
    },
    {
        header: "Skyrocketed the traffic",
        desc: "With a dependable team of SEO Experts to help your automate your SEO"
    }
]

const DataMobile = [
    {
        header: "Increase monthly traffic",
        desc: "With a dependable team of SEO Experts to help your automate your SEO"
    }
]

const DFYGrow = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 576px)'
      });
    return (
        <div className={styles.wrapper}>
            <Container>
                <div className={styles.topSection}>
                    <h1>
                    We Help You Unlock Possibilities For Digital Success
                    </h1>
                    {/* <h1 className={styles.highlighted}>
                        monthly visitors
                    </h1> */}

                    <div className={styles.content}>
                        <div className={styles.left}>
                        At Rank Jacker, we stand by our philosophy of your growth through our digital excellence and experience. We have a robust global market presence to assist small and medium-sized enterprises, helping them grow their market share and profitability.
                            <br /><br />
                            <ul>
                                <li>
                                Completely White Hat
                                </li>
                                <li>
                                10 years of Industry Experience
                                </li>
                                <li>
                                Completely Hands-Free
                                </li>
                                <li>
                                Advanced Reporting (Custom Dashboard)
                                </li>
                                <li>
                                Bi-Monthly Progress Update
                                </li>
                                <li>
                                Result-Driven Custom Plan
                                </li>
                            </ul>
                            {/* <br />
                            A law firm based in Texas reached out to us complaining about its low-traffic website and the increased competition in its niche.
                            <br />
                            <br />
                            <br />
                            Within 6 months, we increased their website traffic to 1000 visits, that’s an explosive rise of over 1000% in traffic which is now valued at more than $15000 per month. */}
                        </div>
                        <div className={styles.right}>
                            <img className={styles.banner} src={'/images/i_visitors.png'} alt={'logo'} />
                        </div>
                    </div>
                </div>
            </Container>
            <div className={styles.secondSection}>
                <Container>
                    <div className={styles.headerSection}>
                        <h1>
                            We help you grow
                        </h1>
                        <p>
                            Lörem ipsum supratt vasa explainer i tresade kronat plases, fast senar. tresade kronat plases, fast senar. asdsad dklasdjasdias sadk sadk ad askd askdas lkdma das d
                        </p>
                    </div>

                    <div className={styles.cardsSection}>
                        {isMobile ? DataMobile.map((item, index) => {
                            return (
                                <div className={styles.cards}>
                                    <h1>
                                        {item.header}
                                    </h1>
                                    <p>
                                        {item.desc}
                                    </p>
                                </div>
                            )
                        }) : Data.map((item, index) => {
                            return (
                                <div className={styles.cards}>
                                    <h1>
                                        {item.header}
                                    </h1>
                                    <p>
                                        {item.desc}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default DFYGrow;