import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import { Link } from "react-router-dom";

const PricingOptions = ({ data, heading, subHeading, ctaLink }) => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <h4>{heading}</h4>
        {subHeading && <p>{subHeading}</p>}
        <div className={styles.pricingList}>
          {data.map((item, index) => {
            return (
              <div className={styles.card}>
                <div className={styles.price}>
                  {item.price}
                  <span className={styles.units}>
                    {item.unit && `/${item.unit}`}
                  </span>
                </div>
                <div className={styles.desc}>{item.desc}</div>
                {item.descTwo && (
                  <div className={styles.descTwo}>{item.descTwo}</div>
                )}
                <ul>
                  {item.list.map((item, index) => {
                    return <li>{item}</li>;
                  })}
                </ul>
                <Link to={ctaLink ? ctaLink : "/contact-us"} className={styles.cta}>
                  <button>Get started</button>
                </Link>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};
export default PricingOptions;
