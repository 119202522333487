import React, { useRef, useEffect } from "react";
import lottie from "lottie-web";
import styles from "./styles.module.scss";
// import animationData from "./road_map_animation.json";

const LottieControl = () => {
    const lottieRef = useRef(null);

    useEffect(() => {
        var animDuration = 10000;
        const anim = lottie.loadAnimation({
            container: lottieRef.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            // animationData: animationData,
            path: '/lottie/road_map_animation.json'
        });

        function animatebodymovin(duration) {
            const scrollPosition = window.scrollY;
            const maxFrames = anim.totalFrames;

            const frame = (maxFrames / 100) * (scrollPosition / (duration / 450));

            anim.goToAndStop(frame, true);
        }
        const onScroll = () => {
            animatebodymovin(animDuration);
        };

        document.addEventListener("scroll", onScroll);

        return () => {
            anim.destroy();
            document.removeEventListener("scroll", onScroll);
        };
    }, []);

    return <div className={styles.main} ref={lottieRef}></div>;
};

export default LottieControl;
