import Container from "../../Shared/Container";
import InputTextButton from "../../Shared/Input/Button";
import styles from "./styles.module.scss";
import { useMediaQuery } from "react-responsive";

const Data = [
  "/images/ic_databox.svg",
  "/images/ic_business_insider.svg",
  "/images/ic_legalzoom.svg",
  "/images/ic_partner_one.svg",
  "/images/ic_segate.svg",
];
const MobileData = [1, 2];

const DominateData = [
  {
    header: "Technical SEO",
    desc: "We excel in On-Page SEO and technical SEO, prioritizing website technicalities for effective on-page optimization.",
  },
  {
    header: "Content Strategy",
    desc: "Boost your ranking with strategic keywords powered by extensive customer sales cycle research. Our customized 3-month content plan ensures premium visibility and traffic that converts.",
  },
  {
    header: "Content Effectiveness",
    desc: "We monitor your content's performance post-publishing, refining it to enhance results and establish authority",
  },
  {
    header: "Link Building",
    desc: "We create powerful, relevant links to drive quality traffic, enhancing your Google ranking.",
  },
];

const DFYDetails = ({heading,leftText,rightText}) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <div className={styles.wrapper}>
      {/*<div className={styles.bgColor} />*/}
      <Container>
        <div className={styles.contentWrapper}>
          {/*<div className={styles.partners}>*/}
          {/*    {Data.map((item, index) => {*/}
          {/*        return (*/}
          {/*            <img className={styles.logoImage} src={item} alt={'logo'} />*/}
          {/*        )*/}
          {/*    })}*/}
          {/*</div>*/}

          <h3>{heading}</h3>
          <div className={styles.textBody}>
            <div className={styles.left} dangerouslySetInnerHTML={{__html:leftText}}>

            </div>
            <div className={styles.right} dangerouslySetInnerHTML={{__html:rightText}}>

            </div>
          </div>
        </div>
      </Container>
      {/*<div className={styles.dominate}>*/}
      {/*  <Container>*/}
      {/*    <div className={styles.headerSection}>*/}
      {/*      <h1>Learn The Art Of Dominating The Market</h1>*/}
      {/*      <h3>*/}
      {/*        Benefit from our result-oriented managed SEO services to grow your*/}
      {/*        business, attract more clients, and 10X website traffic. Discover*/}
      {/*        why, from our founding, we have been the top SEO agency in India*/}
      {/*        by looking at our methodical approach to SEO.*/}
      {/*      </h3>*/}
      {/*    </div>*/}

      {/*    <div className={styles.cardsSection}>*/}
      {/*      {DominateData.map((item, index) => {*/}
      {/*        return (*/}
      {/*          <div className={styles.cards}>*/}
      {/*            {isMobile && (*/}
      {/*              <img*/}
      {/*                className={styles.logoImage}*/}
      {/*                src={"/images/ic_risks.svg"}*/}
      {/*                alt={"logo"}*/}
      {/*              />*/}
      {/*            )}*/}
      {/*            <h2>{item.header}</h2>*/}
      {/*            {!isMobile && <p>{item.desc}</p>}*/}
      {/*          </div>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </div>*/}
      {/*  </Container>*/}
      {/*</div>*/}
    </div>
  );
};

export default DFYDetails;
