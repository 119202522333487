import SwitchButton from "../../Shared/Buttons/Switch";
import Container from "../../Shared/Container";
import styles from "./styles.module.scss";
import { useMediaQuery } from "react-responsive";
import React, { useState, useRef, createRef } from "react";
import { Link } from "react-router-dom";
import AnimatedContainer from "../../Animations/AnimatedContainer";
import { gsap, ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

const Data = [1, 2, 3, 4, 5];
const MobileData = [1, 2];

const PartnerOne = [
  "/images/h-logo-1.svg",
  "/images/h-logo-2.svg",
  "/images/h-logo-3.svg",
  "/images/h-logo-4.svg",
  "/images/h-logo-5.svg",
];
const PartnerOneMobile = [
  "/images/h-logo-1.svg",
  "/images/h-logo-2.svg",
  "/images/h-logo-3.svg",
];

const PartnerTwo = [
  "/images/h-logo-6.svg",
  "/images/h-logo-7.svg",
  "/images/h-logo-8.svg",
  "/images/h-logo-9.svg",
  "/images/h-logo-10.svg",
];
const PartnerTwoMobile = [
  "/images/h-logo-6.svg",
  "/images/h-logo-7.svg",
  "/images/h-logo-8.svg",
];

const Values = [
  {
    header: "Grow Your Capabilities",
    desc: "Our SEO experts streamline your tasks. We handle SEO; you focus on marketing, acquiring clients, and optimizing processes.",
    icon: "/images/ic_growth.svg",
    image: "/images/grow-home.png",
  },
  {
    header: "Expand Your Reach",
    desc: "Reach more clients effortlessly with our SEO Reseller Services. Broaden your service offerings using our hassle-free, Done-For-You solutions. Don't let resource limitations hold you back; we've got you covered.",
    icon: "/images/ic_expand.svg",
    image: "/images/expand-home.png",
  },
  {
    header: "Scale Your Business:",
    desc: "Level up your agency: grab our toolkit—proven processes, frameworks, and templates. From Cold Outreach to SEO delivery, our expertise is the key to your growth.",
    icon: "/images/ic_scale.svg",
    image: "/images/scale-home.png",
  },
];

const ValuesMobile = [
  {
    header: "Growth",
    desc: "With a dependable team of SEO Experts to help your automate your SEO operations and keep you focused on your Marketing Efforts, Client Acquisition & Process Optimisation.",
    icon: "/images/ic_growth.svg",
  },
];

const Services = {
  "Do It Yourself": [
    {
      header: "Niche Edits",
      desc: "Quick. Safe. Powerful. Get source-lasting links for your website with impactful niche-focused backlinks. Leverage the network for permanent links from indexed posts.",
      icon: "/images/ic_service_one.png",
      link: "/services/niche-edit-links",
    },
    {
      header: "PBN links",
      desc: "Boost your tier 1 links with our budget-friendly PBN links. Isolate links, diversify anchors and achieve topical relevance effortlessly.",
      icon: "/images/ic_service_two.png",
      link: "/services/buy-pbn-links",
    },
    {
      header: "Foundation Link",
      desc: "Build trust with foundation links, amplifying your business, story, products and services. A proven 5P Marketing Mix syncs seamlessly with top-notch niche properties. ",
      icon: "/images/ic_service_three.png",
      link: "/services/foundation-packages/",
    },
    {
      header: "Guest Posts",
      desc: "Our content team helps you create excellent guest posts optimized for search engines, which benefits the target demographic and boosts the traffic that links to your pages.",
      icon: "/images/ic_service_four.png",
      link: "/services/buy-guest-posts",
    },
    {
      header: "Press release",
      desc: "Our press release syndication delivers swift delivery to thousands of influential journalists and media outlets, amplifying your reach on top-tier websites.",
      icon: "/images/ic_service_five.png",
      link: "/services/best-pr-distribution/",
    },
    {
      header: "Haro Link",
      desc: "With our Haro Link Building service acquire high authority quote links from reporters in exclusive media publications.",
      icon: "/images/foundation-icon.svg",
      link: "/services/haro-links",
    },
  ],
  "Done for you": [
    {
      header: "Managed SEO",
      desc: "Boost your site on Google's search rankings with our expert SEO. We handle it all so you can focus on what you do best—running your business.",
      icon: "/images/ic_service_one.png",
      link: "/services/dfy-managed-seo/",
    },
    {
      header: "White Label SEO",
      desc: "Embark on a white label SEO partnership, broadening your services and increasing revenue. Achieve guaranteed results with our monthly reporting and top-notch SEO product.",
      icon: "/images/ic_service_two.png",
      link: "/services/white-label-seo",
    },
    {
      header: "Monthly Link Building",
      desc: "Our experts prioritize the relevance of links from esteemed sources and surpass rigorous quality standards. We build connections to increase your company's organic search engine results and bring in new clients.",
      icon: "/images/ic_service_three.png",
      link: "/services/monthly-link-building",
    },
    {
      header: "SEO Consultation",
      desc: "Connect with our SEO experts for a tailored strategy to enhance your website's ranking, drive targeted traffic, and maximize revenue.",
      icon: "/images/ic_service_four.png",
      link: "/services/seo-consulting",
    },
    {
      header: "SEO Audit",
      desc: "Stop on-page and off-site obstacles from impacting your search engine ranking with our dynamic SEO audit services. Enhance your performance with precision",
      icon: "/images/ic_service_five.png",
      link: "/services/seo-audit",
    },
    {
      header: "Content Strategy",
      desc: "Our SEO content strategy service staff produces thorough, in-depth, and semantically rich content using our in-house tools.",
      icon: "/images/ic_service_six.png",
      link: "/services/seo-content-strategy",
    },
  ],
};

// ScrollTrigger.normalizeScroll(true);

function HomeWho() {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const [activeService, setActiveService] = useState("Do It Yourself");
  const container = useRef();
  const headerText = useRef();
  const videoCont = useRef();

  const containerTwo = useRef();
  const headerTextTwo = useRef();
  const cardCont = useRef();

  const elementsRef = useRef(Values.map(() => createRef()));

  const containerThree = useRef();
  const headerTextThree = useRef();
  const cardContThree = useRef();

  const elementsRefTwo = useRef(Services[activeService].map(() => createRef()));

  const spring = {
    type: "spring",
    damping: 20,
    stiffness: 100,
  };

  const springHeader = {
    type: "spring",
    damping: 20,
    stiffness: 100,
  };

  useGSAP(
    () => {
      if (!isMobile) {
        let t1 = gsap.timeline({
          scrollTrigger: {
            trigger: videoCont?.current,
            markers: false,
            pin: true, // pin the trigger element while active
            start: "top +=120", // when the top of the trigger hits the top of the viewport
            end: "+=1500", // end after scrolling 500px beyond the start
            // scrub: 1,
            // anticipatePin: 6,
            scrub: 1,
            pinSpacing: true, // Adjust pin spacing
          },
        });
        let t2 = gsap.timeline({
          scrollTrigger: {
            trigger: containerTwo?.current,
            markers: false,
            pin: true, // pin the trigger element while active
            start: "top +=100", // when the top of the trigger hits the top of the viewport
            end: "+=3000", // end after scrolling 500px beyond the start
            // scrub: 1,
            scrub: 1,
            pinSpacing: true, // Adjust pin spacing
          },
        });
        let t8 = gsap.timeline({
          scrollTrigger: {
            trigger: containerThree?.current,
            markers: false,
            pin: true, // pin the trigger element while active
            start: "top +=120", // when the top of the trigger hits the top of the viewport
            end: "+=2000", // end after scrolling 500px beyond the start
            // scrub: 1,
            scrub: 1,
            pinSpacing: true, // Adjust pin spacing
          },
        });

        let t3 = gsap
          .timeline({
            scrollTrigger: {
              trigger: videoCont?.current,
              start: "top +=160",
              end: "+=3000",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            headerText?.current,
            {
              opacity: 0,
              y: -100,
              scale: 0.9,
              ease: "power1.easeInOut",
            },
            {
              opacity: 1,
              y: 0,
              scale: 1,
              ease: "power1.easeInOut",
            }
          )
          .fromTo(
            headerText?.current,
            {
              y: 0,
              // opacity: 1,
              ease: "power1.easeInOut",
            },
            {
              y: -600,
              // opacity: 0,
              delay: 0.4,
              ease: "power1.easeInOut",
            }
          );

        let t11 = gsap
          .timeline({
            scrollTrigger: {
              trigger: videoCont?.current,
              start: "top +=500",
              end: "+=4500",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            container?.current,
            {
              opacity: 0,
              x: 150,
              ease: "power1.easeInOut",
            },
            {
              opacity: 1,
              x: 0,
              ease: "power1.easeInOut",
            }
          )
          .fromTo(
            container?.current,
            {
              // width: '100%',
              // aspectRatio: '16/9',
              ease: "power1.easeInOut",
            },
            {
              // width: '0%',
              // aspectRatio: '3/4',
              ease: "power1.easeInOut",
            }
          )
          .fromTo(
            container?.current,
            {
              y: 0,
              // opacity: 1,
              ease: "power1.inOut",
            },
            {
              y: -600,
              // opacity: 0,
              // delay: 0.1,
              ease: "power1.inOut",
            }
          );

        let t4 = gsap
          .timeline({
            scrollTrigger: {
              trigger: containerTwo?.current,
              start: "top +=500",
              end: "+=1700",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            elementsRef?.current[1]?.current,
            {
              opacity: 0,
              ease: "power1.inOut",
            },
            {
              opacity: 1,
              ease: "power1.inOut",
            }
          )
          .fromTo(
            headerTextTwo?.current,
            {
              opacity: 0,
              y: 100,
              scale: 0.9,
              ease: "power1.inOut",
            },
            {
              opacity: 1,
              y: 0,
              scale: 1,
              // delay: 0.01,
              ease: "power1.inOut",
            }
          );

        let t13 = gsap
          .timeline({
            scrollTrigger: {
              trigger: containerTwo?.current,
              start: "top +=160",
              end: "+=4000",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            elementsRef?.current[0]?.current,
            {
              opacity: 0,
              x: 400,
              scale: 0.9,
              ease: "power1.inOut",
            },
            {
              opacity: 1,
              x: 0,
              scale: 1,
              delay: 1,
              ease: "power1.inOut",
            }
          )
          .fromTo(
            cardCont?.current,
            {
              opacity: 1,
              y: 0,
              scale: 1,
              ease: "power1.inOut",
            },
            {
              opacity: 0,
              y: 400,
              scale: 0.9,
              delay: 1.5,
              ease: "power1.inOut",
            }
          )
          .fromTo(
            headerTextTwo?.current,
            {
              // opacity: 1,
              y: 0,
              scale: 1,
              ease: "power1.inOut",
            },
            {
              // opacity: 0,
              y: 500,
              scale: 0.9,
              ease: "power1.inOut",
            }
          );
        let t14 = gsap
          .timeline({
            scrollTrigger: {
              trigger: containerTwo?.current,
              start: "top +=160",
              end: "+=4000",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            elementsRef?.current[2]?.current,
            {
              opacity: 0,
              x: -400,
              scale: 0.9,
              ease: "power1.inOut",
            },
            {
              opacity: 1,
              x: 0,
              scale: 1,
              delay: 1,
              ease: "power1.inOut",
            }
          )
          .fromTo(
            cardCont?.current,
            {
              opacity: 1,
              y: 0,
              scale: 1,
              ease: "power1.inOut",
            },
            {
              opacity: 0,
              y: 400,
              scale: 0.9,
              delay: 1.5,
              ease: "power1.inOut",
            }
          )
          .fromTo(
            headerTextTwo?.current,
            {
              // opacity: 1,
              y: 0,
              scale: 1,
              ease: "power1.inOut",
            },
            {
              // opacity: 0,
              y: 500,
              scale: 0.9,
              ease: "power1.inOut",
            }
          );

        // let t22 = gsap
        // .timeline({
        //   scrollTrigger: {
        //     trigger: containerTwo?.current,
        //     start: "bottom +=1000",
        //     end: "+=3500",
        //     scrub: 1,
        //     markers: false
        //   }
        // })
        // .fromTo(cardCont?.current, {
        //   opacity: 0,
        //   x: -400,
        //   scale: 0.9,
        //   ease: "power1.inOut"
        // }, {
        //   opacity: 1,
        //   x: 0,
        //   scale: 1,
        //   delay: 1,
        //   ease: "power1.inOut"
        // })

        let t9 = gsap
          .timeline({
            scrollTrigger: {
              trigger: containerThree?.current,
              start: "top +=600",
              end: "+=800",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            headerTextThree?.current,
            {
              opacity: 0,
              x: -150,
              ease: "power1.easeInOut",
            },
            {
              opacity: 1,
              x: 0,
              ease: "power1.easeInOut",
            }
          );

        let t15 = gsap
          .timeline({
            scrollTrigger: {
              trigger: containerThree?.current,
              start: "top +=600",
              end: "+=800",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            cardContThree?.current,
            {
              opacity: 0,
              x: 250,
              ease: "power1.easeInOut",
            },
            {
              opacity: 1,
              x: 0,
              ease: "power1.easeInOut",
            }
          );

        let t16 = gsap
          .timeline({
            scrollTrigger: {
              trigger: containerThree?.current,
              start: "top center",
              end: "+=2500",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            [
              elementsRefTwo?.current[2]?.current,
              elementsRefTwo?.current[1]?.current,
              elementsRefTwo?.current[0]?.current,
            ],
            {
              opacity: 0,
              x: -350,
              ease: "power1.easeInOut",
            },
            {
              opacity: 1,
              x: 0,
              delay: 0.2,
              ease: "power1.easeInOut",
            }
          );
        let t17 = gsap
          .timeline({
            scrollTrigger: {
              trigger: containerThree?.current,
              start: "top center",
              end: "+=2500",
              scrub: 1,
              markers: false,
            },
          })
          .fromTo(
            [
              elementsRefTwo?.current[3]?.current,
              elementsRefTwo?.current[4]?.current,
              elementsRefTwo?.current[5]?.current,
            ],
            {
              opacity: 0,
              x: 350,
              ease: "power1.easeInOut",
            },
            {
              opacity: 1,
              x: 0,
              delay: 0.2,
              ease: "power1.easeInOut",
            }
          );
      }
    },
    { scope: videoCont, containerTwo, containerThree }
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.bgContainer} />

      <div className={styles.bgContainerBottom} />
      <AnimatedContainer
        className={styles.partners}
        variants={{
          visible: { opacity: 1, translateY: 0, scaleY: 1 },
          hidden: { opacity: 0, translateY: 50, scaleY: 0.8 },
        }}
        transition={spring}
      >
        {isMobile
          ? PartnerOneMobile.map((item, index) => {
              return (
                <img className={styles.logoImage} src={item} alt={"logo"} />
              );
            })
          : PartnerOne.map((item, index) => {
              return (
                <img className={styles.logoImage} src={item} alt={"logo"} />
              );
            })}
      </AnimatedContainer>
      <AnimatedContainer
        className={styles.partners}
        variants={{
          visible: { opacity: 1, translateX: 0 },
          hidden: { opacity: 0, translateX: 200 },
        }}
        transition={spring}
        margin="-100px 0px"
      >
        {isMobile
          ? PartnerTwoMobile.map((item, index) => {
              return (
                <img className={styles.logoImage} src={item} alt={"logo"} />
              );
            })
          : PartnerTwo.map((item, index) => {
              return (
                <img className={styles.logoImage} src={item} alt={"logo"} />
              );
            })}
      </AnimatedContainer>
      <Container>
        <div className={styles.mainContent}>
          <div ref={videoCont}>
            <h2 className={styles.whoHeader} ref={headerText}>
              Discover the SEO Trailblazers
            </h2>
            <div className={styles.videoContainer} ref={container}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/cXVtG_o5N44?si=5fEmgefVEjtlS_39&amp;controls=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div ref={containerTwo}>
            <h1 className={styles.valueHeader} ref={headerTextTwo}>
              We Help You
            </h1>

            <div className={styles.valueCards} ref={cardCont}>
              {Values.map((item, index) => {
                console.log(elementsRef?.current[index], "ref");
                return (
                  <div
                    className={styles.valueCard}
                    ref={elementsRef?.current[index]}
                    key={index}
                  >
                    <div className={styles.top}>
                      <h2>{item.header}</h2>
                      <img
                        className={styles.valueItem}
                        src={item.icon}
                        alt={"logo"}
                      />
                    </div>
                    <p>{item.desc}</p>
                    {!isMobile && (
                      <img
                        src={item.image}
                        alt=""
                        className={styles.bottomImage}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div ref={containerThree}>
            <div className={styles.serviceHeader}>
              <h1 className={styles.serviceHeaderText} ref={headerTextThree}>
                Rated Best, Delivered Better: Our Services
              </h1>
              <div ref={cardContThree} className={styles.switchButton}>
                <SwitchButton
                  buttonTexts={["Do It Yourself", "Done for you"]}
                  onClick={(item) => {
                    console.log(item, "hit");
                    setActiveService(item);
                  }}
                />
              </div>
            </div>

            <div className={styles.serviceCards}>
              {Services[activeService].map((item, index) => {
                return (
                  <div ref={elementsRefTwo?.current[index]} key={index}>
                    <Link to={item.link} className={styles.serviceCard}>
                      <img
                        className={styles.serviceItem}
                        src={item.icon}
                        alt={"logo"}
                      />
                      <h2>{item.header}</h2>
                      <p>{item.desc}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default HomeWho;
