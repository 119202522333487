"use client";
import React, { useEffect, useRef, useState } from "react";
// import { useInView } from "react-intersection-observer";
import { motion, useAnimation, useInView } from "framer-motion";
import { useMediaQuery } from "react-responsive";

function AnimatedContainer({
  children,
  transition,
  variants,
  className,
  margin = "0px 0px",
}) {
  const controls = useAnimation();
  // const [ref, inView] = useInView({
  //   threshold: threshold,
  //   rootMargin: rootMargin,
  // });
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: margin });
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  useEffect(() => {
    if (isInView) {
      console.log("isInView", variants);
      controls.start("visible");
    }
  }, [isInView,controls]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={transition}
      variants={variants}
      className={className}
    >
      {children}
    </motion.div>
  );
}

export default AnimatedContainer;
