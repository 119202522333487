import styles from './styles.module.scss';
import Container from '../../Shared/Container';
import PrimaryButton from '../../Shared/Buttons/Primary';
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';

const Data = Array.from({ length: 3 })

function ContentStrategyHero() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });


  return (
    <div className={styles.wrapper}>
      <img className={styles.leftAbs} src={'/images/i_blogs_illus_one.png'} alt={'carousel item'} />
      {/* <img className={styles.middleAbs} src={'/images/i_blogs_illus_two.png'} alt={'carousel item'} /> */}
      <img className={styles.rightAbs} src={'/images/i_blogs_illus_three.png'} alt={'carousel item'} />
      <Container>
        <div className={styles.contentWrapper}>
          <div className={styles.headerText}>
          Discuss And Craft A Winning SEO Content Strategy with Our Expert Services
          </div>

          <p>
          We create mindful SEO content strategies for an impeccable growth and online presence.
          </p>

          <div className={styles.buttonContainer}>
            <Link to={'https://calendly.com/bookadiscoverycall/15'}>
            <PrimaryButton buttonText={'Talk To Expert'}/>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ContentStrategyHero;
