import Container from "../../Shared/Container";
import styles from "./styles.module.scss";
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from "react-router-dom";

const FoundersPageTwo = () => {
    const isMobile = useMediaQuery({
        query: '(max-width: 576px)'
    });
    return (
        <Container>
            <div className={styles.wrapper}>
                <div className={styles.breadcrumb}>
                    <p>
                        About us
                    </p>
                    <img className={styles.icon} src={'/images/ic_light_arrow.svg'} alt={'logo'} />
                    <p className={styles.light}>
                        Rank jackers founder
                    </p>
                </div>

                <div className={styles.heroSection}>
                    <img className={styles.icon} src={'/images/i_founder_rohit.jpeg'} alt={'logo'} />
                    <div className={styles.middle}>
                        <div className={styles.top}>
                            Rank jacker founder
                        </div>

                        <h1>
                            Rohit Dua
                        </h1>

                        <p>
                            Rohin, a Mechanical Engineer by Education, has been instrumental in Service Portfolio Design for RankJacker. As a Lead SEO, he's the one responsible to affect Operational Excellence & Automations to make everything work as a well-oiled machine. As a Growth Hacker, he brings about a lot of experience and continuous improvements to our system and processes.
                        </p>
                        {/* <br />
                        <p>
                            Ever since he was a child, he would do things that showed his caliber of being an entrepreneur - you can say that he was a born entrepreneur. His work streak and curious nature gave him a huge berth to try new and different things.
                        </p> */}
                    </div>

                    <div className={styles.last}>
                        {/* <p>
                            I call myself a blessed man. Whatever I have dreamed of in life, sooner or later, I have got it. Not because of luck, but because of the people in my life, who have helped me to realize my dream.
                        </p>
                        <br />
                        <p>
                            Nobody is self-made. You need people to change your life. My life was changed by Satguru Shri Wamanrao Pai, and everything I am today, everything that I have today, I owe it all to him.
                        </p> */}
                    </div>
                </div>

                <div className={styles.details}>
                    {/* <div className={styles.left}>
                        "You are the architect of your life." Nobody else is going to come and change your life for you - they can only help you. You have to take the decision to change your life - for the betterment of yourself and everyone around you.
                    </div>
                    <div className={styles.right}>
                        <p>
                            He held onto his dream as he grew up. No matter who teased him or called his ideas a foolish dream - he held onto his vision with both his hands.
                        </p>
                        <br />
                        <p>
                            Lorem ipsum dolor sit amet consectetur. Etiam iaculis aenean augue cursus feugiat duis dui lobortis. Nulla ultrices ultrices ornare sagittis fermentum scelerisque. Cursus amet non imperdiet ut tellus eu pulvinar. Vitae mauris risus scelerisque imperdiet vel. Quam condimentum pellentesque consequat netus velit. Habitant malesuada sit etiam dui eleifend sit ac.
                        </p>
                        <br />
                        <p>
                            It wasn’t an easy journey. As is the way of life, he had to face many challenges and overcome quite a few obstacles. But through it all, he had his vision to hold him strong. He had people who stood by him, gave him strength. He spent close to 15 years, transforming and shaping himself into the entrepreneur he wanted to be.
                        </p>
                    </div> */}
                </div>

                <div className={styles.bottomSection}>
                    <div className={styles.left}>
                        <div className={styles.iconWrapper}>
                            <Link to={"https://www.linkedin.com/in/rankjackerseo/"}>
                                <img className={styles.icon} src={'/images/ic_linkedin_filled.svg'} alt={'logo'} />
                            </Link>
                            <Link to={"https://www.instagram.com/rankjacker/"}>
                                <img className={styles.icon} src={'/images/ic_instagram_filled.svg'} alt={'logo'} />
                            </Link>
                            <Link to={"https://www.facebook.com/rankjacker"}>
                                <img className={styles.icon} src={'/images/ic_fb_filled.svg'} alt={'logo'} />
                            </Link>
                        </div>

                        <div className={styles.line} />
                    </div>


                    {!isMobile && <p>
                        Our story as Rank jacker might just be 5 years old, but behind it is the long journey of 23 years where our hero went through a rollercoaster ride of highs and lows.He is our hero because Lemon Yellow is his story.
                    </p>}
                </div>

                <div className={styles.collabBanner}>
                    <img src={"/images/ic_rocket.svg"}
                        alt={"rocket"}
                        className={styles.rocket} />
                    <img src={"/images/i_collaborate_pattern.svg"}
                        alt={"pattern"}
                        className={styles.pattern} />
                    <div>
                        <h2>
                            Have a project in mind?
                        </h2>
                        <h2>
                            Let’s collaborate
                        </h2>
                    </div>

                    <div className={styles.button}>
                        <p>
                            Get Started Today
                        </p>
                        <img src={"/images/ic_arrow_dark.svg"}
                            alt={"arrow"}
                            className={styles.arrow} />
                    </div>

                </div>
            </div>
        </Container>
    )
};

export default FoundersPageTwo;