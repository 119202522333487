import DIYHero from "../../../components/DIY/Hero";
import DIYDetails from "../../../components/DIY/Details";
import DIYTestimonials from "../../../components/DIY/Testimonials";
import HomeFaqs from "../../../components/Home/Faqs";
import Footer from "../../../components/Footer";
import Details from "../../../components/DFY/Details";
import styles from "../styles.module.scss";
import Container from "../../../components/Shared/Container";
import PrimaryButton from "../../../components/Shared/Buttons/Primary";
import SwitchButton from "../../../components/Shared/Buttons/Switch";
import { useMediaQuery } from "react-responsive";
import PricingOptions from "../../../components/DIY/PricingOptions";
import DFYClients from "../../../components/DFY/Clients";
import {
  CitationData,
  TestimonialsData,
  TestimonialsDataLocal,
} from "../../../cont/ClientData";
import DIYSure from "../../../components/DIY/Sure";
import DIYClients from "../../../components/DIY/Clients";
import { Link } from "react-router-dom";
import CitationAudit from "../../../components/DIY/CitationAudit";

const logoList = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-4.png",
  "/images/diy-logo-5.png",
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-5.png",
];

const logoListMobile = [
  "/images/diy-logo-1.png",
  "/images/diy-logo-2.png",
  "/images/diy-logo-3.png",
  "/images/diy-logo-4.png",
];

const DominateData = [
  {
    icon: "/images/speaker.svg",
    header: "Manually Created",
    desc: "We have real people working on your Citations. We don’t use any bots/scripts to automate Citation Building that allows us to make tweaks to your campaign to meet custom requirements wherever needed. Our team is highly experienced along with a strict Quality Check protocols to deliver exactly what’s promised.",
  },
  {
    icon: "/images/blog.svg",
    header: "NAP Consistency",
    desc: "Local citations are one of the most effective foundation link practices and NAP Accuracy and consistency is something that is indispensable. Our whole process is designed to ensure that there’s no mismatch with regards to Business Name, Business Address, Email, Post Code and Website Address.",
  },
  {
    icon: "/images/link.svg",
    header: "Geo Location Specific",
    desc: "No corner cuttings to deliver orders, we only build Geo-Location targeted listings alone. Right now we cover US, Canada, Australia and UK for this service, but if you want to order for some other country, you can add a custom order for us to start working.",
  },
  {
    icon: "/images/wheel.svg",
    header: "Only Unique Listing",
    desc: "Our team follows strict and thorough SOPs to identify the most relevant and unique Directories Sites to render links from, just to make sure there’s not even a single duplicate listing. Also to provide full value for your money, we run existing citations to check through our list to make sure you are delivered unique and clean Business Citations",
  },
];
const Pricing = [
  {
    icon: "/images/sure_1.svg",
    head: "Toddler",
    sub: "Citation Audit",
    list: [
      "NAP Variations Check",
      "Existing Correct Citations",
      "Incorrect Citations",
    ],
  },
  {
    icon: "/images/sure_2.png",
    head: "Basic",
    sub: "25 Total Citations",
    list: [
      "10 Industry/Niche Specific",
      "10 Local Directories",
      "5 Social Citations",
    ],
  },
  {
    icon: "/images/sure_3.png",
    head: "Silver",
    sub: "40 Total Citations",
    list: [
      "15 Industry/Niche Specific",
      "15 Local Directories",
      "5 Social Citations",
      "5×3 Image Citations",
      "2×2 Video Citations",
    ],
  },
  {
    icon: "/images/sure_4.png",
    head: "Gold",
    sub: "80 Total Citations",
    list: [
      "30 Industry/Niche Specific",
      "30 Local Directories",
      "10 Social Citations",
      "10×7 Image Citations",
      "4×3 Video Citations",
    ],
  },
  {
    icon: "/images/sure_5.png",
    head: "Premium",
    sub: "120 Total Citations",
    list: [
      "45 Industry/Niche Specific",
      "45 Local Directories",
      "15 Social Citations",
      "10×10 Image Citations",
      "5×5 Video Citations",
    ],
  },
];

const FaqData = {
  Generals: [
    {
      question: "How does citation building help with local SEO?",
      answer:
        "Citation building helps with local SEO by establishing your business's online presence across various platforms, increasing visibility to local customers, and improving your chances of appearing in local search results.",
    },
    {
      question: "What information do I need to provide?",
      answer:
        "You will typically need to provide basic business information such as your business name, address, phone number (NAP), website URL, business categories, and any additional relevant details about your business.",
    },
    {
      question: "Will all citations include NAP information?",
      answer:
        "Yes, most citations will include your business's NAP information, as this is crucial for local SEO and ensuring consistency across different online platforms.",
    },
    {
      question:
        "What if I need to make changes to a listing, do you offer citation management?",
      answer:
        "Yes, many citation building services offer citation management, which includes updating or correcting existing listings as needed to ensure accuracy and consistency across all platforms.",
    },
    {
      question: "Are these manual listings or are you using software?",
      answer:
        "It depends on the service provider. Some services may use a combination of manual submissions and software tools to ensure accuracy and efficiency in citation building.",
    },
    {
      question: "Do you have a sample?",
      answer:
        "Yes, most citation building services can provide samples of their work or examples of citations they have created for other clients upon request.",
    },
    {
      question: "What is a structured citation vs. an unstructured citation?",
      answer:
        "A structured citation refers to a citation that follows a specific format and is typically found in online directories or business listings. An unstructured citation, on the other hand, can be any mention of your business online, such as in blog posts, articles, or social media posts.",
    },
    {
      question: "Do you do citations for every country?",
      answer:
        "While many citation building services focus on specific countries or regions, some may offer citations for businesses located in multiple countries. It's essential to inquire with the service provider about their coverage areas.",
    },
    {
      question: "Do you provide citation management or clean-up?",
      answer:
        "Yes, citation building services often provide citation management and clean-up services, which involve monitoring, updating, and correcting citations across various online platforms to ensure accuracy and consistency.",
    },
  ],
};
const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO has truly transformed our business with their local citation service. Our online visibility skyrocketed, bringing in a flood of new customers from our local area. Highly recommended!",
    h3: "John Smith",
    designation: "Business Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Thanks to Rank-Jacker SEO's local citation service, our small boutique saw a significant increase in foot traffic and online inquiries. Their attention to detail and effective strategies have made a tangible difference in our business.",
    h3: "Sarah Johnson",
    designation: "Boutique Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's local citation service has been a game-changer for our plumbing company. We're now ranking higher in local searches, and our phone hasn't stopped ringing with new leads. Couldn't be happier with the results!",
    h3: "Michael Thompson",
    designation: "Plumbing Company Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We were struggling to attract customers to our dental clinic until we discovered Rank-Jacker SEO's local citation service. Since then, our appointment books have been consistently full. Thank you for helping our practice thrive!",
    h3: "Dr. Emily Parker",
    designation: "Dental Clinic Owner",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's local citation service has been instrumental in boosting our restaurant's online presence. We've noticed a significant increase in reservations and walk-ins since partnering with them. Excellent service all around!",
    h3: "Mark Davis",
    designation: "Restaurant Owner",
    personSrc: "/images/i_client_one.png",
  },
];

const LocalCitation = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <>
      <DIYHero
        bgImage={"/images/foundation-link-bg.png"}
        title={"ADVANCED LOCAL CITATION BUILDING SERVICES"}
        subtitle={
          "When You Buy Links From us, you can be rest assured that they not only stand strong on Promised Metrics but"
        }
        cta1Link={"https://rankjacker.spp.io/order/advance-local-citation"}
        cta2Link={"https://calendly.com/bookadiscoverycall/15"}
      />
      <div className={styles.wrapper}>
        <div className={styles.bgContainer} />
        <div className={styles.bgContainerBottom} />

        <div className={styles.mainContent}>
          <Container>
            <div className={styles.partners}>
              {!isMobile
                ? logoList.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })
                : logoListMobile.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })}
            </div>

            <div className={styles.appointment}>
              <div className={styles.left}>
                <div className={styles.topText}>
                  DO YOU RUN A LOCAL BUSINESS OR OWN A LOCAL BUSINESS SEO
                  AGENCY?
                </div>
                <div className={styles.header}>
                  You definitely understand the importance of Business Citations
                  in a Local SEO project. Most vendors do little to help you
                  take to leverage off the Local Citations. What To Do
                  Then?…Local Citations are not just a customary link-building
                  practice, but a potentially powerful method to boost like
                  Brand Exposure, Discoverability, Search Rankings
                </div>
                <div className={styles.buttonCont}>
                  <Link to={"https://calendly.com/bookadiscoverycall/15"}>
                    <PrimaryButton buttonText={"Book an appointment"} />
                  </Link>
                </div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.logoImage}
                  src={"/images/ic_appointment.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </Container>

          {/*Dominate*/}
          <div className={styles.dominate}>
            <Container>
              <div className={styles.headerSection}>
                <h1>
                  SOME OTHER FEATURES THAT ARE COMPLEMENTARY TO THE SERVICE
                </h1>
              </div>

              <div className={styles.cardsSection}>
                {DominateData.map((item, index) => {
                  return (
                    <div className={styles.cards} key={index}>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt={"logo"}
                      />

                      <h2>{item.header}</h2>

                      <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>

          <DFYClients
            dark={true}
            data={CitationData}
            header={"BENEFITS OF LOCAL BUSINESS LISTING"}
            desc={
              "With our experience & knowledge in SEO Domain, you can rely on us for an effective PR campaign, and rest assured that you would get complete value for your money. A few of the highlights of our service:-"
            }
            ctaLink={
              "https://forms.zohopublic.in/rankmakeup/form/MonthlyLinkBuildingServicesFrom/formperma/6D8s1zzWj6n07cdswYzlM7bE8s-QgUC8PocNl6DkAc4"
            }
          />
        </div>
      </div>
      <CitationAudit/>
      <DIYSure
        data={Pricing}
        heading={"Not sure what you want?"}
        ctaLink={"https://rankjacker.spp.io/order/advance-local-citation"}
      />
      <DIYTestimonials data={Testimonial} />
      <DIYClients data={TestimonialsDataLocal} />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};
export default LocalCitation;
