export const ReviewsData = [
    '/images/review_four.png',
    '/images/review_two.png',
    '/images/review_three.png',
    '/images/review_four.png',
    '/images/review_five.png',
    '/images/review_two.png',
    '/images/review_three.png',
    '/images/review_two.png',
    '/images/review_three.png',
    '/images/review_four.png',
    '/images/review_five.png',
    '/images/review_three.png',
    '/images/review_three.png',
    '/images/review_two.png',
    '/images/review_three.png'
]

export const TestimonialsData = [
    {
        data: `“Trusted them and their team with my
        SEO and they did not disappoint”`,
        name: `Rohit Kadam, CEO`,
        image: '/images/one_testimonial.png'
    },
    {
        data: `“ Very engaging and hands on their
        approach of communication. they catered
        everything specifically to my needs”`,
        name: `Rohit Kadam, CEO`,
        image: '/images/one_testimonial.png'
    },
    {
        data: `“Trusted them and their team with my
        SEO and they did not disappoint”`,
        name: `Rohit Kadam, CEO`,
        image: '/images/one_testimonial.png'
    },
    {
        data: `“ Very engaging and hands on their
        approach of communication. they catered
        everything specifically to my needs”`,
        name: `Rohit Kadam, CEO`,
        image: '/images/one_testimonial.png'
    },
    {
        data: `“Trusted them and their team with my
        SEO and they did not disappoint”`,
        name: `Rohit Kadam, CEO`,
        image: '/images/one_testimonial.png'
    },
    {
        data: `“ Very engaging and hands on their
        approach of communication. they catered
        everything specifically to my needs”`,
        name: `Rohit Kadam, CEO`,
        image: '/images/one_testimonial.png'
    },
    {
        data: `“ Very engaging and hands on their
        approach of communication. they catered
        everything specifically to my needs”`,
        name: `Rohit Kadam, CEO`,
        image: '/images/one_testimonial.png'
    },
];


export const TestimonialDefault = [
    {
      brand: "/images/ic_rankjacker_dark.svg",
      p: "Rank-Jacker SEO's Monthly Managed Link Building service is worth every penny. Not only have they helped us build a diverse backlink profile, but they've also provided valuable insights and recommendations to further enhance our SEO strategy.",
      h3: "Emily Brown",
      designation: "Director Of Marketing Agency",
      personSrc: "/images/i_client_one.png",
    },
    {
      brand: "/images/ic_rankjacker_dark.svg",
      p: "The results speak for themselves – Rank-Jacker SEO's Monthly Managed Link Building service has catapulted our websites to the top of search engine results pages. Their dedication to delivering tangible results is unmatched.",
      h3: "David Wilson",
      designation: "SEO Agency Owner",
      personSrc: "/images/i_client_one.png",
    },
    {
      brand: "/images/ic_rankjacker_dark.svg",
      p: "Our experience with Rank-Jacker SEO's Monthly Link Building service has been nothing short of exceptional. Their team's proactive approach and transparent communication have made them a trusted partner in our SEO journey.",
      h3: "James Thompson",
      designation: "CEO",
      personSrc: "/images/i_client_one.png",
    },
    {
      brand: "/images/ic_rankjacker_dark.svg",
      p: "Rank-Jacker SEO's Monthly Managed Link Building service is a game-changer. Their strategic approach to link building has not only boosted our website's authority but also helped us establish credibility within our industry.",
      h3: "Olivia Miller",
      designation: "Head Of Digital Marketing Agency",
      personSrc: "/images/i_client_one.png",
    },
    {
      brand: "/images/ic_rankjacker_dark.svg",
      p: "Since partnering with Rank-Jacker SEO for their Monthly Managed Link Building service, our website's organic traffic has seen a significant uptick. Their commitment to delivering results is evident in the quality of backlinks they acquire for us.",
      h3: "James Clark",
      designation: "Marketing Manager",
      personSrc: "/images/i_client_one.png",
    },
  ];