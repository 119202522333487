import Container from "../../Shared/Container";
import styles from './styles.module.scss';

function CaseInternalRequirement() {
    return (
        <Container>
            <div className={styles.wrapper}>
                <div className={styles.contents}>
                    <h3>
                        Services we offered
                    </h3>

                    <p className={styles.active}>
                        Title 1 : Lorem ipsum saying something
                    </p>
                    <p>
                        Title 2 : Lorem ipsum saying something
                    </p>
                    <p>
                        Title 3 : Lorem ipsum saying something
                    </p>
                    <p>
                        Title 4 : Lorem ipsum saying something
                    </p>
                    <p>
                        Title 5 : Lorem ipsum saying something
                    </p>
                </div>

                <div className={styles.right}>
                    <h2>
                    Their Requirement
                    </h2>
                    <p>
                    Lorem ipsum dolor sit amet consectetur. Ipsum purus lectus pretium dolor fringilla gravida interdum. Dui a senectus ut senectus sed. Orci non pulvinar ac sed quis. Egestas nam amet eu bibendum vitae sed id eleifend.Lorem ipsum dolor sit amet consectetur. Ipsum purus lectus pretium dolor fringilla gravida interdum. Dui a senectus ut senectus sed. Orci non pulvinar ac sed quis. Egestas nam amet eu bibendum vitae sed id eleifend.
                    </p>
                </div>
            </div>
        </Container>
    );
}

export default CaseInternalRequirement;
