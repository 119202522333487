import { useState } from "react";
import Container from "../Container";
import styles from "./styles.module.scss";
import SwitchButton from "../../Shared/Buttons/Switch";
import Footer from "../../Footer";
import { Books, Podcasts } from "../../../cont/EbookList";
import { Link, useNavigate } from "react-router-dom";

const EBook = ({ children }) => {
  const [active, setActive] = useState("Podcasts");
  return (
    <div className={styles.wrapper}>
      <div className={styles.pattern} />

      <Container>
        <div className={styles.left}>
          <h1>Lend Your Ears to</h1>
          <h1 className={styles.alter}>Success Stories</h1>
          <p>
            Listen to SEO experts and agency owners talk about their in-field
            experience building online brands. Hear stories of transformation,
            adaption and innovation that lead to SEO success.
          </p>
          {/* <div className={styles.buttonCont}>
            <input name="myInput" placeholder={'Your Email'} className={styles.inputField} />
            <PrimaryButton buttonText={'Read now'} />
          </div> */}

          <div className={styles.btnCont}>
            <SwitchButton
              buttonTexts={["Podcasts", "Ebooks"]}
              onClick={(i) => setActive(i)}
            />
          </div>

          <div className={styles.cardCont}>
            {active === "Podcasts"
              ? Podcasts.map((i) => {
                  return (
                    <iframe
                      src={i}
                      height="300"
                      width={"100%"}
                      loading="lazy"
                      className={styles.mapStyle}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  );
                })
              : Books.map((i) => {
                  return (
                    <Link className={styles.card} to={i.link}>
                      <h3>{i.name}</h3>
                      <img src={i.img} alt={"logo"} />
                    </Link>
                  );
                })}
          </div>

          <div className={styles.news}>
            <h1>Want To Hear More From Us?</h1>
            <p>
              Subscribe to our newsletter and get unlimited SEO updates, ranking
              tips, and revenue generation advice from our in-house experts.
            </p>
            <div className={styles.buttonWrapper}>
              <input
                name="myInput"
                placeholder={"Your Email"}
                className={styles.inputField}
              />
              <img
                className={styles.icon}
                src={"/images/ic_arrow_dark.svg"}
                alt={"carousel item"}
              />
            </div>
          </div>
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default EBook;
