import Footer from "../../components/Footer";
import HomeFaqs from "../../components/Home/Faqs";
import HomeHero from "../../components/Home/Hero";
import HomeTestimonial from "../../components/Home/Testimonial";
import HomeWho from "../../components/Home/Who";
import HomeWorkspace from "../../components/Home/Workspace";
import { FaqHome } from "../../cont/Home";

const WorkspaceSubSectionData = [
  {
    text: "Provide holistic SEO campaigns",
    icon: "/images/ic_easy_one.svg",
  },
  {
    text: "Secure relationships with the clients",
    icon: "/images/ic_easy_two.svg",
  },
  {
    text: "Provide transparent Reporting",
    icon: "/images/ic_easy_three.svg",
  },
  {
    text: "Employ industry-specific approach",
    icon: "/images/ic_easy_four.svg",
  },
  {
    text: "Conduct technical SEO and page speed optimization",
    icon: "/images/ic_easy_five.svg",
  },
  {
    text: "Provide excellent semantic SEO services",
    icon: "/images/ic_easy_six.svg",
  },
];
const Testimonial = [
  {
    brand: "/images/ic_segate_dark.svg",
    p: "Rank Jacker went above and beyond to support the expansion of our online presence and delivered a much-needed boost. They provided us with a roadmap for content creation, which helped us rank higher for the content and drive more visitors to the website! Excellent outcomes and first-rate assistance.",
    h3: "D. Surrey",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-1.png",
  },
  {
    brand: "/images/ic_databox_dark.svg",
    p: "Finding an SEO team that produces results might be challenging, but we discovered that SEO Experts at Rank Jacker do just that. Mr Amit is focused, and receptive, and above all, he makes a difference in helping us boost our leads. I heartily suggest this SEO experts team. - Morris Tyndall , I had been collaborating with an e-commerce-focused company for several years to design my website. The business provided me with all the required services, including occasional SEO audits",
    h3: "Diane Tate",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-2.png",
  },
  {
    brand: "/images/ic_business_insider_dark.svg",
    p: "Working with Rank Jacker was the right choice. They are SEO experts, and their experience will benefit your business. With them, your business will expand and gain exposure more quickly as they effortlessly traverse the new Google guidelines. Strongly recommended.",
    h3: "Jerry Page",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-3.jpg",
  },
  {
    brand: "/images/ic_legalzoom_dark.svg",
    p: "Rank Jacker went above and beyond to support the expansion of our online presence and delivered a much-needed boost. They provided us with a roadmap for content creation, which helped us rank higher for the content and drive more visitors to the website! Excellent outcomes and first-rate assistance.",
    h3: "D. Surrey",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-4.png",
  },
  {
    brand: "/images/ic_segate_dark.svg",
    p: "Rank Jacker went above and beyond to support the expansion of our online presence and delivered a much-needed boost. They provided us with a roadmap for content creation, which helped us rank higher for the content and drive more visitors to the website! Excellent outcomes and first-rate assistance.",
    h3: "D. Surrey",
    designation: "CEO, Segate",
    personSrc: "/images/h-c-5.png",
  },
];
function Home() {
  return (
    <>
      <HomeHero />
      <HomeWho />
      <HomeTestimonial data={Testimonial} />
      <HomeWorkspace
        tittle={"Maximizing Efficiency: The Art of Outsourcing Workspace"}
        subTitle={
          "Empowering enterprises to supercharge organic growth and revenue through elite marketing solutions and avant-garde SEO software."
        }
        buttonText={"Contact us"}
        onClick={() => false}
        subSectionTitle={
          "Good SEO Means Streamlined Success: And We Do It For You"
        }
        subSectionSubTitle={
          "Rank Jacker is an SEO agency and your growth catalyst, driving traffic and conversions like never before."
        }
        subSectionList={WorkspaceSubSectionData}
      />
      <HomeFaqs data={FaqHome} enableAnimation={true} />
      <Footer />
    </>
  );
}

export default Home;
