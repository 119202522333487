import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import AnimatedContainer from "../../Animations/AnimatedContainer";

const Data = Array.from({ length: 3 });

function HomeHero() {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={"/images/ic_arrow_dark.svg"}
        alt={"arrow"}
        className={styles.nextArrow}
        onClick={onClick}
      />
    );
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={"/images/ic_arrow_dark.svg"}
        alt={"arrow"}
        className={styles.prevArrow}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    variableWidth: true,
    centerMode: false,

    dotsClass: styles.button__bar,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const spring = {
    type: "spring",
    damping: 25,
    stiffness: 100,
  };

  return (
    <Container>
      s
      <div className={styles.wrapper}>
        <AnimatedContainer
          className={styles.left}
          variants={{
            visible: { opacity: 1, translateX: 0, scaleY: 1 },
            hidden: {
              opacity: 0,
              translateX: isMobile ? -100 : -500,
              scaleY: 0.8,
            },
          }}
          transition={spring}
        >
          <h1>We Define White Label SEO Dominance- as an</h1>
          <h1 className={styles.highlighted}>
            unrivaled choice for your ambitious business ideas.
          </h1>
          <p>
            We help you supercharge your service portfolio and skyrocket your
            revenue with an incredible white-label SEO and managed SEO
            partnership. Join forces with Rank Jacker for unmatched and scalable
            SEO success!
          </p>

          <div className={styles.buttonWrapper}>
            <Link to={"/contact-us"}>
              <PrimaryButton buttonText={"Contact us"} />
            </Link>
            <Link
              to={"https://calendly.com/bookadiscoverycall/15"}
              target={"_blank"}
            >
              <PrimaryButton buttonText={"Talk with expert"} outlined={true} />
            </Link>
          </div>
        </AnimatedContainer>
        <AnimatedContainer
          className={styles.right}
          variants={{
            visible: { opacity: 1, translateX: 0, scaleY: 1 },
            hidden: {
              opacity: 0,
              translateX: isMobile ? 100 : 500,
              scaleY: 0.8,
            },
          }}
          transition={spring}
        >
          <Slider {...settings}>
            <div>
              <img
                className={`${styles.heroIllus} ${isMobile && styles.mobile}`}
                src={"/images/i_hero_illus.png"}
                alt={"carousel item"}
              />
            </div>
            <div>
              <img
                className={`${styles.heroIllus} ${isMobile && styles.mobile}`}
                src={"/images/i_hero_illus.png"}
                alt={"carousel item"}
              />
            </div>
            <div>
              <img
                className={`${styles.heroIllus} ${isMobile && styles.mobile}`}
                src={"/images/i_hero_illus.png"}
                alt={"carousel item"}
              />
            </div>
          </Slider>
          <p className={styles.whoWeAre}>Who we are</p>
        </AnimatedContainer>
      </div>
    </Container>
  );
}

export default HomeHero;
