import Container from "../../Shared/Container";
import InputTextButton from "../../Shared/Input/Button";
import styles from "./styles.module.scss";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from "react";

const Data = [
  "/images/ic_databox_dark.svg",
  "/images/ic_business_insider_dark.svg",
  "/images/ic_legalzoom_dark.svg",
  "/images/ic_fiverr-dark.svg",
  "/images/ic_segate_dark.svg",
  "/images/ic_databox_dark.svg",
];

const CaseData = [
  {
    image: "/images/i_case_one.png",
    header: "PLUS SIZE CLOTHING",
    desc: "AMYDUS",
    link: "/case-study/ecommerce-seo-case-study"
  },
  {
    image: "/images/i_case_two.png",
    header: "CAR DEALERSHIP",
    desc: "SEO FOR CAR DEALERSHIP (150% TRAFFIC INCREASE!)",
    link: "/case-study/car-dealership-seo"
  },
  {
    image: "/images/i_case_three.png",
    header: "LAW FIRM SEO",
    desc: "LAW FIRM SEO: FROM 500 TO 7,000 6 MONTHLY VISITORS",
    link: "/case-study/law-firm-seo-growth"
  },
  {
    image: "/images/i_case_one.png",
    header: "DENTAL LINK BUILDING",
    desc: "WEBSITE JUMPS FROM ZERO TO 17,304 VISITORS PER/MONTH WITH BACKLINKS!",
    link: "/case-study/dental-link-building-case-study"
  },
  {
    image: "/images/i_case_two.png",
    header: "LOCAL SEO",
    desc: "LOCAL FOREIGN LANGUAGE SEO CASE STUDY (HUGE ROI ON SEO)",
    link: "/case-study/local-seo"
  },

];

const CaseStudiesList = () => {
  const [selected, setSelected] = useState(null);
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  const navigate = useNavigate();

  return (
    <div className={styles.wrapper} >
      <Container>
        <div className={styles.content}>
          <div className={styles.partners}>
            {Data.map((item, index) => {
              return (
                <img className={styles.logoImage} src={item} alt={"logo"} />
              );
            })}
          </div>

          <div className={styles.buttonWrapper}>
            {/* <div className={styles.button} onClick={() => {
              if (selected === 1) {
                setSelected(null);
              } else setSelected(1)
            }}>
              <p>Select your service</p>
              <img
                className={styles.arrow}
                src={"/images/ic_down_arrow.svg"}
                alt={"carousel item"}
              />
              {selected === 1 && <div className={styles.dropdown}>
                <p className={styles.item}>
                  Serive One
                </p>
                <hr />
                <p className={styles.item}>
                  Serive Two
                </p>
                <hr />
                <p className={styles.item}>
                  Serive Three
                </p>
                <hr />
                <p className={styles.item}>
                  Serive Four
                </p>
              </div>}
            </div> */}
            {/* <div className={styles.button} onClick={() => {
              if (selected === 2) {
                setSelected(null);
              } else setSelected(2)
            }}>
              <p>Select your industry</p>
              <img
                className={styles.arrow}
                src={"/images/ic_down_arrow.svg"}
                alt={"carousel item"}
              />

              {selected === 2 && <div className={styles.dropdown}>
                <p className={styles.item}>
                  Serive One
                </p>
                <hr />
                <p className={styles.item}>
                  Serive Two
                </p>
                <hr />
                <p className={styles.item}>
                  Serive Three
                </p>
                <hr />
                <p className={styles.item}>
                  Serive Four
                </p>
              </div>}
            </div> */}
            {/* {!isMobile && <div className={styles.button} onClick={() => {
              if (selected === 3) {
                setSelected(null);
              } else setSelected(3)
            }}>
              <p>Select your service</p>
              <img
                className={styles.arrow}
                src={"/images/ic_down_arrow.svg"}
                alt={"carousel item"}
              />
              {selected === 3 && <div className={styles.dropdown}>
                <p className={styles.item}>
                  Serive One
                </p>
                <hr />
                <p className={styles.item}>
                  Serive Two
                </p>
                <hr />
                <p className={styles.item}>
                  Serive Three
                </p>
                <hr />
                <p className={styles.item}>
                  Serive Four
                </p>
              </div>}
            </div>} */}
          </div>

          <div className={styles.cardContainer}>
            {CaseData.map((item, index) => {
              return (
                <div className={styles.cards} onClick={() => navigate(item.link)}>
                  <img
                    className={styles.banner}
                    src={item.image}
                    alt={"item"}
                  />
                  <div className={styles.textWrapper}>
                    <div
                      className={`${styles.header} ${index % 2 !== 0 && styles.middle}`}
                    >
                      {item.header}
                    </div>
                    <div className={styles.desc}>{item.desc}</div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.news}>
            <h1>Subscribe to our newsletter</h1>
            <p>
              Jack ranker was a name that reached the masses and got everyone
              intrigued and smiling. With a deeper meaning, it brings ou
            </p>
            <div className={styles.buttonWrapper}>
              <input name="myInput" placeholder={'Your Email'} className={styles.inputField} />
              <img className={styles.icon} src={"/images/ic_arrow_dark.svg"} alt={'carousel item'} />

            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CaseStudiesList;
