import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import SwitchButton from "../../Shared/Buttons/Switch";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Data = Array.from({ length: 3 });

const mainData = {
  "Sandbox Stage": [
    {
      icon: "/images/ic_niche_two.svg",
      header: "Foundation Packages",
      desc: `Layout a solid foundation with high DA Diversity links and a diverse anchor profile, pushing trust and relevance.`,
      anchors: ["Branded Keywords", "Generic Links", "Naked URL"],
      orientation: [
        "Increases Brand Awareness, Positioning & Appeal",
        "To create relevance and Trust",
        "Push Authority",
      ],
      outcome: [
        "Helps Push through Google Sandbox",
        "Initial Link Boost & Authority",
        "Anchor Profile Diversification",
      ],
      link: "https://rankjacker.spp.io/order/foundation-package",
    },
    {
      icon: "/images/ic_guest_two.svg",
      header: "PR",
      desc: `Gives your website extensive worldwide exposure and aids in building the crucial brand identity`,
      anchors: ["Branded Keywords", "Generic Links", "Naked URL"],
      orientation: [
        "Increases Brand Awareness, Positioning & Appeal",
        "To create relevance and Trust",
        "Push Authority",
      ],
      outcome: [
        "Helps Push through Google Sandbox",
        "Initial Link Boost & Authority",
        "Anchor Profile Diversification",
      ],
      link: "https://rankjacker.spp.io/order/QL3JO6",
    },
    {
      icon: "/images/ic_traffic.svg",
      header: "Citation",
      desc: `Ensure your brand stands out among competitors by securing listings in top directories.`,
      anchors: ["Branded Keywords", "Generic Links", "Naked URL"],
      orientation: [
        "Increases Brand Awareness, Positioning & Appeal",
        "To create relevance and Trust",
        "Push Authority",
      ],
      outcome: [
        "Helps Push through Google Sandbox",
        "Initial Link Boost & Authority",
        "Anchor Profile Diversification",
      ],
      link: "https://rankjacker.spp.io/order/advance-local-citation",
    },
  ],
  "Growth stage": [
    {
      icon: "/images/ic_traffic.svg",
      header: "HARO Links",
      desc: `HARO links from high-authority articles improve SEO performance.`,
      anchors: [
        "Long Tail Keywords",
        "Synonyms",
        "Alternate Keywords",
        "Branded",
      ],
      orientation: [
        "Informational",
        "Listicles",
        "Comparison",
        "Alternate View",
      ],
      outcome: [
        "Pushes KW Relevance & Trust",
        "Brand Positioning",
        "Helps SERP Ranking Improvement",
      ],
      link: "https://rankjacker.spp.io/order/haro-link",
    },
    {
      icon: "/images/ic_niche_two.svg",
      header: "Niche Edit",
      desc: `Take control of your guest posts' approval on authentic websites.`,
      anchors: [
        "Long Tail Keywords",
        "Synonyms",
        "Alternate Keywords",
        "Branded",
      ],
      orientation: [
        "Informational",
        "Listicles",
        "Comparison",
        "Alternate View",
      ],
      outcome: [
        "Pushes KW Relevance & Trust",
        "Brand Positioning",
        "Helps SERP Ranking Improvement",
      ],
      link: "https://rankjacker.spp.io/order/ELO2Z3",
    },
    {
      icon: "/images/ic_guest_two.svg",
      header: "Edu Links",
      desc: `Harness the power of .edu backlinks—links from official academic institution websites.`,
      anchors: [
        "Long Tail Keywords",
        "Synonyms",
        "Alternate Keywords",
        "Branded",
      ],
      orientation: [
        "Informational",
        "Listicles",
        "Comparison",
        "Alternate View",
      ],
      outcome: [
        "Pushes KW Relevance & Trust",
        "Brand Positioning",
        "Helps SERP Ranking Improvement",
      ],
      link: "/",
    },
  ],
  "Authority Stage": [
    {
      icon: "/images/ic_guest_two.svg",
      header: "Niche Edits",
      desc: `Niche edits, sometimes referred to as curated or contextual links, are links and references.`,
      anchors: ["Keyword", "Synonyms", "Variations"],
      orientation: ["Infulensive", "Authoritative"],
      outcome: [
        "Targeted Keyword Rankings",
        "Competitive Advantage",
        "Authority Building",
      ],
      link: "/services/niche-edit-links",
    },
    {
      icon: "/images/ic_niche_two.svg",
      header: "Premium Guest Posting",
      desc: `Get the best white hat link-building service at wholesale pricing with our manual blogger outreach program. Ask for a Quote.`,
      anchors: ["Keyword", "Synonyms", "Variations"],
      orientation: ["Infulensive", "Authoritative"],
      outcome: [
        "Targeted Keyword Rankings",
        "Competitive Advantage",
        "Authority Building",
      ],
      link: "https://rankjacker.spp.io/order/7ZN16P",
    },
    {
      icon: "/images/ic_traffic.svg",
      header: "PBN Links",
      desc: `We have the best inventory available whether you want to buy or rent PBN links.`,
      anchors: ["Keyword", "Synonyms", "Variations"],
      orientation: ["Infulensive", "Authoritative"],
      outcome: [
        "Targeted Keyword Rankings",
        "Competitive Advantage",
        "Authority Building",
      ],
      link: "https://rankjacker.spp.io/order/2LRQ63",
    },
  ],
};

const DescData = {
  "Sandbox Stage":
    "New to the online game or struggling with website traffic? You might be in the Google Sandbox. Build essential links to gain the traction you need.",
  "Growth stage": `Level up your website's momentum as it emerges from the sandbox. Fuel its growth with the right link juice and authority for continued success on the path to the top.`,
  "Authority Stage": `This stage is the best time to advocate for KW anchors and establish a strong competitive edge in the search engine rankings.`,
};

function SureSection() {
  const [active, setActive] = useState("Sandbox Stage");
  const navigate = useNavigate();

  return (
    <Container>
      <div className={styles.sureSection}>
        <div className={styles.headerSection}>
          <h1>Not sure what you want?</h1>
          <div className={styles.buttonCont}>
            <SwitchButton
              buttonTexts={["Sandbox Stage", "Growth stage", "Authority Stage"]}
              onClick={(i) => setActive(i)}
            />
          </div>
          <p>{DescData[active]}</p>
        </div>

        <div className={styles.cardsSection}>
          {mainData[active].map((item, index) => {
            return (
              <div className={styles.cards}>
                <img
                  className={styles.logoImage}
                  src={item.icon}
                  alt={"logo"}
                />
                <h2>{item.header}</h2>
                <p>{item.desc}</p>
                <Link to={item.link}>
                  <div className={styles.buttonCont}>Get started</div>
                </Link>

                <hr />

                <h2 className={styles.highlight}>Suggested Anchors</h2>
                <ul>
                  {item.anchors.map((item) => {
                    return <li>{item}</li>;
                  })}
                </ul>

                <hr />

                <h2 className={styles.highlight}>Content Orientation</h2>
                <ul>
                  {item.orientation.map((item) => {
                    return <li>{item}</li>;
                  })}
                </ul>

                <hr />

                <h2 className={styles.highlight}>Expected OUTCOME</h2>
                <ul>
                  {item.outcome.map((item) => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export default SureSection;
