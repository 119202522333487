import Container from "../../Shared/Container";
import InputTextButton from "../../Shared/Input/Button";
import styles from "./styles.module.scss";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

const Data = [
  {
    header: "Agencies Served",
    desc: "1500+",
  },
  {
    header: "Link each year",
    desc: "30K+",
  },
  {
    header: "SEO Projects",
    desc: "700+",
  },
  {
    header: "Total Experience",
    desc: "8+",
  },
];

const DFYExperts = ({
  showBanner = true,
  header,
  bannerHeader,
  bannerDesc,
  buttonText,
  leftHeader,
  leftDesc,
  leftBottomOne,
  leftBottomTwo,
  leftBottomThree,
  leftBottomFour,
  buttonLink,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            {header ? header : "Why you should work with us"}
          </div>
          <div className={styles.content}>
            <div className={styles.left}>
              <div className={styles.first}>
                {leftHeader
                  ? leftHeader
                  : "Many benefits customer gets working with us!"}
              </div>
              <div className={styles.second}>
                {leftDesc
                  ? leftDesc
                  : "Our software arms researchers with powerful data solutions that are simple to implement and utilize."}
              </div>

              <div className={styles.list}>
                <div className={styles.items}>
                  <img
                    className={styles.stars}
                    src={"/images/ic_hands.svg"}
                    alt={"stars"}
                  />
                  <p>{leftBottomOne ? leftBottomOne : "Hand Free SEO"}</p>
                </div>
                <div className={styles.items}>
                  <img
                    className={styles.stars}
                    src={"/images/ic_plan.svg"}
                    alt={"stars"}
                  />
                  <p>{leftBottomTwo ? leftBottomTwo : "Customized Plan"}</p>
                </div>
                <div className={styles.items}>
                  <img
                    className={styles.stars}
                    src={"/images/white-label.svg"}
                    alt={"stars"}
                  />
                  <p>{leftBottomThree ? leftBottomThree : "White Label SEO"}</p>
                </div>
                <div className={styles.items}>
                  <img
                    className={styles.stars}
                    src={"/images/result.svg"}
                    alt={"stars"}
                  />
                  <p>{leftBottomFour ? leftBottomFour : "Result Driven"}</p>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <div className={`${styles.items} ${styles.diff}`}>
                <img
                  className={styles.linking}
                  src={"/images/ic_linking.png"}
                  alt={"stars"}
                />
                <h3>
                  Bank Linking
                  <br /> benefits
                </h3>
              </div>
              <div className={styles.items}>
                <h2>1500+</h2>
                <p>Agencies served</p>
              </div>
              <div className={styles.items}>
                <h2>700+</h2>
                <p>SEO clients</p>
              </div>
              <div className={styles.items}>
                <h2>30K+</h2>
                <p>Links each year</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {showBanner && (
        <div className={styles.banner}>
          <Container>
            <div className={styles.content}>
              <h1>
                {bannerHeader ? bannerHeader : "Claim Your Free SEO Audit Now"}
              </h1>
              <p>
                {bannerDesc
                  ? bannerDesc
                  : "Want to discover how your website can perform better in search rankings?"}
              </p>
              <Link to={buttonLink ? buttonLink : "/contact-us"}>
                <div className={styles.buttonCont}>
                  {buttonText ? buttonText : "Talk to expert"}
                </div>
              </Link>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default DFYExperts;
