import React from "react";

import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
import { Link } from "react-router-dom";

const PricingTable = () => {
  return (
    <Container>
      <div className={styles.innerContainer}>
        <h5> Pricing and Options</h5>
        <p>
          Through your RJ dashboard, you can Buy Pbn Links in a sizable
          quantity. You can get special wholesale pricing. Be it 10 or 60, each
          and every link of yours is posted safely on our high-quality blog
          network.
        </p>

        <div className={styles.wrapper}>
          <div></div>
          <div className={styles.hightlighted}>Basic</div>
          <div className={styles.hightlighted}>Standard</div>
          <div className={styles.hightlighted}>Premium</div>
          <div className={styles.title}>Powerful PR syndication(DF)</div>
          <div className={styles.value}>350+</div>
          <div className={styles.value}>350+</div>
          <div className={styles.value}>350+</div>
          <div className={styles.title}>High DA social bookmarks(DF)</div>
          <div className={styles.value}>8</div>
          <div className={styles.value}>8</div>
          <div className={styles.value}>8</div>
          <div className={styles.title}>Popular Social Brand Profiles (DF)</div>
          <div className={styles.value}>30</div>
          <div className={styles.value}>50</div>
          <div className={styles.value}>60</div>
          <div className={styles.title}>High DA Web 2.0 Links (DF)</div>
          <div className={styles.value}>10</div>
          <div className={styles.value}>15</div>
          <div className={styles.value}>20</div>
          <div className={styles.title}>PDF Creation & Posting (DF)</div>
          <div className={styles.value}>7</div>
          <div className={styles.value}>10</div>
          <div className={styles.value}>15</div>
          <div className={styles.title}>Local Directories (DF/NF)</div>
          <div className={styles.value}>25</div>
          <div className={styles.value}>40</div>
          <div className={styles.value}>80</div>
          <div className={styles.title}>Profiles From EDU Websites (DF)</div>
          <div className={styles.value}>10</div>
          <div className={styles.value}>15</div>
          <div className={styles.value}>20</div>
          <div className={styles.title}>High DA Wikimedia Links (NF)</div>
          <div className={styles.value}>10</div>
          <div className={styles.value}>15</div>
          <div className={styles.value}>20</div>
          <div className={styles.title}>
            <strong> Paid Resource Links</strong>
          </div>
          <div className={styles.value}>5</div>
          <div className={styles.value}>10</div>
          <div className={styles.value}>15</div>

          <div className={`${styles.hightlighted} ${styles.bottom}`}>
            Paid Resource Links
          </div>
          <div className={`${styles.hightlighted} ${styles.bottom}`}>455</div>
          <div className={`${styles.hightlighted} ${styles.bottom}`}>513</div>
          <div className={`${styles.hightlighted} ${styles.bottom}`}>598</div>
          <div></div>
          <div className={styles.cta}>
            <Link to={"https://rankjacker.spp.io/order/foundation-package"}>
              Order now
            </Link>
          </div>
          <div className={styles.cta}>
            <Link to={"https://rankjacker.spp.io/order/foundation-package"}>
              Order now
            </Link>
          </div>
          <div className={styles.cta}>
            <Link to={"https://rankjacker.spp.io/order/foundation-package"}>
              Order now
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default PricingTable;
