import styles from './styles.module.scss';
import Container from '../../Shared/Container';
import PrimaryButton from '../../Shared/Buttons/Primary';
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';
import SwitchButton from '../../Shared/Buttons/Switch';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const Data = Array.from({ length: 3 });

const mainData = [
  {
    icon: '/images/sure_2.png',
    header: 'Basic',
    price: '$45 per link',
    desc: `Ahref ref-domain upto 400`,
    anchors: ['1 Anchor Text/Target URL', 'Permanent, Do-follow backlink ', '100% Spam Free Link', 'Link From General Sites & Niche Relevant Post', 'No PBN/Link Farms'],
    orientation: ['Increases Brand Awareness, Positioning & Appeal', 'To create relevance and Trust', 'Push Authority'],
    outcome: ['Helps Push through Google Sandbox', 'Initial Link Boost & Authority', 'Anchor Profile Diversification'],
    link: '/services/foundation-packages/'
  },
  {
    icon: '/images/ic_guest_two.svg',
    header: 'Standard',
    price: '$55 per link',
    desc: `Aherf Ref-Domain 400 to 1000`,
    anchors: ['1 Anchor Text/Target URL', 'Permanent, Do-follow backlink ', '100% Spam Free Link', 'Link From General Sites & Niche Relevant Category', 'No PBN/Link Farms'],
    orientation: ['Increases Brand Awareness, Positioning & Appeal', 'To create relevance and Trust', 'Push Authority'],
    outcome: ['Helps Push through Google Sandbox', 'Initial Link Boost & Authority', 'Anchor Profile Diversification'],
    link: '/services/best-pr-distribution/'
  },
  {
    icon: '/images/ic_traffic.svg',
    header: 'Professional',

    price: '$75 per link',
    desc: `Min. Ahrefs Ref-Domain
      1000+`,
    anchors: ['1 Anchor Text/Target URL', 'Permanent, Do-follow backlink ', '100% Spam Free Link', 'Link From Niche Relevant sites & Min Traffic 1000', 'No PBN/Link Farms'],
    orientation: ['Increases Brand Awareness, Positioning & Appeal', 'To create relevance and Trust', 'Push Authority'],
    outcome: ['Helps Push through Google Sandbox', 'Initial Link Boost & Authority', 'Anchor Profile Diversification'],
    link: '/services/advanced-local-citation-service'
  },
  {
    icon: '/images/ic_traffic.svg',
    header: 'Elite',
    price: '$110 per link',
    desc: `Ahrefs Red-Domain 3000+`,
    anchors: ['1 Anchor Text/Target URL', 'Permanent, Do-follow backlink ', '100% Spam Free Link', 'Link From Niche Relevant sites & Min Traffic 3000', 'No PBN/Link Farms'],
    orientation: ['Increases Brand Awareness, Positioning & Appeal', 'To create relevance and Trust', 'Push Authority'],
    outcome: ['Helps Push through Google Sandbox', 'Initial Link Boost & Authority', 'Anchor Profile Diversification'],
    link: '/services/advanced-local-citation-service'
  }
]


function DIYPackages() {
  const navigate = useNavigate();

  return (
    <Container>
      <div className={styles.sureSection}>
        <div className={styles.headerSection}>
          <h1>
            Buy Niche edit packages
          </h1>
        </div>

        <div className={styles.cardsSection}>
          {mainData.map((item, index) => {
            return (
              <div className={styles.cards}>
                <img className={styles.logoImage} src={item.icon} alt={'logo'} />
                <h2>
                  {item.header}
                </h2>
                <p>
                  {item.desc}
                </p>

                <div className={styles.buttonCont} onClick={() => navigate(item.link)}>
                  {item.price}
                </div>

                {item.anchors.map((anch) => {
                  return (
                    <div>
                      <h2 className={styles.highlight}>
                        {anch}
                      </h2>

                      <hr />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </Container>
  );
}

export default DIYPackages;
