import styles from "./styles.module.scss";
import Container from "../../Shared/Container";
const OurServicesCS = () => {
  return (
    <Container>
      <div className={styles.wrapper}>
        <h5 className={styles.head}>Our Services</h5>
        <p className={styles.subhead}>
          A comprehensive range of content writing services designed to meet the
          unique needs of your business. Our team of skilled writers is
          proficient in creating:
        </p>
        <div className={styles.list}>
          <div className={styles.card}>
            <div className={styles.icon}>
              <img src="/images/cs-service-1.svg" alt="" />
            </div>
            <div className={styles.title}>Blog Content</div>
            <div className={styles.subtitle}>
              Informative, engaging, and optimized blog posts that resonate with
              your audience and improve your SEO.
            </div>
          </div>  <div className={styles.card}>
            <div className={styles.icon}>
              <img src="/images/cs-service-2.svg" alt="" />
            </div>
            <div className={styles.title}>Landing Page Content</div>
            <div className={styles.subtitle}>
              Persuasive and strategically written landing pages that drive conversions and sales.
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default OurServicesCS;
