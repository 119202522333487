import PrimaryButton from "../../Shared/Buttons/Primary";
import Container from "../../Shared/Container";
import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import LottiePlayer from "../LottiePlayer";

const AboutUsDetails = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <div className={styles.wrapper}>
      <Container>
        <p className={styles.growth}>Growth . Expand . Scale</p>
      </Container>

      <img
        className={styles.bridge}
        src={"/images/i_bridge.png"}
        alt={"logo"}
      />
      {/* <img className={styles.bridge} src={'/images/i_road.png'} alt={'logo'} /> */}
      <LottiePlayer />

      <Container>
        <div className={styles.content}>
          <div className={styles.subHeader}>
            Our intriguing brand name, Rank Jacker, represents our steadfast
            dedication to achieving the highest search engine ranks,{" "}
            <span className={styles.highlight}>
              outperforming rivals and maintaining online supremacy for SEO
              experiences.
            </span>
          </div>

          <img
            className={styles.rocket}
            src={"/images/i_rocket.png"}
            alt={"logo"}
          />

          <div className={styles.headerAndButton}>
            <p>
              We're fervent experts in top keyword rankings and revenue
              generation and are committed to automating and scaling your SEO.
              Our global clients attest to our success.
            </p>
            <div className={styles.buttonWrapper}>
              <Link to={"https://calendly.com/bookadiscoverycall/15"}>
                <PrimaryButton
                  buttonText={"Contact us"}
                  dark={true}
                  onClick={() => false}
                />
              </Link>
            </div>
          </div>
        </div>
      </Container>

      <div className={styles.multiImage}>
        {!isMobile && (
          <img
            className={styles.one}
            src={"/images/i_about_one.png"}
            alt={"logo"}
          />
        )}
        <img
          className={styles.two}
          src={"/images/i_about_two.png"}
          alt={"logo"}
        />
        <img src={"/images/i_about_three.png"} alt={"logo"} />
        <img
          className={styles.two}
          src={"/images/i_about_four.png"}
          alt={"logo"}
        />
        {!isMobile && (
          <img
            className={styles.one}
            src={"/images/i_about_five.png"}
            alt={"logo"}
          />
        )}
      </div>
    </div>
  );
};

export default AboutUsDetails;
