import Container from "../../Shared/Container";
import styles from "./styles.module.scss";

const PrivacyHero = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.bgCont} />
            <Container>
                <div>
                    <div className={styles.header}>
                        <p>
                            {`Home > Privacy Policy`}
                        </p>

                        <h1>
                            Privacy Policy
                        </h1>
                    </div>
                    <div className={styles.main}>
                        <h3>
                            Privacy Policy
                        </h3>
                        <p>
                            Your privacy is our responsibility and is important to us. Keeping in mind your privacy and your rights, the corporate has developed this policy to form you clear on the concepts like how the corporate collects, use, communicates, and makes use of all of your personal information.
                            <br /><br />
                            Outlines For Our Privacy Policy Are Given Below:
                            When you are accessing our website link website, RankJacker then, the corporate will learn some information about you during your visit.
                            <br /><br />
                            Similar to other commercial websites, our website also uses a regular technology named “cookies” (see explanation below) and server logs, which collect knowledge about how you use our website.
                            <br /><br />
                            Information obtained through cookies and server logs may consist of the date and time of visits, the number of pages viewed, the total time you spent at our site, and also the websites visited just before and just after our own, similarly to your IP address.
                        </p>

                        <h3>
                            Use Of Cookies:
                        </h3>
                        <p>
                            A cookie may be a tiny text document, which consists of an anonymous unique identifier. Whenever you visit an internet site, the actual site inquires about your computer for a grant to store part of a file on the disk drive specifically designated for cookies.
                            Every website sends its cookie to the browser given that you permit your browser’s preferences, but (to protect your privacy) your browser only permits an internet site to access the cookies it’s already sent to you, not the cookies sent to you by other sites.
                        </p>
                        <h3>
                            IP Addresses:
                        </h3>
                        <p>
                            The computer uses IP addresses whenever you employ a web connection. The IP address may be a number that computer users search out your computer within the network.
                            The is automatically collected by the net server for the demographic and profile data, usually called “traffic data” so that data (such because the web content you request) will be sent to you.
                        </p>

                        <h3>
                            Email Information:
                        </h3>
                        <p>
                            If you decide to retort us through email, we may maintain a record of the content of your email messages following your email address and our responses. An identical procedure is going to be followed up for all electronic communications.
                            For any longer information, kindly see the e-mail policies below.
                            <br /><br />
                            How Will We Use The Data That You Simply Provide To Us?
                            Broadly speaking, private information is employed for several purposes including administering business activities, providing customer care service, and creating available items and services which are demanded by our customers.
                            <br /><br />
                            RankJacker does not gather the personally-identifying information about you unless you’re able to provide such information to us. Neither the knowledge provided to us is sold nor transferred to unaffiliated third parties without your consent.
                        </p>

                        <h3>
                            Email Policies:
                        </h3>
                        <p>
                            All the e-mail address is kept confidential. The corporate isn’t engaged in any style of selling, renting, or leasing our subscription lists to 3rd parties.
                            <br /><br />
                            All the knowledge is kept as a secret and isn’t provided to any third party individual, administrative body, or company at any point of your time until and unless the corporate is strictly compelled to try and do so by law.
                            <br /><br />
                            Your e-mail address is going to be solely accustomed to providing timely information about RankJacker. We are going to keep a record of the data you send via e-mail following applicable federal law.
                        </p>

                        <h3>
                            CAN-SPAM Compliance:
                        </h3>
                        <p>
                            In compliance with the CAN-SPAM Act, all e-mail received by you thru our organization declares that e-mail provides clear information regarding a way to contact the sender.
                            Besides, e-mail messages to encompass concise information regarding a way to remove you from our listing for receiving no further e-mail communication from us.
                        </p>

                        <h3>
                            Choice/Opt-Out:
                        </h3>
                        <p>
                            The company’s website grants users the chance to opt out of receiving communications from us and our partners by reading the unsubscribe instructions located at The underside of any e-mail they receive from us at any time.
                            All the users who wish to not receive our newsletter or promotional materials can unsubscribe to our newsletter.
                        </p>

                        <h3>
                            Use Of External Links:
                        </h3>
                        <p>
                            Website links may comprise links to several other websites. RankJacker doesn’t give any guarantee in terms of the accuracy of knowledge found on any linked site.
                            Links to or from external sites, which don’t seem to be owned or controlled by RankJacker don’t constitute an endorsement by RankJacker or any of its employees of the sponsors of those sites or the products or information presented therein.
                            <br /><br />
                            By accessing this website, you comply with and be bound by this website’s Terms and Conditions of Use which apply all the applicable laws, rules, and regulations. You additionally agree to comply with any applicable local laws.
                            <br /><br />
                            Just in case you are doing wish to trust the present terms and conditions, you’re prohibited from using or accessing this site. The materials on the website are protected by applicable copyright and trademark law.
                        </p>

                        <h3>
                            Intellectual Property Rights:
                        </h3>
                        <p>
                            RankJacker or its licensors have the only property of all copyrights, trademarks, patents, and other holding rights for all the content and software updated on the location.
                            Kindly don’t use any of our trademarks, content, and property without the express written consent from RankJacker:
                        </p>

                        <h3>
                            Don’t:
                        </h3>
                        <p>
                            Republish any material on our website without prior written consent.
                            Sell or to-let material from our website.
                            Recreate or duplicate any create derivative exploit material on our website for any purpose.
                            Redistribute the content from our website, even to any other website.
                        </p>

                        <h3>
                            Acceptable Use:
                        </h3>
                        <p>
                            You comply with using our website by keeping the cyber laws in mind. In any way, it doesn’t infringe on the rights or restrict or inhibit anyone else’s use and pleasure of the website.
                            <br /><br />
                            Prohibited behavior consists of harassing or causing harm or any style of inconvenience to a different user, sending obscene or offensive content, and breaking down the flow of dialogue within the website.
                            <br /><br />
                            Kindly don’t use our website to send unsolicited commercial communications. You’re not allowed to use the content of the website for any style of marketing-related strategy without our express written consent.
                        </p>

                        <h3>
                            Restricted Access:
                        </h3>
                        <p>
                            The company has secured the correct to limit access to some parts (or all) of our website in the future.
                            In this case, at any point, the corporate offers you a username and password for you to access restricted areas of our website.
                            Kindly make sure that you retain your authentication confidential.
                        </p>

                        <h3>
                            Use Of Testimonials:
                        </h3>
                        <p>
                            With relevance to FTC guidelines concerning the employment of endorsements and testimonials in advertising, please bear in mind the following:
                            <br /><br />
                            Testimonials that appear on the positioning are received through text, audio, or video submission. The corporate doesn’t declare them as any official results.
                            <br /><br />
                            These don’t seem to be taken as necessarily representative of all of the people that are willing to use our products and/or services.
                            <br /><br />
                            The representation of testimonials on this site (text, audio, video, or other) is reproduced verbatim, aside from correction of grammatical or typing errors.
                            <br /><br />
                            In other words, not the whole message received by the testimonial writer is shown because it seems longer or not the complete statement seems appropriate for the overall public.
                            <br /><br />
                            The testimonials don’t claim that the products and/or services should be accustomed to diagnosing, treating, curing, mitigating, or preventing any disease. Any such claims, implicit or explicit, in any shape or form, haven’t been clinically tested or evaluated.
                            <br /><br />
                            How Will We Protect Your Information & Secure Information Transmissions?
                            According to the company, Email isn’t recognized as a secure medium of communication. So, the company advises you not to send private information to us by email.
                            <br /><br />
                            However, if you are still doing so, the work is solely at your own risk. MasterCard information and other sensitive information aren’t transmitted via email.
                            <br /><br />
                            RankJacker may use software programs to form summary statistics, which are used for such purposes as assessing the number of holidaymakers to the various sections of our site, what information is of most and least interest, determining technical design specifications, and identifying system performance or problem areas.
                            <br /><br />
                            For site security purposes and to confirm that this service remains available to all or any users, RankJacker uses software programs to watch network traffic to spot unauthorized attempts to upload or change information, or otherwise cause damage.
                        </p>

                        <h3>
                            Disclaimer & Limitation Of Liability:
                        </h3>
                        <p>
                            RankJacker doesn’t have any representations, warranties, or assurances that may be marked on the correctness, currency, or completeness of the work made available on this website or any sites linked to the present site.
                            <br /><br />
                            All the knowledge provided on this site is marked “as is” without expressing any implied warranty of any kind, consisting of warranties of merchantability, no infringement of property, or fitness for any particular purpose.
                            <br /><br />
                            RankJacker or its agents or associates be not held chargeable for any destruction or harms consisting of but aren’t limited to the damages arising just in case of any loss or profits, business interruption, loss of data, injury, or death.
                            <br /><br />
                            If any case arises out of such situations RankJacker has priory advised of the likelihood of such loss or damages.
                        </p>

                        <h3>
                            Policy Changes:
                        </h3>
                        <p>
                            The company secures the proper to alter the privacy policy at any point in your time with none prior. However, kindly keep yourself updated as no personal emails would be dropped to you just in case of any amendments.
                            <br /><br />
                            The company shows full commitment to doing business following the principles which protect the confidentiality of private information and therefore the integrity is maintained.
                        </p>

                        <h3>
                            Contact:
                        </h3>
                        <p>
                            For any more details regarding the above-mentioned policy or related to your website.
                            Kindly be at liberty to contact us by email and address
                        </p>
                    </div>

                    <div className={styles.collabBanner}>
                        <img src={"/images/ic_rocket.svg"}
                            alt={"rocket"}
                            className={styles.rocket} />
                        <img src={"/images/i_collaborate_pattern.svg"}
                            alt={"pattern"}
                            className={styles.pattern} />
                        <div>
                            <h2>
                                Have a project in mind?
                            </h2>
                            <h2>
                                Let’s collaborate
                            </h2>
                        </div>

                        <div className={styles.button}>
                            <p>
                                Get Started Today
                            </p>
                            <img src={"/images/ic_arrow_dark.svg"}
                                alt={"arrow"}
                                className={styles.arrow} />
                        </div>

                    </div>
                </div>
            </Container>
        </div>
    )
};

export default PrivacyHero;