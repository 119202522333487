import DFYClients from "../../../components/DFY/Clients";
import DFYDetails from "../../../components/DFY/Details";
import DFYExperts from "../../../components/DFY/Experts";
import DFYGrow from "../../../components/DFY/Grow";
import DFYHero from "../../../components/DFY/Hero";
import Footer from "../../../components/Footer";
import HomeTestimonial from "../../../components/Home/Testimonial";
import HomeFaqs from "../../../components/Home/Faqs";
import Container from "../../../components/Shared/Container";
import { useMediaQuery } from "react-responsive";
import styles from "./styles.module.scss";
import DIYClients from "../../../components/DIY/Clients";

const Data = [
  "/images/ic_databox.svg",
  "/images/ic_business_insider.svg",
  "/images/ic_legalzoom.svg",
  "/images/ic_partner_one.svg",
  "/images/ic_segate.svg",
];
const MobileData = [
  "/images/ic_databox.svg",
  "/images/ic_business_insider.svg",
  "/images/ic_legalzoom.svg",
  "/images/ic_partner_one.svg",
  "/images/ic_segate.svg",
  "/images/ic_segate.svg",
];

const DominateData = [
  {
    header: "Anaylzing your website",
    desc: "Upon ordering your audit, we scrutinize your site for SEO gaps hindering first-page Google rankings. Our checklist covers essential ranking factors, followed by an in-depth audit, including UI-UX design, mobile friendliness, and site speed.",
    image: "/images/ic_complement_one.svg",
  },
  {
    header: "Detailed Reporting",
    desc: "We provide you with a detailed audit report that cover every minute detail which needs to be improved/improvised on your site, for your site to rank on the first page of Google. Throghout the process we keep you reported so that you are in full control with the process.",
    image: "/images/ic_complement_two.svg",
  },
  {
    header: "Training and Recommendation",
    desc: "Along with the detailed report we also provide a training session where we teach your team how to recitify the errors in the audit and implement the techniques and strategies that are needed to improve your website ranking.        ",
    image: "/images/ic_complement_three.svg",
  },
  {
    header: "Done For You",
    desc: "If you run short of time and wish to skip all the hustle then our in-house team of SEO experts can help you shoot up your ranking without you lifting a finger.",
    image: "/images/ic_complement_four.svg",
  },
];

const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's SEO Audit service completely transformed our online presence. Their thorough analysis uncovered hidden issues and provided actionable insights, resulting in a significant boost in our search engine rankings.",
    h3: "Jennifer Smith",
    designation: "CEO",
    personSrc: "/images/Jennifer Smith.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We were struggling to understand why our website wasn't performing as expected until we tried Rank-Jacker SEO's SEO Audit service. Their detailed report helped us identify and fix critical SEO issues, leading to a noticeable increase in organic traffic within weeks.",
    h3: "Sarah Johnson",
    designation: "Marketing Manager",
    personSrc: "/images/Elisha Levine.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's SEO Audit service exceeded our expectations. Their team went above and beyond to provide a comprehensive breakdown of our website's SEO health, along with tailored recommendations for improvement. We saw a remarkable improvement in our search visibility shortly after implementing their suggestions.",
    h3: "David Lee",
    designation: "Founder",
    personSrc: "/images/David Lee.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Choosing Rank-Jacker SEO for our SEO Audit was a game-changer for our business. Their in-depth analysis pinpointed areas where we were falling short and provided actionable strategies to address them. The results were remarkable, with a noticeable increase in organic traffic and higher search engine rankings.",
    h3: "Evelyn Hansley",
    designation: "Marketing Director",
    personSrc: "/images/Evelyn Hansley.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We can't thank Rank-Jacker SEO enough for their exceptional SEO Audit service. Their team's expertise and attention to detail helped us identify and resolve critical SEO issues that we weren't even aware of. Our website's performance and visibility have improved significantly since implementing their recommendations.",
    h3: "Michael Brown",
    designation: "CEO",
    personSrc: "/images/i_client_one.png",
  },
];

const Faq = {
  Generals: [
    {
      question: "Why is it important to audit my website?",
      answer:
        "Without a detailed search, it can be challenging to identify whether your site has problems that prevent it from obtaining its ideal search rankings, or even just greater usability for your visitors. Your organic search ranks can be improved by having a website that is clean, structured, free of duplicate material, and technically sound.",
    },
    {
      question: "How does in-depth SEO work?",
      answer:
        "After you buy your audit, we carefully examine your website using a comprehensive list of known ranking indicators that search engines employ. We examine your page’s speed on mobile devices, keyword density, internal and external links, analytics and reporting, coding, technological glitches, crawlability, accessibility, and other factors. We provide you possibilities to improve your ranking, increase traffic, and increase sales at the conclusion. We describe any issues we’ve found and precisely how you can remedy them in each component of the audit.",
    },
    {
      question: "What is turnaround time?",
      answer:
        "From the time you submit your site information, the 30 Point Technical Audit can take 10 to 14 business days. Take note that access to the search console will be required, and also that delays in receiving access may affect the turnaround times. Due to elements including website content production, on-page optimization, and off-page link building, the SEO Triage option and SEO Accelerated option both have a 21-business-day completion time limit.",
    },
    {
      question: "What is the timeframe of the SEO analysis?",
      answer:
        "The average study takes 30 days to complete, and 45 days for large websites with thousands of pages.",
    },
    {
      question: "What will you be analyzing?",
      answer:
        "We take into account a wide range of search ranking criteria. Technical elements (such as site speed, codes, and broken links), On-page elements (such as meta descriptions and headlines), Content elements (such as duplicate checks and thematic relevancy) and Off-Page elements are a few of these aspects (link quality and authority). When these measurements are combined, a thorough report is created that shows exactly what flaws your website may have.",
    },
    {
      question: "Will you make the required fixes?",
      answer:
        "Absolutely! Businesses that lack the time or internal technical and SEO knowledge to implement all of our recommendations could enjoy the benefits of our on-site optimization service. The audit’s most important recommendations that are keeping you back in the search results will be quickly implemented by our experts. Since it’s your brand, you always get the last say on any work we execute. We’ll work with you or members of your team to approve content and website updates before anything is published online.",
    },
    {
      question: "What will I receive in the final report?",
      answer:
        "Your SEO report will give you a thorough analysis of several search ranking criteria, including technical elements, on-page elements, content factors, and off-page metrics. Our qualified auditing team evaluates each item and offers recommendations for improvements. This will serve as a guide to help you build a strong foundation for your website. Additionally, a total score for your website will be given, calculated by averaging the results of each individual factor score and weighted according to the factors’ estimated relative relevance to Google’s algorithm.",
    },
  ],
};

const SEOAudit = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <>
      <DFYHero
        header={"IN - DEPTH SEO AUDIT"}
        desc={
          "With our in-depth SEO audit services we quickly identify the SEO errors, gaps, and barriers that hold your rankings back.. Our complete SEO audit services are 100% transparent and analyze your site’s technical, on-page and off-page SEO for you to rank on the first page of Google."
        }
      />

      {/* Details section */}
      <div className={styles.wrapper}>
        <div className={styles.bgColor} />
        <Container>
          <div className={styles.contentWrapper}>
            <div className={styles.partners}>
              {isMobile
                ? MobileData.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })
                : Data.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })}
            </div>

            <h1>NOT RANKING #1 IN SEARCH RESULTS?</h1>
            <h1>GET ANSWERS WITH SEO AUDIT</h1>
            <div className={styles.textBody}>
              <div className={styles.left}>
                An in-depth SEO audit is the fastest, most effective way to
                determine how to rank in search results. If done right along
                with link building and content strategy, there is nothing that
                can stop you from ranking in the first 3 search results of
                Google.
                <br />
                <br />
                Basically when it comes to in-depth SEO audit we devide it into
                three major aspects – Technical, On-page and Off-page edit. Your
                SEO audit will be delivered by our experienced SEO strategists,
                which not only saves you time but ensures you with strategies
                that are battle tested and are going to bring results everytime
                you use them.
              </div>
              <div className={styles.right}>
                Technical SEO is one of the most underrated & effective measures
                to gain a critical ranking boost when you have a large website.
                <br />
                <br />
                No website can stand without a strong backbone and that backbone
                is technical SEO. Technical SEO is the structure of your website
                without which everything else falls apart.
                <br />
                <br />
                There are several files on your server — like .htaccess,
                robots.txt and sitemap.xml to name a few — that dictate how
                search engines access and index your content. We optimize and
                configure those files so that the search engine can easily crawn
                and index your website, which gives you an upper hand for
                ranking in the first page of Google.
              </div>
            </div>

            <div className={styles.complement}>
              SOME OTHER FEATURES THAT ARE COMPLEMENTARY TO THE SERVICE
            </div>
            <div className={styles.cardsSection}>
              {DominateData.map((item, index) => {
                return (
                  <div className={styles.cards} key={index}>
                    <img
                      className={styles.icon}
                      src={item.image}
                      alt={"logo"}
                    />

                    <h2>{item.header}</h2>
                    <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </div>

      <DFYClients
        ctaLink={
          "https://forms.zohopublic.in/rankmakeup/form/ManagedSEOServiceForm/formperma/xD3vQReHd6_-bC1FlVLX-fhK3UJGRXF9raq3TYp_x5w"
        }
      />
      <DIYClients data={Testimonial} verticalSpace={true} />
      <DFYExperts
        bannerHeader={"Claim Your Free Backlink Audit Now"}
        bannerDesc={
          "Let us help you wead out Toxic, Irrelevant backlinks from your website!"
        }
        buttonLink={"https://calendly.com/bookadiscoverycall/15"}
      />
      <HomeFaqs data={Faq} />
      <Footer />
    </>
  );
};

export default SEOAudit;
