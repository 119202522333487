import Container from "../../Shared/Container";
import styles from "./styles.module.scss";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

const Values = [
  {
    header: "Result-oriented",
    desc: "Our tried and tested SEO solutions yield results, helping you achieve excellent position for your brand as a leader in your sector. We constantly update our services to reflect the dynamism of SERPs.",
    icon: "/images/ic_curious.svg",
    image: "/images/about-result.png",
  },
  {
    header: "Measurable Results",
    desc: "Our team follows a well-coordinated strategy combining offline marketing and public relations with search and paid search advertising to produce exponential results.",
    icon: "/images/ic_ethical.svg",
    image: "/images/about-measurable.png",
  },
  {
    header: "100% Transparency",
    desc: "We understand your biggest concern is putting in enormous effort to monitor our actions. That’s why we follow transparent practices in payments or our SEO strategies.",
    icon: "/images/ic_risks.svg",
    image: "/images/about-transparency.png",
  },
  {
    header: "Dependable Team",
    desc: "Our reliable team of experts helps you navigate the ever-changing world of digital marketing, maximizing the effectiveness of your efforts through online and offline channels.",
    icon: "/images/ic_curious.svg",
    image: "/images/about-result.png",
  },
  {
    header: "Time Management",
    desc: "We know meeting project deadlines is as vital as the project itself. Our well-structured calendars foresee and manage delays effectively.",
    icon: "/images/ic_ethical.svg",
    image: "/images/about-measurable.png",
  },
  {
    header: "Active Support",
    desc: "Need support at an ungodly hour? Get 24/7 access to all your data and constant support about what we do on your behalf.",
    icon: "/images/ic_risks.svg",
    image: "/images/about-transparency.png",
  },
];

const AboutUsValues = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.mainWrapper}>
        <Container>
          <div className={styles.wrapper}>
            <h1>6 Values, Infinite Possibilities, Defined Work Ethic</h1>

            <div className={styles.valueCards}>
              {Values.map((item, index) => {
                return (
                  <div className={styles.valueCard}>
                    <div className={styles.top}>
                      <h2>{item.header}</h2>
                      <img
                        className={styles.valueItem}
                        src={item.icon}
                        alt={"logo"}
                      />
                    </div>
                    <p>{item.desc}</p>
                    <img
                      src={item.image}
                      alt=""
                      className={styles.bottomImage}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <div className={styles.differentText}>
          Rank Jacker believes in reimagining, redefining, and resolving
          unconventionally to pull your company to the top of Google pages.
          That's why <span>WE ARE DIFFERENT.</span>
        </div>
      </Container>

      <img
        className={styles.bannerOne}
        src={"/images/i_about_banner_1.png"}
        alt={"banner"}
      />

      <Container>
        <div className={styles.founderSection}>
          <div className={styles.header}>
            Meet the Minds
            <br />
            <span>Behind The Screen</span>
          </div>

          <p>
            Our experts make you SEO Empowered: We help you turn your common SEO
            problems into extraordinary results for your agency's success
            through automation, scalability, and accountable delegation that
            sets your agency apart.
          </p>

          <div
            className={styles.founderCards}
          >
            <div className={styles.founderCard} onClick={() => navigate("/founders/anil-kumar")}>
              <img
                className={styles.founder}
                src={"/images/i_founder_one.png"}
                alt={"banner"}
              />
              <div className={styles.texts}>
                <div className={styles.name}>Mr. Anil Kumar</div>
                <div className={styles.position}>
                  Operational Excellence, SEO & Marketing Lead
                </div>
              </div>
            </div>
            <div className={styles.founderCard} onClick={() => navigate("/founders/rohit-dua")}>
              <img className={styles.founder} src={'/images/i_founder_rohit.jpeg'} alt={'banner'} />
              <div className={styles.texts}>
                <div className={styles.name}>
                  Mr. Rohit Dua
                </div>
                <div className={styles.position}>
                  Growth Hacker, Automation Lead
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <img
        className={styles.bannerOne}
        src={"/images/i_about_banner_two.png"}
        alt={"banner"}
      />

      <Container>
        <div className={styles.collabBanner}>
          <img
            src={"/images/ic_rocket.svg"}
            alt={"rocket"}
            className={styles.rocket}
          />
          <img
            src={"/images/i_collaborate_pattern.svg"}
            alt={"pattern"}
            className={styles.pattern}
          />
          <div>
            <h2>Have a project in mind?</h2>
            <h2>Let’s collaborate</h2>
          </div>

          <Link
            to={"https://rankjacker.spp.io/signup"}
            className={styles.button}
          >
            <p>Start a project</p>
            <img
              src={"/images/ic_arrow_dark.svg"}
              alt={"arrow"}
              className={styles.arrow}
            />
          </Link>
        </div>
      </Container>
    </>
  );
};

export default AboutUsValues;
