import Container from "../../Shared/Container";
import styles from "./styles.module.scss";

const RefundHero = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.bgCont} />
            <Container>
                <div>
                    <div className={styles.header}>
                        <p>
                            {`Home > Refund Policy`}
                        </p>

                        <h1>
                            RANKJACKER REFUND POLICY
                        </h1>
                    </div>
                    <div className={styles.main}>
                        <h3>
                            RANKJACKER REFUND POLICY
                        </h3>
                        <p>
                            Last Modified: Feb 29, 2024
                            <br /><br />
                            Rank-jacker.com offers non-tangible irrevocable goods and services and therefore we do not offer refunds for paid subscriptions or services. Once work is complete, all purchases are final.
                            <br /><br />
                            In the exceptional circumstance where you are not satisfied with your purchase, we offer a 30-day Satisfaction Guarantee.
                            <br /><br />
                            If you are not happy with your service/product, please contact hey@rank-jacker.com within 30 days of the delivery of your product/service. Once we receive your email, we will be in contact regarding your concerns and will make every effort to rectify your dissatisfaction.
                            <br /><br />
                            If we are unable to ‘make good’ on the product or service ordered, we will issue a credit to you for the full amount of the services purchased. This credit will not expire and can be applied to any future RankJacker purchases.
                            <br /><br />
                            In the event that we are unable to deliver the product or service you have ordered, we will issue a full refund, no questions asked.
                        </p>

                        <h3>
                            Link Building Terms
                        </h3>
                        <p>
                            Our guarantee is that your link will be live for a minimum of 6 months from the date of delivery. Should your link be removed within that time, we will replace it with an equal or better opportunity at no additional charge. We cannot offer refunds on a link placement once it is live.
                            RankJacker is a link-building brokerage, meaning that we do not own or operate any of the domains offered on our list.
                            <br /><br />
                            As such, there are a number of factors that are site-dependent and at the sole discretion of our site partners. These factors are outside of our control and are therefore not covered by the 30-day Satisfaction Guarantee.
                            <br /><br />
                            This includes, but is not limited to, the following:
                            We cannot guarantee that the publisher will accept your primary choice in URL, especially for promotional URLs. No refunds or replacements will be issued relative to URL. Please provide an informational URL as an alternate option.
                            <br /><br />
                            We cannot guarantee that your primary choice in anchor text will be used. No refunds or replacements will be issued relative to anchor text.
                            We cannot guarantee indexation for any link that we build. No refunds or replacements will be issued if a page your link is placed on does not show up in the results of a particular search engine.
                            <br /><br />
                            We cannot guarantee that any changes can be made after the content has been published. Once an article is live, in most cases it cannot be edited or modified. However, should there be an error, (e.g. misspelled anchor text), we will make every effort within our means to rectify it.
                            If you have chosen/approved the site for your link placement, we naturally assume that you have reviewed the site and are happy with your choice. No refunds or replacements will be issued for changes of heart after the fact.
                            <br /><br />
                            While we regularly check each site we work with, please do your own due diligence before selecting/approving domains for your order.
                            RankJacker SEO makes no claims or guarantees that your link building campaign will directly improve SEO. We’re not Google, nor do we have insider knowledge of their algorithm. While Link Building is one factor that can improve your site’s ranking, many other factors are at play and we cannot guarantee positive results.
                            <br /><br />
                            RankJacker SEO is not liable for any negative results, whether perceived or known, that might occur as a result of using our link building services. This includes but is not limited to manual penalty or negative impact of links.
                            <br /><br />
                            Any dissatisfaction with links must be brought to our attention within 30 days of delivery. Any claims to our live link guarantee must be submitted within 180 days of delivery.
                        </p>


                        <h3>
                            Citation Cleanup Terms
                        </h3>
                        <p>
                            RankJacker SEO provides a manual outreach service for the purposes of correcting citation listings. The outcome of the cleanup effort is dependent on the cooperation of directory owners to be successful.
                            As RankJacker SEO does not own or operate any local directories, we cannot guarantee a specific number or percentage of corrected listings.
                            Some directories may take longer than the 6-8 weeks of our campaign to update your listing. Please do your own due diligence and review the pending listings periodically after the final report is delivered.
                        </p>

                    </div>

                    <div className={styles.collabBanner}>
                        <img src={"/images/ic_rocket.svg"}
                            alt={"rocket"}
                            className={styles.rocket} />
                        <img src={"/images/i_collaborate_pattern.svg"}
                            alt={"pattern"}
                            className={styles.pattern} />
                        <div>
                            <h2>
                                Have a project in mind?
                            </h2>
                            <h2>
                                Let’s collaborate
                            </h2>
                        </div>

                        <div className={styles.button}>
                            <p>
                                Get Started Today
                            </p>
                            <img src={"/images/ic_arrow_dark.svg"}
                                alt={"arrow"}
                                className={styles.arrow} />
                        </div>

                    </div>
                </div>
            </Container>
        </div>
    )
};

export default RefundHero;