import DIYHero from "../../../components/DIY/Hero";
import DIYDetails from "../../../components/DIY/Details";
import DIYTestimonials from "../../../components/DIY/Testimonials";
import HomeFaqs from "../../../components/Home/Faqs";
import Footer from "../../../components/Footer";
import Details from "../../../components/DFY/Details";
import styles from "../styles.module.scss";
import Container from "../../../components/Shared/Container";
import PrimaryButton from "../../../components/Shared/Buttons/Primary";
import SwitchButton from "../../../components/Shared/Buttons/Switch";
import { useMediaQuery } from "react-responsive";
import PricingOptions from "../../../components/DIY/PricingOptions";
import DFYClients from "../../../components/DFY/Clients";
import { Link, useNavigate } from "react-router-dom";
import DIYNetwork from "../../../components/DIY/Network";
import DIYClients from "../../../components/DIY/Clients";
import {
  TestimonialsData,
  TestimonialsDataPress,
} from "../../../cont/ClientData";

const logoList = [
  "/images/pr-logo-1.svg",
  "/images/pr-logo-2.svg",
  "/images/pr-logo-3.svg",
  "/images/pr-logo-5.svg",
  "/images/pr-logo-4.svg",
  "/images/pr-logo-6.svg",
  "/images/pr-logo-7.svg",
  "/images/pr-logo-8.svg",
  "/images/pr-logo-9.svg",
  "/images/pr-logo-2.svg",
];

const logoListMobile = [
  "/images/pr-logo-1.svg",
  "/images/pr-logo-2.svg",
  "/images/pr-logo-3.svg",
  "/images/pr-logo-4.svg",
  "/images/pr-logo-5.svg",
  "/images/pr-logo-6.svg",
  "/images/pr-logo-7.svg",
  "/images/pr-logo-8.svg",
  "/images/pr-logo-9.svg",
  "/images/pr-logo-2.svg",
];

const DominateData = [
  {
    icon: "/images/speaker.svg",
    header: "Exposure & Reach",
    desc: "PRs extend your business' reach to a potentially large audience via a huge network of news sites & media outlets in a fraction of an expense.",
  },
  {
    icon: "/images/blog.svg",
    header: "SEO Benefits",
    desc: "They can attract a large amount of instant Traffic and prospects to your site that can strengthen your site's standing in SERPs as well.",
  },
  {
    icon: "/images/link.svg",
    header: "Brand Appeal",
    desc: "PR Outreach helps to project your business as an Industry Leader with strong Brand visibility and enhanced targeted exposure.",
  },
];
const Pricing = [
  {
    price: "$79",
    list: [
      "Up to 700 Unique Content",
      "Approval Before Syndication",
      "Distributed to 350+ Media Sites",
      "Up to 2 Contextual Links",
      "Optimized Content & Titles",
      "NAP, Bio, Logo Included",
    ],
  },
  {
    price: "$99",
    list: [
      "All Basic Features Included",
      "100 Additional Media Outlets",
      "Guaranteed Google News Inclusion",
      "Optimized Content & Titles",
      "NAP, Bio, Logo Included",
      "Unlimited Edits",
      "Youtube Video Embededs Included",
    ],
  },
  {
    price: "$99",
    list: [
      "All Basic Features Included",
      "100 Additional Media Outlets",
      "Guaranteed Google News Inclusion",
      "Optimized Content & Titles",
      "NAP, Bio, Logo Included",
      "Unlimited Edits",
      "Youtube Video Embededs Included",
    ],
  },
];

const Testiomonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's press release service skyrocketed our visibility! Within days of distribution, our company saw a significant surge in media coverage and website traffic. Highly recommended!",
    h3: "Jane Doe",
    designation: "CEO",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We've seen a noticeable increase in website traffic and inquiries since partnering with 'Rank-Jacker SEO' for our press releases. Their team's expertise in storytelling and media relations has been pivotal in elevating our brand presence.",
    h3: "Alex Turner",
    designation: "CEO",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Choosing 'Rank-Jacker SEO' for our press release needs was one of the best decisions we've made. Their team's professionalism and attention to detail have helped us secure valuable media placements and expand our reach exponentially.",
    h3: "Mark Roberts",
    designation: "CEO",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "We've seen remarkable results since partnering with 'Rank-Jacker SEO' for our press releases. Their attention to detail and strategic approach have helped us garner media attention and attract new customers consistently.",
    h3: "Michael Brown",
    designation: "Founder",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "I can't recommend 'Rank-Jacker SEO' enough for their press release services. Their team not only understands our brand's messaging but also ensures that each release reaches the right audience, resulting in tangible growth and engagement.",
    h3: "Sarah Johnson",
    designation: "Marketing Director",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "'Rank-Jacker SEO' exceeded our expectations with their press release service. Their attention to detail, coupled with their strategic approach, led to extensive media pickups and amplified our brand's reach far beyond what we anticipated. A definite must for any company looking to make an impact.",
    h3: "Sarah Johnson",
    designation: "Founder",
    personSrc: "/images/i_client_one.png",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "'Rank-Jacker SEO's press release service proved to be a vital asset in our marketing strategy. Their team's professionalism and dedication ensured our announcement received widespread coverage, leading to increased brand visibility and credibility within our industry. We're grateful for their outstanding work!",
    h3: "David Roberts",
    designation: "COO",
    personSrc: "/images/i_client_one.png",
  },
];

const FaqData = {
  Generals: [
    {
      question: "What makes a good press release topic?",
      answer:
        "A good press release topic is one that is newsworthy, timely, and relevant to your target audience. It should offer something of value or interest, such as a product launch, significant company milestone, or industry insight.",
    },
    {
      question: "Are there SEO benefits to press releases?",
      answer:
        "Yes, press releases can provide SEO benefits by generating backlinks from reputable news sites and increasing online visibility. Including relevant keywords and links in your press release can also improve search engine rankings.",
    },
    {
      question:
        "Do press releases help increase backlinks to a business website?",
      answer:
        "Absolutely. Press releases distributed through reputable channels can result in backlinks from news websites, blogs, and other online platforms. These backlinks can enhance your website's authority and improve its search engine ranking.",
    },
    {
      question: "How often should a brand publish press releases?",
      answer:
        "The frequency of press releases depends on the news and updates within your company. Generally, it's advisable to distribute press releases when you have significant announcements or developments to share, rather than on a rigid schedule. Aim for quality and relevance over quantity.",
    },
  ],
};
const PressRelease = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();
  return (
    <>
      <DIYHero
        bgImage={"/images/foundation-link-bg.png"}
        title={"PRESS RELEASE DISTRIBUTION SERVICES"}
        subtitle={
          "ESTABLISH YOUR BUSINESS AS A DIGITAL BRAND. Get an instant exposure On 100s Of News & Media Publications with a Guaranteed Google News Inclusion"
        }
        cta2Link={"https://calendly.com/bookadiscoverycall/15"}
      />
      <div className={styles.wrapper}>
        <div className={styles.bgContainer} />
        <div className={styles.bgContainerBottom} />

        <div className={styles.mainContent}>
          <Container>
            <div className={styles.partners}>
              {!isMobile
                ? logoList.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })
                : logoListMobile.map((item, key) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                        key={key}
                      />
                    );
                  })}
            </div>

            <div className={styles.appointment}>
              <div className={styles.left}>
                <div className={styles.topText}>
                  LEVEL UP WITH HARO BACKLINKS
                </div>
                <div className={styles.header}>
                  Gain high authority backlinks from famous publication sites
                  for growing your website presence and SEO.
                </div>
                <div className={styles.buttonCont}>
                  <Link to={"https://calendly.com/bookadiscoverycall/15"}>
                    <PrimaryButton buttonText={"Book an appointment"} />
                  </Link>
                </div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.logoImage}
                  src={"/images/ic_appointment.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </Container>

          {/*Dominate*/}
          <div className={styles.dominate}>
            <Container>
              <div className={styles.headerSection}>
                <h1>WE CAN HELP WITH AN ACCELERATED BRAND EXPOSURE</h1>
                <h3>
                  With Press Release Syndication, we can help to let the word
                  out for any Business, Website, or event amongst your potential
                  audience almost instantly.
                  <br />
                  Make a powerful brand statement with an enhanced Brand
                  perception and build an effective long-term brand reputation
                  and brand appeal. We’ll let your brand visible on major news &
                  media outlets like google news, CBS, Digital Journal, etc.
                </h3>
              </div>

              <div className={styles.cardsSection}>
                {DominateData.map((item, index) => {
                  return (
                    <div className={styles.cards} key={index}>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt={"logo"}
                      />

                      <h2>{item.header}</h2>

                      <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>

          <DIYNetwork />

          <PricingOptions
            data={Pricing}
            heading={"Pricing and Options"}
            subHeading={
              "Through your RJ dashboard, you can Buy Pbn Links in a sizable quantity. You can get special wholesale pricing. Be it 10 or 60, each and every link of yours is posted safely on our high-quality blog network."
            }
            ctaLink={"https://rankjacker.spp.io/order/QL3JO6"}
          />
          <DFYClients
            dark={true}
            header={"Working with Precision in Process, Excellence in Results"}
            desc={
              "Our impeccable back linking strategies give you access to robust data solutions- easy to implement and use. Here’s how we work."
            }
          />
        </div>
      </div>
      <DIYTestimonials data={Testiomonial} />
      <DIYClients data={TestimonialsDataPress} />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};
export default PressRelease;
