import DFYClients from "../../../components/DFY/Clients";
import DFYDetails from "../../../components/DFY/Details";
import DFYExperts from "../../../components/DFY/Experts";
import DFYGrow from "../../../components/DFY/Grow";
import DFYHero from "../../../components/DFY/Hero";
import Footer from "../../../components/Footer";
import HomeTestimonial from "../../../components/Home/Testimonial";
import HomeFaqs from "../../../components/Home/Faqs";
import Container from "../../../components/Shared/Container";
import { useMediaQuery } from "react-responsive";
import styles from "./styles.module.scss";

const Data = [
  "/images/sc-logo-1.svg",
  "/images/sc-logo-2.svg",
  "/images/sc-logo-3.svg",
  "/images/sc-logo-4.svg",
  "/images/sc-logo-5.svg",
  "/images/sc-logo-6.svg",
  "/images/sc-logo-7.svg",
  "/images/sc-logo-8.svg",
];
const MobileData = [
  "/images/sc-logo-1.svg",
  "/images/sc-logo-2.svg",
  "/images/sc-logo-3.svg",
  "/images/sc-logo-4.svg",
  "/images/sc-logo-5.svg",
  "/images/sc-logo-6.svg",
  "/images/sc-logo-7.svg",
  "/images/sc-logo-8.svg",
];

const FaqData = {
  Generals: [
    {
      question: "Why should we outsource our SEO projects?",
      answer:
        "SEO best practices are continually being modified to resonate with the dynamic search engine algorithms; this makes it hard for you and your team to get results as you need to keep on coming up with the new modifications and techniques. Therefore outsourcing your work to an experienced team who is solely dedicated to SEO not only makes sense but also ensures that you have peace of mind. You can expect better results, and on-time delivery with a 100% confidentiality check, what else do you need, remains the last question?",
    },
    {
      question: "How can we get a site to the first page of Google?",
      answer:
        "First we need to understand how does Google decide which pages rank on the first page of Google, Google being a search engine aims to provide the most relevant results to search queries, and therefore places those sites on its first page which are most relevant and authoritative matching the search query. Therefore in simple words, you would need to understand things like the search queries, keywords, competition, site authenticity, site authority, search engine friendly practices, and much more, to be able to rank on the first page of Google. All these practices in a nutshell are known as SEO.",
    },
    {
      question: "How long does it take to start seeing results?",
      answer:
        "You might see some gains from the very first week of the campaign but there is no way to pinpoint an exact date.SEO is an ongoing process and results vary enormously from one business to another. There are some SEO service providers who promise to show results in a short span of time, but those results can have a negative long-term impact on your site’s performance and ranking. SEO results are dependent on many factors like your present site health, complexity, popularity, competition, etc.",
    },
    {
      question: "Can you guarantee results?",
      answer: "We guarantee results for every service we provide.",
    },
    {
      question: "How do I know that I will get a return on my investment?",
      answer:
        "Because we put our money where our mouth is. Our guarantee is that if you do not like the results you get contact us within 7 days of receiving your report and we ensure to make it right and if not we refund back your money.",
    },
    {
      question: "Is there any monthly contract needed?",
      answer:
        "No, absolutely not. We earn our next month with the results we bring in for you.",
    },
  ],
};

const LocalService = [
  {
    h1: "We become your white label partner",
    p: "Sign a contract ensuring full confidentiality. You represent us to clients, set prices, and control margins while we work discreetly.",
    icon: "/images/ic_local_service_1.svg",
  },
  {
    h1: "Frictionless Onboarding",
    p: "We’ve done all the work for you in creating an on-boarding process for both you and your clients.",
    icon: "/images/ic_local_service_2.svg",
  },
  {
    h1: "24-72 hrs Turnover Time",
    p: "Project begins within 24-72 hours. We analyze the project, assess competition, and craft winning strategies for your clients.",
    icon: "/images/ic_local_service_3.svg",
  },
  {
    h1: "Reporting and Client Retention",
    p: "Our experts monitor the online review sites looking into the local SEO algorithm with our expertise! Increase your quality reviews today.",
    icon: "/images/ic_local_service_4.svg",
  },
];

const SpeedData = [
  {
    icon: "/images/ic_buy_link.svg",
    header: "Updated and Proven SEO Techniques",
    desc: "Having a dedicated team in every small segment of SEO keeps us up-to-date with the alogorithm changes and new techniques, allowing us to bring the best of results for you.",
  },
  {
    icon: "/images/ic_managed.svg",
    header: "Complete SEO Audit",
    desc: "A comprehensive SEO audit quickly reveals why your website isn't ranking high. Our services analyze technical, on-page, and off-page SEO for a Google ranking edge.",
  },
  {
    icon: "/images/ic_local.svg",
    header: "Competitive Analysis",
    desc: "There is no point in reinventing the wheel, we deeply research about your competitors and their strategies that are already working for them, and then make a bespoke winning strategy for you.",
  },
  {
    icon: "/images/ic_consult.svg",
    header: "Complete Roadmap For SEO Success",
    desc: "Getting a roadmap to success without a grind is tough. We have battle tested all our strategies in the market and therfore are confident that are proven strategies are going to bring positive results.",
  },
  {
    icon: "/images/ic_monthly.svg",
    header: "CRO Optimization",
    desc: "SEO brings guaranteed traffic, not conversions. Our expert designers optimize UX and CRO for maximum conversions once SEO succeeds.",
  },
  {
    icon: "/images/ic_depth.svg",
    header: "Setting Up Analysis & Reporting",
    desc: "We set up the analytical tools so that every aspect of the SEO process is completely trackable and provide you with weekly reports that helps you to stay in full control.",
  },
];

const DataClients = [
  {
    icon: "/images/gr_one.png",
    header: "UNDERSTANDING YOUR ICP(IDEAL CUSTOMER PROFILE)",
    desc: `Unlike most agencies we don’t ‘copy-paste strategies’ on every business we work with.
        At first we analyze about our client’s present situation in the business, research about their competitior, understand their sales process, targeted audience and then making a bespoke plan to drive in quality traffic that grows in the revenue for our clients.`,
  },
  {
    icon: "/images/gr_two.png",
    header: "SETTING UP YOUR CUSTOM SALES PROCESS/ SOPS",
    desc: `Achieve qualified leads with our proven sales process, guiding clients to surpass the $1M milestone and beyond.
        Our battle-tested frameworks and SOPs ensure precision and alignment for your team, detailing what to do, when, how, and why.
        Beyond documentation, it's about business growth. Our comprehensive package is designed to swiftly skyrocket your business.`,
  },
  {
    icon: "/images/gr_three.png",
    header: "SALES OUTREACH",
    desc: `Ok, now this is the stage where businesses either go broke or they break their every limitation. The most vital stage in business and specially online business….SALES OUTREACH
        Our team of specially trained experts organic onbound marketers makes sure that your messaging and offer is crystal clear, efficient and highly converting.
        We follow the exact strategies that bootstrap companies have used for generating millions of dollars in revenue.`,
  },
  {
    icon: "/images/gr_four.png",
    header: "SALES MONITORING AND REPORTING",
    desc: `We monitor every aspect of the outreach and provide you with reports so that you are in complete control with the process.
        After analyzing our reports we work on further improvement untill and unless you reach your business goals.`,
  },
];

const SEOConsultation = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <>
      <DFYHero
        header={"SEO CONSULTATION AND SALES PROCESS"}
        desc={
          "Consult with our SEO and sales expert for a well researched and detailed strategy that not only helps your website to rank but also drives in quality traffic and sales."
        }
      />

      {/* Details section */}
      <div className={styles.wrapper}>
        <div className={styles.bgColor} />
        <div className={styles.bgColorTwo} />
        <Container>
          <div className={styles.contentWrapper}>
            <div className={styles.partners}>
              {isMobile
                ? MobileData.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })
                : Data.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })}
            </div>

            <div className={styles.headerSection}>
              <h1>SAY GOODBYE TO SEO PROBLEMS</h1>
              <p>
                No need to worry about new and modified google ranking algoritms
                any more, once you partner with us!!!
              </p>
            </div>

            <div className={styles.cardsSection}>
              {SpeedData.map((item, index) => {
                return (
                  <div className={styles.cards} key={index}>
                    <img className={styles.icon} src={item.icon} alt={"logo"} />

                    <h2>{item.header}</h2>
                    <p>{item.desc}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </div>

      <DFYClients
        header={"GROW YOUR AGENCY REVENUE EXPONENTIALLY"}
        desc={
          "Worried about your next clients or dependent on referrals? Get ready to be flooded with leads using our proven sales process that will overfill your calenders."
        }
        data={DataClients}
      />

      <Container>
        <div className={styles.sense}>
          <h1>
            IT MAKES NO SENSE WHY YOU WOULDN'T LIKE US TO DO THIS FOR YOU ?
          </h1>
          <div className={styles.content}>
            <div className={styles.left}>
              With our proven SEO strategy and framework, we have generated
              consistent results for more than 100s businesses…
              <br />
              <br />• Taking a law firm from monthly traffic of 500 to 1000 in
              just 6 months valued at more than $12000
              <br />
              <br />• Increased the monthly traffic of a car dealership company
              by 150%.
              <br />
              <br />• An increase of $100K in annual revenue for a local foreign
              language company
              <br />
              <br />• Skyrocketed the traffic of a dental website from 0 to a
              whopping 7000 visits per month which are now valued at more than
              $15000 per month
              <br />
              <br />
              Now see we can keep on writing these results we have achieved for
              others and fill this entire page but what matters to us now
              is YOU…
              <br />
              <br />
              ARE YOU READY TO TAKE AN ACTION?
            </div>
            <img
              className={styles.icon}
              src={"/images/sense.png"}
              alt={"logo"}
            />
          </div>
        </div>
      </Container>

      {/*<HomeTestimonial />*/}

      <DFYExperts
        bannerHeader={"Claim Your Free Backlink Audit Now"}
        bannerDesc={
          "Let us help you wead out Toxic, Irrelevant backlinks from your website!"
        }
        buttonLink={"https://calendly.com/bookadiscoverycall/15"}
      />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};

export default SEOConsultation;
