import styles from "./styles.module.scss";

const RoundedButton = ({ buttonText, onClick }) => {
    return <div className={`${styles.wrapper}`} onClick={() => {
        if (onClick) {
            onClick();
        } else return false;
    }}>
        {buttonText}
    </div>;
};

export default RoundedButton;