import AboutUsHero from "../../components/AboutUs/Hero";
import AboutUsValues from "../../components/AboutUs/Values";
import DFYClients from "../../components/DFY/Clients";
import DFYDetails from "../../components/DFY/Details";
import DFYExperts from "../../components/DFY/Experts";
import DFYGrow from "../../components/DFY/Grow";
import DFYHero from "../../components/DFY/Hero";
import Footer from "../../components/Footer";
import HomeTestimonial from "../../components/Home/Testimonial";
import HomeFaqs from "../../components/Home/Faqs";
import styles from "./styles.module.scss";
import DIYHero from "../../components/DIY/Hero";
import DIYDetails from "../../components/DIY/Details";
import DIYTestimonials from "../../components/DIY/Testimonials";
import Details from "../../components/DFY/Details";

const DIY = () => {
  return (
    <>
      <DIYHero />
      <DIYDetails />

      <DIYTestimonials />
      <HomeFaqs />
      <Footer />
    </>
  );
};

export default DIY;
