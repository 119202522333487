import React, { useEffect, useState } from "react";
import BlogsInternalHero from "../../../../components/BlogsInternal/Hero";
import Footer from "../../../../components/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import BlogBottomBanner from "../../../../components/BlogsInternal/BottomBanner";
import { useMediaQuery } from "react-responsive";

const BlogsInternal = () => {
  const [data, setData] = useState({});
  const [relatedData, setRelatedData] = useState([]);
  const { slug } = useParams();
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
});

  useEffect(() => {
    if (slug) {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `https://www.rank-jacker.com/wp-json/wp/v2/posts?slug=${slug}`,
          );
          const postData = res.data[0] || {};
          setData(postData);
          if (postData.categories && postData.categories.length > 0) {
            const resTwo = await axios.get(
              `https://www.rank-jacker.com/wp-json/wp/v2/posts?categories=${postData.categories[0]}`,
            );
            console.log(resTwo.data);
            setRelatedData(resTwo.data);
          }
        } catch (error) {
          console.error("Error fetching post:", error);
          // Handle error or set default state
        }
      };

      fetchData();
    }
  }, [slug]);

  return (
    <div style={{ position: "relative" }}>
      <BlogsInternalHero
        category={data?.x_categories}
        header={data?.title?.rendered}
        bannerImage={data?.x_featured_media_original}
        author={data?.x_author}
        date={data?.x_date}
        content={data?.content?.rendered}
        authorImage={data?.x_gravatar}
        relatedData={relatedData.slice(0,3)}
      />
      <Footer />
      <BlogBottomBanner />
    </div>
  );
};

export default BlogsInternal;
