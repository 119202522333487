import DFYClients from "../../../components/DFY/Clients";
import DFYDetails from "../../../components/DFY/Details";
import DFYExperts from "../../../components/DFY/Experts";
import DFYGrow from "../../../components/DFY/Grow";
import DFYHero from "../../../components/DFY/Hero";
import Footer from "../../../components/Footer";
import HomeTestimonial from "../../../components/Home/Testimonial";
import HomeFaqs from "../../../components/Home/Faqs";
import Container from "../../../components/Shared/Container";
import { useMediaQuery } from "react-responsive";
import styles from "./styles.module.scss";
import SureSection from "../../../components/ContentStrategy/SureSection";
import DIYClients from "../../../components/DIY/Clients";

const Data = [
  "/images/lb-logo-1.svg",
  "/images/lb-logo-2.svg",
  "/images/lb-logo-3.svg",
  "/images/lb-logo-4.svg",
  "/images/lb-logo-5.svg",
  "/images/lb-logo-6.svg",
  "/images/lb-logo-7.svg",
  "/images/lb-logo-8.svg",
];
const MobileData = [
  "/images/lb-logo-1.svg",
  "/images/lb-logo-2.svg",
  "/images/lb-logo-3.svg",
  "/images/lb-logo-4.svg",
  "/images/lb-logo-5.svg",
  "/images/lb-logo-6.svg",
  "/images/lb-logo-7.svg",
  "/images/lb-logo-8.svg",
];

const DataClients = [
  {
    icon: "/images/ic_audit.svg",
    header: "Establishing Your Goals",
    desc: `Every business has commonalities but it does not mean every business is the same, in fact, no businesses are the same. This is where we find most agencies get it wrong, they try achieving their client’s goal with templated strategies which makes the company get themselves positioned as a dime a dozen.
        Unlike most agencies we have a different approach, before starting off with anything we deeply analyze your business’s goals, sales process and its market followed by which we make a bespoke plan for establishing your goals.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Link Audit",
    desc: `Before getting started with the link building process, we analyze all your links to identify and remove spammy and/or low quality links, apart from link inspection we also check for the diversity of backlinks you have on your website, and then execute on generating high quality, authoritative and relevant links.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Discover relevant and authoritative sites",
    desc: `Our meticulous link building process focuses on securing 99% high-authority and relevant links. With a proven strategy divided into Sandbox, Growth, and Authority stages, tailored to your website's organic traffic, we analyze and implement a bespoke plan, ensuring your site ranks on Google's first page with quality traffic.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Create Contents",
    desc: `Our experienced team of copywriters will create high quality, engaging contents for your visitors to increase the dwell time and conversions on the website… all a part of our link building packages.`,
  },
  {
    icon: "/images/ic_audit.svg",
    header: "Weekly Reports",
    desc: `With our weekly reports you will be able to track every aspect of your business which we are working upon and see the upward trajectory in your growth.`,
  },
];

const FaqData = {
  Generals: [
    {
      question: "What is monthly link building?",
      answer:
        "Link building is a method of off-page Search Engine Optimization (SEO) that entails obtaining high-quality backlinks to your website from other websites. These links are referred to as backlinks, as the explanation implies. It is a crucial aspect of SEO that helps the search engine comprehend your website and its contents more effectively.",
    },
    {
      question: "Why is link building important?",
      answer:
        "As long as it is done properly, link building increases website authority and ranking potential, which is vital for SEO. Backlinks enable search engine algorithms in analyzing and valuing your website as a useful resource for users on a relevant search query of a specific subject. Although the quantity of backlinks is important, the quality of those backlinks is just as important, if not more so.",
    },
    {
      question: "How do you go about doing outreach to build links?",
      answer:
        "Outreach for PR purposes can resemble outreach for link building. To find out if they could be interested in writing a guest post, get in touch with the right individual. Prepare a kind of proposal outlining why you would be a good fit for their website and how you could contribute. Most individuals will be willing to assist you if you are pleasant and professional.",
    },
    {
      question: "What if I don't like the sites you suggest posting on?",
      answer:
        "No issue, let us know why you’re unhappy with the selections by flagging them. After reviewing your comments, our staff will choose new possibilities that are a good fit.",
    },
    {
      question: "What takes place if a link is declined?",
      answer:
        "We will offer you an equivalent or better link chance if a contributor is unable to deliver on a link. Either we finish it or we’ll fix it. Enjoy the assurance that a guarantee brings!",
    },
    {
      question: "Can ANY page be linked to?",
      answer:
        "No. The websites we collaborate with have some amount of editorial control and are not free-for-alls (otherwise, we wouldn’t collaborate with them!) Each one has different guidelines for what could be published, but generally speaking: no pharmaceutical, adult, casino/gambling, etc. links, and the pages we link to, should actually include information that is helpful. Please get in touch with us before placing an order if you’re unsure whether a particular domain or URL would be allowed by the websites we work with.",
    },
    {
      question: "What are do-follow and no-follow links?",
      answer:
        "Do-follow links allow the search engines to follow and pass link equity credit because the website carrying the link is endorsing the website to which it is linked. No-follow links indicate that the website carrying the link will not attest to the reliability or authority of the webpage being linked to, informing the search engines not to follow through to the website and denying link equity credit. A no-follow designation is a regular practice for many of the largest online publishers, and all natural link profiles have a mix of both of these sorts.",
    },
    {
      question: "How long will it take to see results?",
      answer:
        "Websites and industries will differ in this in different ways. Since no website has exactly the same link profile as its rivals, every website has a unique beginning point. Depending on the beginning point and the amount of effort put into the campaign, it might often take up to 6 months to observe noticeable movement.",
    },
    {
      question: "How are your techniques better than other companies?",
      answer:
        "There are several SEO companies who claim that they build links. They promise their customers that they will publish blog entries and that websites will link to them. They fall short because they frequently fail to promote the information, which prevents links from ever actually materializing. We are great at reaching out to bloggers, journalists, and webmasters. Additionally, our unique techniques for locating quality link targets draw attention to our clients and provide authoritative connections that help them rise in the search results pages.",
    },
    {
      question: "How many links will you be building for my website?",
      answer:
        "We include a cautious estimate of the number of links that can be built at each proposed level of monthly investment. Most of the time, we’ll far exceed those figures. Quality above quantity will always be our main priority when building something.",
    },
    {
      question: "How much does it cost?",
      answer:
        "It may cost a few hundred to several thousand dollars per month. It is advisable to set aside a substantial budget for this service because it is one of the most crucial elements in deciding how well a website will rank in the search results. For a free consultation and a quote based on the particular requirements of your website, get in touch with us.",
    },
  ],
};

const Testimonial = [
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's Monthly Managed Link Building service is worth every penny. Not only have they helped us build a diverse backlink profile, but they've also provided valuable insights and recommendations to further enhance our SEO strategy.",
    h3: "Emily Brown",
    designation: "Director Of Marketing Agency",
    personSrc: "/images/Emily Brown.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "The results speak for themselves – Rank-Jacker SEO's Monthly Managed Link Building service has catapulted our websites to the top of search engine results pages. Their dedication to delivering tangible results is unmatched.",
    h3: "David Wilson",
    designation: "SEO Agency Owner",
    personSrc: "/images/David Wilson.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Our experience with Rank-Jacker SEO's Monthly Link Building service has been nothing short of exceptional. Their team's proactive approach and transparent communication have made them a trusted partner in our SEO journey.",
    h3: "James Thompson",
    designation: "CEO",
    personSrc: "/images/James Thompson.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Rank-Jacker SEO's Monthly Managed Link Building service is a game-changer. Their strategic approach to link building has not only boosted our website's authority but also helped us establish credibility within our industry.",
    h3: "Olivia Miller",
    designation: "Head Of Digital Marketing Agency",
    personSrc: "/images/Olivia Miller.jpg",
  },
  {
    brand: "/images/ic_rankjacker_dark.svg",
    p: "Since partnering with Rank-Jacker SEO for their Monthly Managed Link Building service, our website's organic traffic has seen a significant uptick. Their commitment to delivering results is evident in the quality of backlinks they acquire for us.",
    h3: "James Clark",
    designation: "Marketing Manager",
    personSrc: "/images/i_client_one.png",
  },
];

const LinkBuilding = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <>
      <DFYHero
        header={"MONTHLY MANAGED LINKBUILDING"}
        desc={
          "Take Advantage of our proven Link Building Strategy that promises highly efficient & targeted organic results to your website that helps you to dominate your competition."
        }
      />

      {/* Details section */}
      <div className={styles.wrapper}>
        <div className={styles.bgColor} />
        <Container>
          <div className={styles.contentWrapper}>
            <div className={styles.partners}>
              {isMobile
                ? MobileData.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })
                : Data.map((item, index) => {
                    return (
                      <img
                        className={styles.logoImage}
                        src={item}
                        alt={"logo"}
                      />
                    );
                  })}
            </div>

            <h1>GUARANTEED RESULTS WITH</h1>
            <h1>MANAGED LINK BUILDING</h1>
            <div className={styles.textBody}>
              <div className={styles.left}>
                <span className={styles.highlight}>
                  Link building is definitely a complex, time-consuming & yet
                  the most important aspect of SEO but if done right it not just
                  gives good results but can do wonders for your business.
                </span>
                <br />
                <br />
                Link building when combined with strong technical SEO
                foundations, great on-page SEO, intriguing content strategy, and
                good user engagement, link building can be super effective at
                driving more organic rankings and opening the floodgates of
                customers for your business.
                <br />
                <br />
                The two major aspects which determine the quality of links are
                their relevancy with the website on which it is being placed and
                the authority of the website from which it is being
                sourced/generated.
              </div>
              <div className={styles.right}>
                Building random links isn’t the solution– Most agencies build
                links just for the sake of doing it, but that does not do any
                good if not bring down the SEO effectiveness of the site.
                <br />
                <br />
                95% of all pages online have zero backlinks pointing to their
                website and 65% of digital marketers state that link building is
                the hardest part of SEO.
                <br />
                <br />
                But we got your back, we have a specially trained team dedicated
                only to link building. We build links that you would make for
                your website if you had time. All our links are highly relevant,
                from high authority sources, and are compliant with a list of
                quality checkpoints.
              </div>
            </div>

            <SureSection />
          </div>
        </Container>
      </div>

      <div className={styles.clientsWrapper}>
        <DFYClients
          dark={true}
          header={"OUR APPROACH TO LINK BUILDING"}
          desc={
            "Our software arms researchers with powerful data solutions that are simple to implement and utilize."
          }
          data={DataClients}
          ctaLink={
            "https://forms.zohopublic.in/rankmakeup/form/MonthlyLinkBuildingServicesFrom/formperma/6D8s1zzWj6n07cdswYzlM7bE8s-QgUC8PocNl6DkAc4"
          }
        />
      </div>

      {/* Grow section */}
      <div className={styles.growWrapper}>
        <Container>
          <div className={styles.topSection}>
            <h1>QUALITY OVER QUANTITY</h1>

            <div className={styles.content}>
              <div className={styles.left}>
                Unlike most agencies, we do not run behind in generating a bulk
                of links but only generate links that bring measurable results
                to your website.
                <br />
                <br />
                Link building is a double-edged sword, and Search Engines are
                quick to penalize your sites if the links are found to be
                spammy, irrelevant, or low quality.
                <br />
                <br />
                Our team of expert link builders strongly follows a list of
                quality checkpoints before generating/placing any links for
                every project we undertake.
              </div>
              <div className={styles.right}>
                <img
                  className={styles.banner}
                  src={"/images/i_visitors.png"}
                  alt={"logo"}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <DIYClients data={Testimonial} verticalSpace={true} />
      <DFYExperts
        buttonLink={"https://calendly.com/bookadiscoverycall/15"}
        bannerHeader={"Claim Your Free Backlink Audit Now"}
        bannerDesc={
          "Let us help you wead out Toxic, Irrelevant backlinks from your website!"
        }
      />
      <HomeFaqs data={FaqData} />
      <Footer />
    </>
  );
};

export default LinkBuilding;
