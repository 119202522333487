import Container from "../../Shared/Container";
import PrimaryButton from "../../Shared/Buttons/Primary";
import styles from "./styles.module.scss";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

const DFYAppointments = ({ header, desc, ctaLink }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className={styles.appointment}>
        <div className={styles.left}>
          <div className={styles.topText}>{header}</div>
          <div className={styles.header}>{desc}</div>
          <div className={styles.buttonCont}>
            <Link to={ctaLink ? ctaLink : "/contact-us"}>
              <PrimaryButton buttonText={"Book an appointment"} />
            </Link>
          </div>
        </div>
        <div className={styles.right}>
          <img
            className={styles.logoImage}
            src={"/images/ic_appointment.png"}
            alt={"logo"}
          />
        </div>
      </div>
    </Container>
  );
};

export default DFYAppointments;
