import Container from "../../Shared/Container";
import styles from './styles.module.scss';

function CaseInternalImpact() {
    return (
        <div className={styles.wrapper}>
            <Container>
                <div className={styles.wrapperCont}>
                    <h2>
                    The impact it caused
                    </h2>
                    <div className={styles.mainCont}>
                        

                        <div className={styles.right}>

                        </div>
                        <div className={styles.left}>
                            <p>
                            Lorem ipsum dolor sit amet consectetur. Ipsum purus lectus pretium dolor fringilla gravida interdum. Dui a senectus ut senectus sed. Orci non pulvinar ac sed quis. Egestas nam amet eu bibendum vitae sed id eleifend.Lorem ipsum dolor sit amet consectetur. Ipsum purus lectus pretium dolor fringilla gravida interdum. Dui a senectus ut senectus sed. Orci non pulvinar ac sed quis. Egestas nam amet eu bibendum vitae sed id eleifend.
                            </p>
                            <br/>
                            <br/>
                            <p>
                            Lorem ipsum dolor sit amet consectetur. Ipsum purus lectus pretium dolor fringilla gravida interdum. Dui a senectus ut senectus sed. Orci non pulvinar ac sed quis. Egestas nam amet eu 
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>

    );
}

export default CaseInternalImpact;
